import { DASHBOARD_RELOAD } from "../actions/actionTypes";

const initialState = 0;

export const dashboardReloadReducer = (state = initialState, action) => {
    switch (action.type) {
        case DASHBOARD_RELOAD:
            return state + 1;
        
        default:
            return state;
    }
};
