import { useEffect, useState } from "react";

import { Button, Menu, notification } from "antd";

import { Link } from "react-router-dom";

import classNames from "classnames";

import _auth from "@netuno/auth-client";
import _service from "@netuno/service-client";

import HeaderUserInfo from "../HeaderUserInfo";

function HeaderMenu({
    onClickNewProject,
    onClickInstitutionsAreas,
    showMobileMenu,
    onLogout,
}) {
    const [menuItems, setMenuItems] = useState([
        {
            className: "create-project",
            key: "create-project",
            label: (
                <Button
                    type="primary"
                    className={"btn-lg"}
                    onClick={onClickNewProject}
                >
                    Criar e Avaliar Projeto
                </Button>
            ),
        },
        {
            className: "project-management",
            key: "project-management",
            label: (
                <Link to="/project-management">
                    <span>Gestão de Projetos</span>
                </Link>
            ),
        },
        {
            className: "dashboard",
            key: "dashboard",
            label: (
                <Link to="/dashboard">
                    <span>Dashboard</span>
                </Link>
            ),
        },
        {
            key: "profile",
            label: (
                <HeaderUserInfo />
            ),
            triggerSubMenuAction: "click",
            className: "profile-menu",
            popupClassName: "profile-menu-popup",
            children: [
                {
                    key: "1",
                    label: (
                        <Link to="/login">
                            <Button
                                onClick={onLogout}
                                className="buttonLogout"
                                type="primary"
                                trigger="click"
                            >
                                Terminar Sessão
                            </Button>
                        </Link>
                    ),
                },
            ],
        }
    ]);

    useEffect(() => {
        _service({
            method: 'GET',
            url: 'people',
            success: (response) => {
                if (response.json.result) {
                    if (response.json.data.admin) {
                        setMenuItems([
                            {
                                className: "create-project",
                                key: "create-project",
                                label: (
                                    <Button
                                        id="project-add-menu-btn"
                                        type="primary"
                                        className={"btn-lg"}
                                        onClick={onClickNewProject}
                                    >
                                        Criar e Avaliar Projeto
                                    </Button>
                                ),
                            },
                            {
                                className: "project-management",
                                key: "project-management",
                                label: (
                                    <Link to="/project-management">
                                        <span>Gestão de Projetos</span>
                                    </Link>
                                ),
                            },
                            {
                                className: "dashboard",
                                key: "dashboard",
                                label: (
                                    <Link to="/dashboard">
                                        <span>Dashboard</span>
                                    </Link>
                                ),
                            },
                            {
                                key: "profile",
                                label: (
                                    <HeaderUserInfo />
                                ),
                                triggerSubMenuAction: "click",
                                className: "profile-menu",
                                popupClassName: "profile-menu-popup",
                                children: [
                                    {
                                        key: "2",
                                        label: (
                                            <Button
                                                id="institutions-area-menu-btn"
                                                type="link"
                                                onClick={onClickInstitutionsAreas}
                                                trigger="click"
                                            >
                                                <span>
                                                    Definições de Organizações e Áreas
                                                </span>
                                            </Button>
                                        ),
                                    },
                                    {
                                        key: "3",
                                        className: "project-user",
                                        label: (
                                            <Link to="/project-user">
                                                <span trigger="click">Gerir Utilizador</span>
                                            </Link>
                                        ),
                                    },
                                    {
                                        key: "4",
                                        label: (
                                            <Link to="/login">
                                                <Button
                                                    onClick={onLogout}
                                                    className="buttonLogout"
                                                    type="primary"
                                                    trigger="click"
                                                >
                                                    Terminar Sessão
                                                </Button>
                                            </Link>
                                        ),
                                    },
                                ],
                            }
                        ])
                    }
                } else {
                    notification["warning"]({
                        message: 'Dados do Utilizador',
                        description: response.json.error,
                    });
                }
            },
            fail: (e) => {
                console.error('Dados do Utilizador', e);
                notification["error"]({
                    message: 'Dados do Utilizador',
                    description: 'Ocorreu um erro a carregar os dados, por favor tente novamente.',
                });
                _auth.logout();
            }
        });
    }, []);

    if (showMobileMenu) {
        return (
            <Menu
                theme="light"
                mode="inline"
                items={menuItems}
                className={classNames({
                    menu: true,
                    "menu-burger": true,
                    "menu-burger-open": showMobileMenu,
                })}
            />
        );
    }

    return (
        <Menu
            mode="horizontal"
            items={menuItems}
            triggerSubMenuAction="click"
            className={classNames({
                menu: true,
                "menu-desktop": true,
            })}
        />

    )
}

export default HeaderMenu;