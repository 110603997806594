import { PrinterOutlined } from "@ant-design/icons";
import { Button } from "antd";

export function PrintButton({ onClick, title, icon }) {
    return (
        <Button type="link" onClick={onClick} className="btn-printButton">
            {title && <span>Imprimir</span>}
            {icon && <PrinterOutlined style={{ fontSize: 20, color: "#3CBA92" }} />}
        </Button>
    )
}