import React, { useState, useEffect, useImperativeHandle } from 'react';

import {connect} from "react-redux"
import { bindActionCreators } from 'redux';

import Form from 'antd/lib/form';
import notification from 'antd/lib/notification';

import _service from '@netuno/service-client';
import _auth from '@netuno/auth-client';

import Alignement from '../../Container/Alignement';
import RiskAssessment from '../../Container/RiskAssessment';
import CompositeMeasure from '../../Container/CompositeMeasure';
import Output from '../../Container/Output';
import Outcomes from '../../Container/Outcomes';
import ImpactYouAntecipate from '../../Container/ImpactYouAntecipate';
import ProjectData from '../../Container/ProjectData'

import { dashboardReloadAction } from "../../../../redux/actions"
import { inactiveUserMesage, inactiveUserCode } from "../../../../common/consts";

let submitCallback = null;

const EditProject = React.forwardRef(({ editingRecord, handleGetValuesCalculation, handleUpdateAndReload, dashboardReloadAction }, ref) => {
    const [calculationAmi, setCalculationAmi] = useState(editingRecord.ami);
    const [editForm] = Form.useForm();

    const SAA = Form.useWatch("rank_alignement_attractiveness", editForm);
    const OP = Form.useWatch("rank_output", editForm);
    const OC = Form.useWatch("rank_outcomes", editForm);
    const AIA = Form.useWatch("rank_impact_long_term_measure", editForm);
  

    const onSubmit = (callback) => {
        submitCallback = callback;
        editForm.submit();
      
    };

    useEffect(() => {
        editForm.setFieldsValue(editingRecord);
    }, [editingRecord]);

    const handleUpdate = (newRecord) => {
        setCalculationAmi(newRecord.ami);
    }

    const onFinish = (values) => {
        const startDate = values?.dates[0].format('YYYY-MM-DD');
        const endDate = values?.dates[1].format('YYYY-MM-DD');
        _service({
            url: "/project/calculation/ami",
            method: "POST",
            data: values,
            success: (response) => {
                handleUpdate(response.json);
                handleGetValuesCalculation(response);
                _service({
                    url: "/project",
                    method: "PUT",
                    data: {
                        ...values,
                        consultancy_start: startDate,
                        consultancy_end: endDate,
                        uid: editingRecord.uid,
                        ami: response.json.ami,
                    },
                    success: (response) => {
                        if (submitCallback) {
                            submitCallback();
                            submitCallback = null;
                        }
                        notification["success"]({
                            message: "Edição do Projeto",
                            description: "Os dados do projeto foram atualizados com sucesso.",
                        });
                        // handleUpdateAndReload();
                        dashboardReloadAction()
                    },
                    fail: () => {
                        submitCallback = null;
                        notification["error"]({
                            message: "Não foi possível editar o projeto, contacte-nos através do chat de suporte.",
                        });
                    },
                });
            },
            fail: (err) => {
                submitCallback = null;

                const { json } = err;

                if (json) {
                    if (json.code === inactiveUserCode) {
                        notification.error({
                            key: inactiveUserCode,
                            message: inactiveUserMesage
                        });
                        _auth.logout();

                        return;
                    }
                }

                notification["error"]({
                    message: "Não foi possível calcular o valor do AMI, contacte-nos através do chat de suporte.",
                });
            },
        });
    };

    React.useImperativeHandle(ref, () => ({
        editForm,
        onSubmit: (callback) => {
            return editForm.validateFields().then((values) => {
                return onSubmit(callback);
            });
        },
    }));

    useEffect(() => {
        _service({
            url: "/project/calculation/ami",
            method: "POST",
            data: {
                rank_alignement_attractiveness: SAA ? SAA : 0,
                rank_output: OP ? OP : 0,
                rank_outcomes: OC ? OC : 0,
                rank_impact_long_term_measure: AIA ? AIA : 0,
            },
            success: (response) => {
                handleUpdate(response.json);
                handleGetValuesCalculation(response);
            },
            fail: (error) => {
              console.log("error", error)
            }
        })
      },[SAA, OP, OC, AIA])

    return (
        <Form form={editForm} onFinish={onFinish} initialValues={editingRecord}>
            <div className='area-project-modal'>
                <div
                    id="project-data"
                >
                    <ProjectData />
                </div>
                <div
                    id="alignement-and-attractiveness"
                >
                    <Alignement />
                </div>
                <div
                    id="output"
                >
                    <Output />
                </div>
                <div
                    id="outcomes"
                >
                    <Outcomes />
                </div>
                <div
                    id="impact"
                >
                    <ImpactYouAntecipate />
                </div>
                <div
                    id="agregate-measure-impact"
                >
                    <CompositeMeasure calculationAmi={calculationAmi} />
                </div>
                <div
                    id="risk"
                >
                    <RiskAssessment />
                </div>
            </div>
        </Form>
    );
});

const mapStateToProps = store => {
    return {
        reloads: store.dashboardReloadState
    };
};
  
const mapDispatchToProps = (dispatch) => bindActionCreators({
    dashboardReloadAction
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(EditProject);
