import React, { useState, useImperativeHandle } from 'react';

import { Form, Row, Col, Input, notification, Select } from 'antd';
import { PasswordInput } from "antd-password-input-strength";

import _service from '@netuno/service-client';
import _auth from '@netuno/auth-client';

import { inactiveUserMesage, inactiveUserCode } from "../../../common/consts";

// import './index.less';

const AddUser = ({ getUsers }, ref) => {
    const [passwordRequired, setPasswordRequired] = useState(false);
    const [formAdd] = Form.useForm();

    const onSubmit = () => {
        formAdd.submit();
    };

    const onFinish = (values) => {
        const { name, username, email, group_code, password } = values;
        _service({
            url: "user",
            method: "POST",
            data: {
                name,
                username,
                email,
                group_code,
                password
            },
            success: (response) => {
                notification["success"]({
                    message: 'Criação do utilizador',
                    description: 'Os dados do seu utilizador foram criado com sucesso.',
                });
                formAdd.resetFields();
                if (getUsers) {
                    getUsers();
                }
            },
            fail: (err) => {
                const { json } = err;

                if (json) {
                    const { code } = json;

                    if (code === inactiveUserCode) {
                        notification.error({
                            key: inactiveUserCode,
                            message: inactiveUserMesage
                        });
                        _auth.logout();

                        return;
                    }
                }

                notification["error"]({
                    message: 'Não foi possível criar o utilizador, contacte-nos através do chat de suporte.'
                });
            }
        })
    };

    function onValuesChange(changedValues, allValues) {
        if (allValues.password && allValues.password.length > 0) {
            setPasswordRequired(true);
        } else {
            setPasswordRequired(false);
        }
    };

    useImperativeHandle(ref, () => {
        return (
            { onSubmit }
        )
    })
    
    return (
        <Form form={formAdd} onFinish={onFinish} onValuesChange={onValuesChange}>
            <Row justify="flex-start">
                <Col>
                    <h1>Novo Utilizador</h1>
                </Col>
                <Row gutter={[10, 24]} className='form-user-content'>
                    <Col span={12}>
                        <span>Nome</span>
                        <Form.Item name="name" rules={[
                            { required: true, message: "Insira o nome do Utilizador." },
                        ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <span>Email</span>
                        <Form.Item name="email"
                            rules={[
                                { required: true, message: "Insira o email do Utilizador." },
                                { type: 'email', message: 'O e-mail inserido não é válido.' },
                            ]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <span>Utilizador</span>
                        <Form.Item name="username" rules={[
                            { required: true, message: "Insira o seu Utilizador." },
                        ]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <span>Grupo</span>
                        <Form.Item name="group_code" rules={[
                            { required: true, message: "Insira o grupo do Utilizador." },
                        ]}>
                            <Select
                                showSearch
                                placeholder="Selecione o grupo do Utilizador"
                                optionFilterProp="children"
                                allowClear
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                <Select.Option value="raiz_manager">
                                    Gestor
                                </Select.Option>
                                <Select.Option value="raiz_admin">
                                    Administrador
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <span>Palavra-passe</span>
                        <Form.Item name="password" rules={[
                            { required: true, message: "Insira a sua palavra-passe." },
                        ]}>
                            <PasswordInput autoComplete="new-password" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <span>Confirme a sua Palavra-passe</span>
                        <Form.Item
                            name="password_confirm"
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject('As palavras-passes não são iguais.');
                                    },
                                }),
                                { required: true, message: "Confirme sua senha." },
                            ]}
                        >
                            <Input.Password maxLength={25} />
                        </Form.Item>
                    </Col>
                    {/* <Form.Item style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        width: '100%'
                    }}>
                        <Button
                            type='primary'
                            htmlType='submit'>Adicionar Utilizador</Button>
                    </Form.Item> */}
                </Row>

            </Row>
        </Form >
    );
}

export default React.forwardRef(AddUser);
