import { Navigate } from "react-router-dom";

import { Typography, Spin, Col, Row } from 'antd';

import { connect } from 'react-redux';

import _auth from '@netuno/auth-client';

import ProjectImpactGraphic from '../../components/ProjectImpactGraphic';
import OperationalAreaSplit from '../../components/Overview/OperationalAreaSplit';
import ListProjects from "../../components/Project/ListProjects";
import Overview from '../../components/Overview';
import Alerts from '../../components/Alerts';
import SessionTitle from '../../components/SessionTitle';
import FilterArea from '../../components/Project/ListProjects/FilterArea';
import FilterInstitution from '../../components/Project/ListProjects/FilterInstitution';
import FilterProject from '../../components/Project/ListProjects/FilterProject';
import PrintDasboard from '../../components/Print/Dashboard';

import './index.less';

function Dashboard({
  loggedUserInfo
}) {
  if (_auth.isLogged()) {
    if (!loggedUserInfo) {
      return (
        <Row justify="center">
          <Col>
            <Spin />
          </Col>
        </Row>
      );
    }
  } else {
    return <Navigate to="/login" />;
  }

  return (
    <Row className="dashboard">
      <Col span="24">
        <div className="image-raiz">
        <img src="/images/logo-raiz.png" />
        </div>
      </Col>
      <Col span="24">
        <Typography.Title className="dashboard__title" level={2}>
          Avaliação do Impacto de Projetos de Inovação
        </Typography.Title>
      </Col>
      <Col span="24">
        <Row gutter={24}>
          <Col span="11">
            <Overview />
            <Alerts />
          </Col>
          <Col span="13">
            <OperationalAreaSplit />
          </Col>
        </Row>
      </Col>
      <Col span="24">
        <Row
          align="middle"
          gutter={4}
          justify={'space-between'}
          className="dashboard__filter"
        >
          <Col span={6}>
            <Typography.Title
              level={2}
              style={{ color: 'white' }}
            >
              Filtrar
            </Typography.Title>
          </Col>
          <Col span={18}>
            <Row justify={'space-between'} gutter={8}>
              <Col span={8}>
                <FilterInstitution />
              </Col>
              <Col span={8}>
                <FilterArea />
              </Col>
              <Col span={8}>
                <FilterProject />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col span="24" className="col-bottom">
        <Row gutter={24}>
          <Col span="11">
            <ProjectImpactGraphic />
          </Col>
          <Col span="13">
            <Col xs={24} md={10}>
              <SessionTitle title="Projetos" />
            </Col>
            <ListProjects
              type="dashboard"
            />
          </Col>
        </Row>
        <PrintDasboard />
      </Col>
    </Row >
  );
}

const mapStateToProps = store => {
  const { loggedUserInfo } = store.loggedUserInfoState;
  return {
    loggedUserInfo
  };
};

export default connect(mapStateToProps, {})(Dashboard);

