import { useEffect, useState } from "react";

import { Select, TreeSelect } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import _service from "@netuno/service-client";

import { dashboardFiltersAction } from "../../../../redux/actions";

function FilterArea({
    reloads,
    dashboardFiltersAction,
    dashboardFilters
}) {
    const [areas, setAreas] = useState([]);

    const onChangeSelect = (_newValue, values) => {
        dashboardFiltersAction({
            ...dashboardFilters,
            area_uids: values.map(({ label, value }) => ({ name: label, uid: value }))
        });
    }

    useEffect(() => {
        _service({
            url: "area/list",
            method: "POST",
            success: (res) => {
                if (res.json) {
                    setAreas(res.json.areas);
                }
            }
        });
    }, [reloads]);

    return (
        <>
            <Select
                allowClear
                mode="multiple"
                placeholder="Área Operacional"
                style={{
                    width: "100%",
                    marginRight: "10px",
                    background: "#fff",
                    borderRadius: "5px"
                }}
                dropdownStyle={{
                    maxHeight: 400,
                    overflow: 'auto',
                    minWidth: 300,
                }}
                suffixIcon={<SearchOutlined />}
                optionFilterProp="label"
                options={
                    areas.map(({ uid, name }) => ({
                        label: name,
                        value: uid
                    }))
                }
                value={((dashboardFilters || {}).area_uids || []).map(({ name, uid }) => ({
                    label: name,
                    value: uid
                }))}
                onChange={onChangeSelect}
            />
        </>
    );
}

const mapStateToProps = store => {
    const { dashboardFilters } = store.dashboardFiltersState;
    return {
        dashboardFilters,
        reloads: store.dashboardReloadState
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    dashboardFiltersAction
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FilterArea);