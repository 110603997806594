import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal, Popconfirm, Popover } from "antd";
import { InfoCircleOutlined, WarningOutlined } from '@ant-design/icons'
import "./index.less";
import _service from '@netuno/service-client';
import EditProject from './EditProject';
import SideMenuProject from '../SideProject';

const EditProjectModal = ({ show, onClose, editingRecord, handleUpdateAndReload }) => {
    const [calculationAmi, setCalculationAmi] = useState('');

    const handleGetValuesCalculation = (newRecord) => {
        setCalculationAmi(newRecord.json.ami);
    }
    const editProjectModal = useRef(null);

    const handleSaveAndClose = () => {
        editProjectModal.current.onSubmit(() => {
            window.history.replaceState({}, document.title, window.location.pathname);
            onClose();
        });
    };

    const handleCloseModal = () => {
        window.history.replaceState({}, document.title, window.location.pathname);
        onClose();
    };


    const items = [
        {
            key: "side-menu",
            children: <SideMenuProject />,
        },
        {
            key: 'edit-project',
            children: <EditProject />
        }
    ];

    useEffect(() => {
        console.log(editingRecord)
    }, [])

    return (
        <Modal
            className="modal-project-areas"
            title={
                <React.Fragment>
                    <h1 className="modal-project-areas-title">Editar Projeto</h1>
                    <div className="modal-project-areas-button">
                        {
                            editingRecord && editingRecord.manager && (
                                <span className="button-tcmgi">
                                    Gestor: {editingRecord.manager.name}
                                </span>
                            )
                        }
                        <span className="button-tcmgi">
                            AMI {calculationAmi ? calculationAmi?.toFixed(1) : editingRecord.ami.toFixed(1)}
                            <Popover
                                overlayStyle={{
                                    width: "300px"
                                }} 
                                content="AMI - Medida Agregada de Impacto = 0.30 * Alinhamento e Atratividade + 0,20 * Outputs + 0,20 * Efeitos / Outcomes + 0,30 * Impacto Antecipado"
                            >
                                <InfoCircleOutlined />
                            </Popover>
                        </span>
                    </div>
                </React.Fragment>
            }
            visible={show}
            onCancel={handleCloseModal}
            destroyOnClose={true}
            footer={
                <div style={{
                    display: 'flex',
                    justifyContent: "flex-end",
                    alignItems: "center"
                }}>
                    <Popconfirm
                        title="Tem certeza que deseja cancelar a edição de projeto?"
                        okText="Sim"
                        cancelText="Não"
                        okType="link"
                        onConfirm={handleCloseModal}
                        icon={<WarningOutlined style={{ color: "#E70000" }} />}
                        okButtonProps={{
                            className: "pop-confirm-confirm-btn",
                        }}
                        cancelButtonProps={{
                            className: "pop-confirm-cancel-btn",
                        }}
                    >
                        <Button type='link' style={{
                            color: "#E70000"
                        }}
                        >Cancelar</Button>
                    </Popconfirm>
                    <Button
                        type='primary'
                        onClick={handleSaveAndClose}
                        style={{
                            width: 132
                        }}>
                        Guardar
                    </Button>
                </div >
            }
        >
            <React.Fragment>
                <SideMenuProject getContainer={() => document.querySelector(".area-project-modal")} items={items} />
            </React.Fragment>
            <React.Fragment>
                <EditProject ref={editProjectModal} editingRecord={editingRecord} handleGetValuesCalculation={handleGetValuesCalculation} handleUpdateAndReload={handleUpdateAndReload} />
            </React.Fragment>
        </Modal >
    );
};

export default EditProjectModal;
