import React, { useState, useEffect } from 'react';
import { Select, Row, Col, Form, Input, Popover } from 'antd';
import './index.less';
import _service from '@netuno/service-client';
import Checkbox from 'antd/es/checkbox/Checkbox';
import { InfoCircleOutlined } from '@ant-design/icons'

const { TextArea } = Input;
const { Option } = Select;

const Outcomes = () => {

    const options = [];

    const contentRanking = ["Nível de efeitos - Baixos", "Nível de efeitos - Suficientes", "Nível de efeitos - Bom", "Nível de efeitos - Muito Bom", "Nível de efeitos - Forte"];

    for (let i = 0; i < contentRanking.length; i++) {
        options.push(
            <Option key={i + 1} value={i + 1}>
                {i + 1} - {contentRanking[i]}
            </Option>
        );
    }

    const onChange = (value) => {
        console.log(`selected ${value}`);
    };
    const onSearch = (value) => {
        console.log('search:', value);
    };

    return (
        <>
            <Row className='outcomes-modal' justify="flex-start">
                <Col className='outcomes-modal-title'>
                    <h1>Efeitos/Outcomes</h1>
                </Col>
                <Row className="outcomes-modal-body" gutter={[10, 24]}>
                    <Col span={24}>
                        <Form.Item name="outcome_increase_recognition" initialValue={false} valuePropName="checked">
                            <Checkbox>Aumento do conhecimento público</Checkbox>
                        </Form.Item>
                        <Form.Item name="outcome_knowledge_enlargement" initialValue={false} valuePropName="checked">
                            <Checkbox>Consciencialização pública através de uma maior literacia do conhecimento e participação da sociedade civil</Checkbox>
                        </Form.Item>
                        <Form.Item name="outcome_public_awareness" initialValue={false} valuePropName="checked">
                            <Checkbox>Aumento do conhecimento em cocriação e contributo das diferentes hélices de inovação</Checkbox>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <span>Justificação</span>
                        <Form.Item name="outcomes_justify">
                            <TextArea rows={4} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <div className='area-project-modal-select-title'>
                            <span>Avaliação</span>
                        </div>
                        <Form.Item name="rank_outcomes" rules={[
                            { required: true, message: 'Campo Requerido.' },
                        ]}>
                            <Select
                                style={{
                                    marginTop: 8
                                }}
                                allowClear
                                className="outcomes-modal-body-content-select-unique"
                                showSearch
                                placeholder="Selecione uma opção"
                                optionFilterProp="children"
                                onChange={onChange}
                                onSearch={onSearch}
                            >
                                {options}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Row>
        </>
    )
}

export default Outcomes;