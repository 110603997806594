import React, { useEffect, useState } from "react";
import {
  Routes as Switch,
  Route,
  useLocation,
  useNavigate,
  Link,
  Navigate,
} from "react-router-dom";

import { ConfigProvider, Layout, Menu, Button, Tooltip } from "antd";
import {
  PieChartOutlined,
  LogoutOutlined,
  MenuOutlined,
  EditOutlined,
  UserOutlined
} from "@ant-design/icons";

import Burger from "@animated-burgers/burger-slip";
import "@animated-burgers/burger-slip/dist/styles.css";

import antLocale_ptPT from "antd/lib/locale/pt_PT";

import { Provider } from "react-redux";
import { Store } from "./redux/store";

import classNames from "classnames";

import _auth from "@netuno/auth-client";
import "./common/Config";

import HeaderUserInfo from "./components/HeaderUserInfo";
import InstitutionsAreasModal from "./components/InstitutionsAreasModal";

import LoginPage from "./pages/Login";
import RegisterPage from "./pages/Register";
import Dashboard from "./pages/Dashboard";
import RecoveryPage from "./pages/Recovery";
import Profile from "./pages/Profile";
import NotFoundPage from "./pages/NotFound";

import "./styles/App.less";
import UserManagement from "./pages/UserManagement";
import ProjectManagement from "./pages/ProjectManagement";
import AddProjectModal from "./components/Project/AddProjectModal";
import HeaderMenu from "./components/HeaderMenu";

const { Header, Content, Sider, Footer } = Layout;

const NavWithAuthCheck = () => {
  if (_auth.isLogged()) {
    return <Navigate to="/dashboard" />;
  }
  return <Navigate to="/login" />;
};

export default function App(props) {
  const [headerButtonMode, setHeaderButtonMode] = useState("login");
  const [collapsed, setCollapsed] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showInstitutionsAreasModal, setShowInstitutionsAreasModal] = useState(false);
  const [showAddProject, setShowAddProject] = useState(false)

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    _auth.config({
      onLogout: () => {
        navigate("/login");
      },
    });
  }, []);

  useEffect(() => {
    setHeaderButtonMode(location.pathname);
  }, [location]);

  function onLogout() {
    _auth.logout();
  }

  function onCollapse() {
    setCollapsed(!collapsed);
  }

  function onClickNewProject() {
    setShowAddProject(true);
  }

  function onClickInstitutionsAreas() {
    setShowInstitutionsAreasModal(true);
  }


  return (
    <ConfigProvider
      locale={antLocale_ptPT}
      theme={{
        token: {
          colorPrimary: "#3CBA92",
          colorLinkHover: "#3CBA92",
          borderRadius: 4,
          colorText: "#2D2D2D",
          colorLink: "#3CBA92",
          colorLinkActive: "#3CBA92",
          colorBgSpotlight: "#FFF"
        },
        components: {
          Menu: {
            colorItemBgSelected: "transparent",
            colorItemText: "#2D2D2D",
          },
          Button: {
            colorPrimaryBg: "linear-gradient(91.59deg, #3CBA92 0%, #039E6D 100%)",
            colorPrimaryText: "#FFF",
            borderRadius: 4,
            colorBgContainerDisabled: "#2D2D2D33",
            colorTextDisabled: "#FFF"
          },
          Modal: {
            motionDurationMid: "0.3s"
          }
        },
      }}
    >
      <Provider store={Store}>
        <Layout
          className={
            "page " +
            classNames({ "auth ": _auth.isLogged() }) +
            classNames({ "collapsed ": collapsed })
          }
        >
          <InstitutionsAreasModal
            show={showInstitutionsAreasModal}
            onClose={() => setShowInstitutionsAreasModal(false)}
          />
          <AddProjectModal
            show={showAddProject}
            onClose={() => setShowAddProject(false)}
          />
          <Layout>
            {headerButtonMode !== "/login" && (
              <Header
                className={
                  classNames({ "auth ": _auth.isLogged() }) +
                  classNames({ "collapsed ": collapsed })
                }
              >
                <Link to="/" className="logo-container">
                  <img alt="logo" src="/images/impacto.png" />
                </Link>
                {headerButtonMode === "/login" ? (
                  <Link to="/register">
                    <Button type="primary">Criar Projeto</Button>
                  </Link>
                ) : headerButtonMode === "/register" ? (
                  <Link to="/login">
                    <Button type="primary">Iniciar sessão</Button>
                  </Link>
                ) : (
                  _auth.isLogged() && (
                    <>
                      {showMobileMenu && (
                        <HeaderMenu
                          showMobileMenu={showMobileMenu}
                          onClickInstitutionsAreas={onClickInstitutionsAreas}
                          onClickNewProject={onClickNewProject}
                          onLogout={onLogout}
                        />
                      )}
                      <div className="menu-burger-button">
                        <Burger
                          isOpen={showMobileMenu}
                          onClick={() => setShowMobileMenu(!showMobileMenu)}
                        >
                          <MenuOutlined />
                        </Burger>
                      </div>
                      <HeaderMenu
                        showMobileMenu={showMobileMenu}
                        onClickInstitutionsAreas={onClickInstitutionsAreas}
                        onClickNewProject={onClickNewProject}
                        onLogout={onLogout}
                      />
                    </>
                  )
                )}
              </Header>
            )}
            <Content className={classNames({ "auth ": _auth.isLogged() })}>
              <Switch>
                <Route exact path="/" element={<NavWithAuthCheck />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/register" element={<RegisterPage />} />
                <Route path="/recovery" element={<RecoveryPage />} />
                <Route path="/project-management" element={<ProjectManagement />} ></Route>
                <Route path="/project-user" element={<UserManagement />} ></Route>
                <Route element={<NotFoundPage />} />
              </Switch>
            </Content>
            {_auth.isLogged() && (
              <Footer>
                <span className="raiz-footer-year">iMPACTO © {new Date().getFullYear()}</span>
                <span>Cofinanciado por:</span>
                <div className="cofinanciado-image">
                  <img src="/images/barra-cofinanciamento.png" />
                </div>
              </Footer>
            )}
          </Layout>
        </Layout>
      </Provider>
    </ConfigProvider>
  );
}
