import { DASHBOARD_FILTERS, DASHBOARD_FILTERS_RELOAD } from "../actions/actionTypes";

const initialState = {
    area_uids: [],
    institution_uids: [],
    project_uids: []
};

export const dashboardFiltersReducer = (state = initialState, action) => {
    switch (action.type) {
        case DASHBOARD_FILTERS:
            return {
                ...state,
                dashboardFilters: action.payload
            };
        case DASHBOARD_FILTERS_RELOAD:
            return {
                ...state,
                dashboardFiltersReload: action.payload
            };
        default:
            return state;
    }
};
