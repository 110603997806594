import { useState } from "react";

import { Button, Col, Row, Tooltip, Typography } from "antd";
import { WarningOutlined } from "@ant-design/icons";

import _service from "@netuno/service-client";

import "./index.less";

function ProjectEditModalFooter({
    onCancel,
    form,
    loading
}) {
    const [open, setOpen] = useState(false);

    const onToggleTooltip = () => setOpen(!open);

    const onFinish = async (close) => {
        try {
            await form.validateFields();

            form.closeModal = close;
            form.submit();

        } catch (err) {
            const field = err.errorFields[0].name[0];
            const link = document.createElement("a");
            link.href = `#${field}`;

            link.click();
        }
    };

    return (
        <Row justify="end" align="middle">
            <Col>
                <Tooltip
                    overlayClassName="cancel-tooltip"
                    open={open}
                    trigger="click"
                    title={(
                        <Row className="cancel-tooltip__inner" justify="end">
                            <Col span="24">
                                <Typography.Paragraph className="cancel-tooltip__inner__text">
                                    <span className="cancel-tooltip__inner__text__icon"><WarningOutlined /></span>
                                    Tem certeza que deseja cancelar a edição de projeto?
                                </Typography.Paragraph>
                                <Row justify="end" gutter={8}>
                                    <Col>
                                        <Button
                                            type="default"
                                            className="cancel-tooltip__inner__not"
                                            onClick={onToggleTooltip}
                                        >
                                            Não
                                        </Button>
                                    </Col>
                                    <Col>
                                        <Button
                                            className="cancel-tooltip__inner__yes"
                                            type="primary"
                                            danger
                                            onClick={() => {
                                                onToggleTooltip();
                                                onCancel();
                                            }}
                                        >
                                            Sim
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    )}
                >
                    <Button
                        onClick={onToggleTooltip}
                        type="link"
                        danger
                    >
                        Cancelar
                    </Button>
                </Tooltip>
            </Col>
            <Col>
                <Button
                    type="primary"
                    loading={loading}
                    onClick={() => onFinish(true)}
                    style={{
                        width: "132px"
                    }}
                >
                    Guardar
                </Button>
            </Col>
        </Row>
    )
}

export default ProjectEditModalFooter;