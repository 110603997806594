import { useEffect, useState } from "react";

import { Divider, Typography } from "antd";
import { CheckOutlined } from "@ant-design/icons";

import ProjectModal from "../Modal";
import ProjectRevaluateModalFooter from "./Footer";
import ProjectRevaluateModalTitle from "./Title";
import ProjectRevaluateModalSession from "./Session";
import ProjectRevaluateModalCard from "./Card";

function ProjectRevaluateModal({
  uid,
  open,
  onCancel,
  loadAlerts
}) {
  const [project, setProject] = useState(null);
  const [sessions, setSessions] = useState([]);
  const [toRevaluate, setToRevaluate] = useState([]);
  const [toRevaluateCount, setToRevaluateCount] = useState(0);
  const [menu, setMenu] = useState([]);

  const onLoadProject = (values) => {
    setProject(values);
  };

  const onClose = (config) => {
    onCancel(config);
    setProject(null);
    setToRevaluate([]);
    setToRevaluateCount(0);

    if (config) {
      if (config.reloadAlerts) {
        loadAlerts();
      }
    }
  };

  const onRevaluate = (field) => {
    setToRevaluate((prev) => [
      ...prev.filter(({ name }) => name !== field),
      {
        name: field,
        accept: false
      }
    ]);
  }

  const onAccept = (field) => {
    setToRevaluate((prev) => [
      ...prev.filter(({ name }) => name !== field),
      {
        name: field,
        accept: true
      }
    ]);
  }

  useEffect(() => {
    if (project) {
      let newToRevaluateCount = 0;

      if (project.revaluate_ami) newToRevaluateCount++

      if (project.revaluate_risk) newToRevaluateCount++

      setToRevaluateCount(newToRevaluateCount);

      setSessions([
        {
          key: "project-data",
          title: "Dados Gerais do Projeto",
          fields: [
            [{
              key: "institution",
              label: "Organização/Direção",
            },
            {
              key: "area",
              label: "Área Operacional"
            }],
            [{
              key: "period",
              label: "Período de Execução",
            }],
            [{
              key: "project",
              label: "Projeto / Programa Acrónimo"
            }],
            [{
              key: "overview",
              label: "Visão Geral"
            }],
            [{
              key: "milestones",
              label: "Metas"
            }],
            [{
              key: "deliverables",
              label: "Entregáveis"
            }],
            [{
              key: "trl_ttm",
              label: "Prontidão tecnológica vs Tempo para o mercado"
            }],
          ]
        },
        {
          key: "alignement-and-attractiveness",
          title: "Alinhamento e Atratividade",
          fields: [
            [{
              key: "justify_strategic_alignement",
              label: "Alinhamento com os objetivos estratégicos da Organização"
            }],
            [{
              key: "justify_uniquiness_program_plan",
              label: "Grau de inovação, originalidade e efetividade do Plano Proposto e Realizado no Projeto"
            }],
            [{
              key: "justify_economical_environmental_relevance_business",
              label: "Relevância Económica, Societal e Ambiental Para a Organização/Setor/Região/País"
            }],
            [{
              key: "justify_competence_network_partners",
              label: "Relevância Estratégica dos Parceiros da Rede na Cocriação e Valorização do Conhecimento"
            }],
            [{
              key: "rank_alignement_attractiveness",
              label: "Avaliacão"
            }]
          ]
        },
        {
          key: "output",
          title: "Outputs",
          fields: [
            [{
              key: "number_publications",
              label: "Nº de publicações científicas e não científicas"
            }],
            [{
              key: "justify_number_publications",
              label: "Descrição"
            }],
            [{
              key: "number_outreaches",
              label: "Nº de iniciativas de divulgação, promoção e prémios"
            }],
            [{
              key: "justify_number_outreach",
              label: "Descrição"
            }],
            [{
              key: "number_patents",
              label: "Nº de patentes"
            }],
            [{
              key: "justify_number_patents",
              label: "Descrição"
            }],
            [{
              key: "number_norms_reports_techinical_assessments",
              label: "Nº de normas, relatórios e pareces técnicos"
            }],
            [{
              key: "justify_number_norms_reports_techinical_assessments",
              label: "Descrição"
            }],
            [{
              key: "number_proof_concepts",
              label: "Nº de provas de conceito e projetos de ignição, modelos de viabilidade técnico-económica e protótipos"
            }],
            [{
              key: "justify_number_proof_concepts",
              label: "Descrição"
            }],
            [{
              key: "number_new_technologies",
              label: "Nº de introdução de novas tecnologias e inovações em produtos e processos mais sustentáveis"
            }],
            [{
              key: "justify_number_new_technologies",
              label: "Descrição"
            }],
            [{
              key: "number_new_potential_business_proposals",
              label: "Nº de novos potenciais negócios, licenças a terceiros, joint ventures"
            }],
            [{
              key: "justify_number_new_potential_business_proposals",
              label: "Descrição"
            }],
            [{
              key: "number_new_strategic_partnerships",
              label: "Nº de novas parcerias estratégicas"
            }],
            [{
              key: "justify_number_new_strategic_partnerships",
              label: "Descrição"
            }],
            [{
              key: "rank_output",
              label: "Avaliacão"
            }],
          ]
        },
        {
          key: "outcomes",
          title: "Efeitos/Outcomes",
          fields: [
            [{
              key: "outcome_increase_recognition",
              value: project.outcome_increase_recognition && (
                <Typography.Paragraph className="project-modal-field__value">
                  <span><CheckOutlined /></span> Aumento do conhecimento público
                </Typography.Paragraph>
              )
            }],
            [{
              key: "outcome_public_awareness",
              value: project.outcome_public_awareness && (
                <Typography.Paragraph className="project-modal-field__value">
                  <span><CheckOutlined /></span> Consciencialização pública através de uma maior literacia do conhecimento e participação da sociedade civil
                </Typography.Paragraph>
              )
            }],
            [{
              key: "outcome_knowledge_enlargement",
              value: project.outcome_knowledge_enlargement && (
                <Typography.Paragraph className="project-modal-field__value">
                  <span><CheckOutlined /></span> Aumento do conhecimento em cocriação e contributo das diferentes hélices de inovação
                </Typography.Paragraph>
              )
            }],
            [{
              key: "outcomes_justify",
              label: "Justificação"
            }],
            [{
              key: "rank_outcomes",
              label: "Avaliação"
            }],
          ]
        },
        {
          key: "impact",
          title: "Impacto Antecipado",
          subtitle: "Sugestão de possíveis cálculos para a avaliação: previsão do retorno do investimento por ano após terminus do projeto, contemplar os direitos resultantes de licenças, poupanças de custos, lucros;  contributo do Programa/Projeto para a alteração da estrutura dos rendimentos da organização, entrada em novos mercados ou em novas áreas de negócio, processos de geração de novos negócios, difusão tecnológica e efeitos de repercussões “spillovers”, alteração do valor da despesa em IDT; valor económico, social e ambiental gerado tentando sempre estimar o impacto esperado com base nos pressupostos assumidos em % do EBITDA",
          fields: [
            [{
              key: "description_impact_long_term_measure",
              label: "Impacto Antecipado"
            }],
            [{
              key: "rank_impact_long_term_measure",
              label: "Impacto no EBITDA anual a médio/longo prazo",
            }]
          ]
        },
        {
          key: "agregate-measure-impact",
          fields: [
            [{
              key: "ami",
              value: (
                <>
                  <Divider />
                  <ProjectRevaluateModalCard
                    data={project.ami}
                    alert={project.revaluate_ami}
                    title="MEDIDA AGREGADA DE IMPACTO (AMI)"
                    onAccept={() => onAccept("ami")}
                    onRevaluate={() => onRevaluate("ami")}
                  />
                  <Divider />
                </>
              )
            }]
          ]
        },
        {
          key: "risk",
          title: "Estimativa de Risco",
          subtitle: "Descrever os riscos que podem colocar em causa a avaliação de impacto anterior e ações para mitigar esses riscos",
          fields: [
            [{
              key: "risk_justify",
              label: "Justificação"
            }],
            [{
              key: "rank_risk",
              value: (
                <>
                  <Divider />
                  <ProjectRevaluateModalCard
                    data={project.rank_risk}
                    alert={project.revaluate_risk}
                    title="Estimativa de Risco"
                    onAccept={() => onAccept("risk")}
                    onRevaluate={() => onRevaluate("risk")}
                  />
                </>
              )
            }]
          ]
        },
      ])

      setMenu([
        {
          href: '#project-data',
          title: 'Dados Gerais do Projeto',
          className: 'project-data'
        },
        {
          href: '#alignement-and-attractiveness',
          title: 'Alinhamento e Atratividade',
          className: 'alignement-and-attractiveness'
        },
        {
          href: '#output',
          title: 'Outputs',
          className: 'output'
        },
        {
          href: '#outcomes',
          title: 'Efeitos/Outcomes',
          className: 'outcomes'
        },
        {
          href: '#impact',
          title: 'Impacto Antecipado',
          className: 'impact'
        },
        {
          href: '#agregate-measure-impact',
          title: project.revaluate_ami ? (
            <span className="project-modal-menu__alert">
              Medida Agregada de Impacto (AMI)
            </span>
          ) : "Medida Agregada de Impacto (AMI)",
          className: 'agregate-measure-impact'
        },
        {
          href: '#risk',
          title: project.revaluate_risk ? (
            <span className="project-modal-menu__alert">
              Estimativa de Risco
            </span>
          ) : "Estimativa de Risco",
          className: 'risk'
        },
      ]);
    }
  }, [project]);

  useEffect(() => {
    if (sessions.length > 0 && project) {
      let href = "";

      if (project.revaluate_risk) {
        href = "#risk";
      }

      if (project.revaluate_ami) {
        href = "#agregate-measure-impact";
      }

      if (href) {
        setTimeout(() => {
          const link = document.createElement("a");
          link.href = href;

          link.click();
        }, 500);
      }
    }
  }, [sessions]);

  const handleCloseModal = (config) => {
    window.history.replaceState({}, document.title, window.location.pathname);
    onClose(config);
  };

  const handleCancelModal = () => {
    window.history.replaceState({}, document.title, window.location.pathname);
    onCancel();
  };

  return (
    <ProjectModal
      uid={uid}
      open={open}
      title={project && <ProjectRevaluateModalTitle project={project} />}
      menu={menu}
      footer={project &&
        <ProjectRevaluateModalFooter
          onClose={handleCloseModal}
          onCancel={handleCancelModal}
          toRevaluate={toRevaluate}
          toRevaluateCount={toRevaluateCount}
          project={project}
        />
      }
      onCancel={handleCloseModal}
      onLoadProject={onLoadProject}
    >
      {sessions.map((session) => (
        <ProjectRevaluateModalSession
          key={session.key}
          session={session}
          project={project}
        />
      ))}
    </ProjectModal>
  );
}

export default ProjectRevaluateModal;
