import React, { useState, useEffect } from "react";
import { Select, Row, Col, Form, Input, InputNumber, Popover } from "antd";
import "./index.less";
import _service from "@netuno/service-client";
import Checkbox from "antd/es/checkbox/Checkbox";
import { InfoCircleOutlined } from "@ant-design/icons"

const { TextArea } = Input;
const { Option } = Select;

const Output = () => {

    const options = [];

    const contentRanking = ["Baixo", "Suficiente", "Bom", "Muito bom", "Excede"];

    for (let i = 0; i < contentRanking.length; i++) {
        options.push(
            <Option key={i + 1} value={i + 1}>
                {i + 1} - {contentRanking[i]}
            </Option>
        );
    }

    const onChange = (value) => {
        console.log(`selected ${value}`);
    };
    const onSearch = (value) => {
        console.log("search:", value);
    };

    return (
        <Row className="output-modal" justify="flex-start">
            <Col>
                <h1>Outputs</h1>
            </Col>
            <Row className="output-modal-body" gutter={[10, 24]}>
                <Col xxl={12} md={12} sm={24} xs={24} className="area-project-modal-content">
                    <span>Nº de publicações científicas e não científicas</span>
                    <Form.Item name="number_publications">
                        <InputNumber min={0} defaultValue={0} onChange={onChange} />
                    </Form.Item>
                </Col>
                <Col span={24} className="area-project-modal-content">
                    <span>Descrição</span>
                    <Form.Item name="justify_number_publications">
                        <TextArea rows={4} />
                    </Form.Item>
                </Col>
                <Col xxl={12} md={12} sm={24} xs={24} className="area-project-modal-content">
                    <div className="area-project-modal-select-title">
                        <span>Nº de iniciativas de divulgação, Promoção e Prémios </span>
                    </div>
                    <Form.Item name="number_outreaches" >
                        <InputNumber min={0} defaultValue={0} onChange={onChange} />
                    </Form.Item>
                </Col>
                <Col span={24} className="area-project-modal-content">
                    <span>Descrição</span>
                    <Form.Item name="justify_number_outreach">
                        <TextArea rows={4} />
                    </Form.Item>
                </Col>
                <Col xxl={12} md={12} sm={24} xs={24} className="area-project-modal-content">
                    <span>Nº de patentes</span>
                    <Form.Item name="number_patents">
                        <InputNumber min={0} defaultValue={0} onChange={onChange} />
                    </Form.Item>
                </Col>
                <Col span={24} className="area-project-modal-content">
                    <span>Descrição</span>
                    <Form.Item name="justify_number_patents">
                        <TextArea rows={4} />
                    </Form.Item>
                </Col>
                <Col xxl={12} md={12} sm={24} xs={24} className="area-project-modal-content">
                    <span>Nº de normas, relatórios e pareces técnicos</span>
                    <Form.Item name="number_norms_reports_techinical_assessments" >
                        <InputNumber min={0} defaultValue={0} onChange={onChange} />
                    </Form.Item>
                </Col>
                <Col span={24} className="area-project-modal-content">
                    <span>Descrição</span>
                    <Form.Item name="justify_number_norms_reports_techinical_assessments">
                        <TextArea rows={4} />
                    </Form.Item>
                </Col>
                <Col xxl={12} md={12} sm={24} xs={24} className="area-project-modal-content">
                    <div className="area-project-modal-select-title">
                        <span>Nº de provas de conceito e projetos de ignição, modelos de viabilidade técnico-económica e protótipos</span>
                    </div>
                    <Form.Item name="number_proof_concepts" >
                        <InputNumber min={0} defaultValue={0} onChange={onChange} />
                    </Form.Item>
                </Col>
                <Col span={24} className="area-project-modal-content">
                    <span>Descrição</span>
                    <Form.Item name="justify_number_proof_concepts">
                        <TextArea rows={4} />
                    </Form.Item>
                </Col>
                <Col xxl={12} md={12} sm={24} xs={24} className="area-project-modal-content">
                    <span>Nº de introdução de novas tecnologias e inovações em produtos e processos mais sustentáveis </span>
                    <Form.Item name="number_new_potential_business_proposals" >
                        <InputNumber min={0} defaultValue={0} onChange={onChange} />
                    </Form.Item>
                </Col>
                <Col span={24} className="area-project-modal-content">
                    <span>Descrição</span>
                    <Form.Item name="justify_number_new_potential_business_proposals">
                        <TextArea rows={4} />
                    </Form.Item>
                </Col>
                <Col xxl={12} md={12} sm={24} xs={24} className="area-project-modal-content">
                    <span>Nº de novos potenciais negócios, licenças a terceiros, joint ventures</span>
                    <Form.Item name="number_new_strategic_partnerships" >
                        <InputNumber min={0} defaultValue={0} onChange={onChange} />
                    </Form.Item>
                </Col>
                <Col span={24} className="area-project-modal-content">
                    <span>Descrição</span>
                    <Form.Item name="justify_number_new_strategic_partnerships">
                        <TextArea rows={4} />
                    </Form.Item>
                </Col>
                <Col xxl={12} md={12} sm={24} xs={24} className="area-project-modal-content">
                    <span>Nº de novas parcerias estratégicas</span>
                    <Form.Item name="number_new_technologies" >
                        <InputNumber min={0} defaultValue={0} onChange={onChange} />
                    </Form.Item>
                </Col>
                <Col span={24} className="area-project-modal-content">
                    <span>Descrição</span>
                    <Form.Item name="justify_number_new_technologies">
                        <TextArea rows={4} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <div className="area-project-modal-select-title">
                        <span>Avaliação</span> 
                    </div>
                    <Form.Item name="rank_output" rules={[
                        { required: true, message: "Campo Requerido." },
                    ]}>
                        <Select
                            style={{
                                marginTop: 8
                            }}
                            className="impact-you-antecipate-modal-body-content-select-unique"
                            showSearch
                            placeholder="Selecione uma opção"
                            optionFilterProp="children"
                            onChange={onChange}
                            onSearch={onSearch}
                        >
                            {options}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        </Row>
    )
}

export default Output;