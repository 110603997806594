import React, { useState, useEffect } from 'react';
import { Row, Col, Form } from 'antd';
import './index.less';
import _service from '@netuno/service-client';

const CompositeMeasure = ({ calculationTcmgi, calculationAmi }) => {

    return (
        <Row className='composite-measure-modal' justify="flex-start">
            <Col className='composite-measure-modal-box' span={24}>
                <Form.Item name="ami">
                    <span className='composite-measure-modal-box-number'>{calculationAmi ? calculationAmi.toFixed(1) : '0'}</span>
                    <span className='composite-measure-modal-box-title'>Medida Agregada de Impacto (AMI)</span>
                </Form.Item>
            </Col>
        </Row >
    )
}

export default CompositeMeasure;