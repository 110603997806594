import { useEffect, useState } from "react";

import { Divider, Form, notification, Tooltip } from "antd";
import { InfoCircleOutlined, WarningOutlined } from "@ant-design/icons";

import dayjs from 'dayjs';

import _service from "@netuno/service-client";
import _auth from "@netuno/auth-client";

import ProjectModal from "../Modal";
import ProjectEditModalFooter from "./Footer";
import ProjectEditModalTitle from "./Title";
import ProjectEditModalSession from "./Session";
import ProjectEditModalCard from "./Card";
import SelectAreaInstitution from "./SelectAreaInstitution";
import SelectRank from "./SelectRank";

import { inactiveUserMesage, inactiveUserCode } from "../../../common/consts";

function ProjectEditModal({
  uid,
  open,
  onCancel,
  loadAlerts
}) {
  const [project, setProject] = useState(null);
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ami, setAmi] = useState(0);
  const [menu, setMenu] = useState([]);
  const [form] = Form.useForm(null);
  const dateFormat = "YYYY-MM-DD";

  const TooltipTRLTTM = () => (
    <Tooltip
      title={(
        <>
          <p>TRL 1 a TRL 3 - Baixo TRL</p>
          <p>TRL 3 a TRL 6 - Médio TRL</p>
          <p>TRL 6 a TRL 9 - Elevado TRL</p>
        </>
      )}
    >
      <spa style={{ marginLeft: "4px" }}><InfoCircleOutlined /></spa>
    </Tooltip>
  );

  const onFinish = (values) => {
    setLoading(true);

    const data = {
      ...values,
      consultancy_start: values?.period[0].format('YYYY-MM-DD'),
      consultancy_end: values?.period[1].format('YYYY-MM-DD'),
      uid
    };

    if (values.rank_risk === project.rank_risk) {
      data.revaluate_risk = project.revaluate_risk;
    }

    _service({
      url: "/project/calculation/ami",
      method: "POST",
      data,
      success: (res) => {
        data.ami = res.json.ami;
        setAmi(data.ami);

        if ((Math.trunc(data.ami * 10) / 10) === (Math.trunc(project.ami * 10) / 10)) {
          data.revaluate_ami = project.revaluate_ami;
        }

        _service({
          url: "project",
          method: "PUT",
          data,
          success: () => {
            setLoading(false);

            if (form.closeModal) {
              onCancel();
            }

            loadAlerts();

            notification.success({
              message: "Edição do Projeto",
              description: "Os dados do projeto foram atualizados com sucesso.",
            })
          },
          fail: (err) => {
            setLoading(false);
            console.error(err);

            const { json } = err;

            if (json) {
              if (json.code === inactiveUserCode) {
                notification.error({
                  key: inactiveUserCode,
                  message: inactiveUserMesage
                });
                _auth.logout();

                return;
              }
            }

            notification.error({
              message: "Não foi possível editar o projeto, contacte-nos através do chat de suporte."
            });
          }
        });
      },
      fail: (err) => {
        setLoading(false);
        console.error(err);

        const { json } = err;

        if (json) {
          if (json.code === inactiveUserCode) {
            notification.error({
              key: inactiveUserCode,
              message: inactiveUserMesage
            });
            _auth.logout();

            return;
          }
        }

        notification.error({
          message: "Não foi possível editar o projeto, contacte-nos através do chat de suporte."
        });
      }
    });

  }

  const onLoadProject = (values) => {
    setProject(values);
    setAmi(values.ami);

    form.setFieldsValue({
      ...values,
      area_uid: values.area.uid,
      institution_uid: values.institution.uid,
      period: [
        dayjs(values.consultancy_start, dateFormat),
        dayjs(values.consultancy_end, dateFormat)
      ]
    });
  };

  useEffect(() => {
    if (project) {
      setSessions([
        {
          key: "project-data",
          title: "Dados Gerais do Projeto",
          fields: [
            [{
              key: "institution",
              label: "Organização/Direção",
              value: (
                <SelectAreaInstitution
                  field="institution"
                  form={form}
                  initial={{
                    label: project.institution.name,
                    value: project.institution.uid
                  }}
                />
              )
            },
            {
              key: "area",
              label: "Área Operacional",
              value: (
                <SelectAreaInstitution
                  field="area"
                  form={form}
                  initial={{
                    label: project.area.name,
                    value: project.area.uid
                  }}
                />
              )
            }],
            [{
              key: "period",
              label: "Período de Execução",
            }],
            [{
              key: "project",
              label: "Projeto / Programa Acrónimo"
            }],
            [{
              key: "overview",
              label: "Visão Geral"
            }],
            [{
              key: "milestones",
              label: "Metas"
            }],
            [{
              key: "deliverables",
              label: "Entregáveis"
            }],
            [{
              key: "trl_ttm",
              label: (
                <>
                  Prontidão tecnológica vs Tempo para o mercado <TooltipTRLTTM />
                </>
              ),
              value: (
                <SelectRank
                  initial={project.trl_ttm}
                  option="trl_ttm"
                  name="trl_ttm"
                  form={form}
                />
              )
            }, {}],
          ]
        },
        {
          key: "alignement-and-attractiveness",
          title: "Alinhamento e Atratividade",
          fields: [
            [{
              key: "justify_strategic_alignement",
              label: "Alinhamento com os objetivos estratégicos da Organização"
            }],
            [{
              key: "justify_uniquiness_program_plan",
              label: "Grau de inovação, originalidade e efetividade do Plano Proposto e Realizado no Projeto"
            }],
            [{
              key: "justify_economical_environmental_relevance_business",
              label: "Relevância Económica, Societal e Ambiental Para a Organização/Setor/Região/País"
            }],
            [{
              key: "justify_competence_network_partners",
              label: "Relevância Estratégica dos Parceiros da Rede na Cocriação e Valorização do Conhecimento"
            }],
            [{
              key: "rank_alignement_attractiveness",
              label: "Avaliação",
              value: (
                <SelectRank
                  initial={project.rank_alignement_attractiveness}
                  option="alignement"
                  name="rank_alignement_attractiveness"
                  form={form}
                />
              )
            }]
          ]
        },
        {
          key: "output",
          title: "Outputs",
          fields: [
            [{
              key: "number_publications",
              label: "Nº de publicações científicas e não científicas"
            }, {}],
            [{
              key: "justify_number_publications",
              label: "Descrição"
            }],
            [{
              key: "number_outreaches",
              label: "Nº de iniciativas de divulgação, promoção e prémios"
            }, {}],
            [{
              key: "justify_number_outreach",
              label: "Descrição"
            }],
            [{
              key: "number_patents",
              label: "Nº de patentes"
            }, {}],
            [{
              key: "justify_number_patents",
              label: "Descrição"
            }],
            [{
              key: "number_norms_reports_techinical_assessments",
              label: "Nº de normas, relatórios e pareces técnicos"
            }, {}],
            [{
              key: "justify_number_norms_reports_techinical_assessments",
              label: "Descrição"
            }],
            [{
              key: "number_proof_concepts",
              label: "Nº de provas de conceito e projetos de ignição, modelos de viabilidade técnico-económica e protótipos"
            }, {}],
            [{
              key: "justify_number_proof_concepts",
              label: "Descrição"
            }],
            [{
              key: "number_new_technologies",
              label: "Nº de introdução de novas tecnologias e inovações em produtos e processos mais sustentáveis"
            }, {}],
            [{
              key: "justify_number_new_technologies",
              label: "Descrição"
            }],
            [{
              key: "number_new_potential_business_proposals",
              label: "Nº de novos potenciais negócios, licenças a terceiros, joint ventures"
            }, {}],
            [{
              key: "justify_number_new_potential_business_proposals",
              label: "Descrição"
            }],
            [{
              key: "number_new_strategic_partnerships",
              label: "Nº de novas parcerias estratégicas"
            }, {}],
            [{
              key: "justify_number_new_strategic_partnerships",
              label: "Descrição"
            }],
            [{
              key: "rank_output",
              label: "Avaliação",
              value: (
                <SelectRank
                  initial={project.rank_output}
                  option="output"
                  name="rank_output"
                  form={form}
                />
              )
            }, {}],
          ]
        },
        {
          key: "outcomes",
          title: "Efeitos/Outcomes",
          fields: [
            [{
              key: "outcome_increase_recognition",
              value: "Aumento do conhecimento público"
            }],
            [{
              key: "outcome_public_awareness",
              value: "Consciencialização pública através de uma maior literacia do conhecimento e participação da sociedade civil"
            }],
            [{
              key: "outcome_knowledge_enlargement",
              value: "Aumento do conhecimento em cocriação e contributo das diferentes hélices de inovação"
            }],
            [{
              key: "outcomes_justify",
              label: "Justificação"
            }],
            [{
              key: "rank_outcomes",
              label: "Avaliação",
              value: (
                <SelectRank
                  initial={project.rank_outcomes}
                  option="outcomes"
                  name="rank_outcomes"
                  form={form}
                />
              )
            }, {}],
          ]
        },
        {
          key: "impact",
          title: "Impacto Antecipado",
          subtitle: "Sugestão de possíveis cálculos para a avaliação: previsão do retorno do investimento por ano após terminus do projeto, contemplar os direitos resultantes de licenças, poupanças de custos, lucros;  contributo do Programa/Projeto para a alteração da estrutura dos rendimentos da organização, entrada em novos mercados ou em novas áreas de negócio, processos de geração de novos negócios, difusão tecnológica e efeitos de repercussões “spillovers”, alteração do valor da despesa em IDT; valor económico, social e ambiental gerado tentando sempre estimar o impacto esperado com base nos pressupostos assumidos em % do EBITDA",
          fields: [
            [{
              key: "description_impact_long_term_measure",
              label: "Impacto Antecipado"
            }],
            [{
              key: "rank_impact_long_term_measure",
              label: "Impacto no EBITDA anual a médio/longo prazo",
              value: (
                <SelectRank
                  initial={project.rank_impact_long_term_measure}
                  option="ebitda"
                  name="rank_impact_long_term_measure"
                  form={form}
                />
              )
            }, {}]
          ]
        },
        {
          key: "agregate-measure-impact",
          fields: [
            [{
              key: "ami",
              value: (
                <>
                  <Divider />
                  <ProjectEditModalCard
                    data={project.ami}
                    alert={project.revaluate_ami}
                    title="MEDIDA AGREGADA DE IMPACTO (AMI)"
                  />
                  <Divider />
                </>
              )
            }]
          ]
        },
        {
          key: "risk",
          title: "Estimativa de Risco",
          subtitle: "Descrever os riscos que podem colocar em causa a avaliação de impacto anterior e ações para mitigar esses riscos",
          fields: [
            [{
              key: "risk_justify",
              label: "Justificação"
            }],
            [{
              key: "rank_risk",
              label: project.revaluate_risk ? (
                <>
                  <Tooltip
                    title="Foi requerido uma reavaliação para este indicador."
                  >
                    <span className="revaluate-alert">
                      <WarningOutlined />
                    </span>
                  </Tooltip>
                  Avaliação
                </>
              ) : "Avaliação",
              value: (
                <SelectRank
                  initial={project.rank_risk}
                  option="risk"
                  name="rank_risk"
                  form={form}
                />
              )
            }, {}]
          ]
        },
      ]);

      setMenu([
        {
          href: '#project-data',
          title: 'Dados Gerais do Projeto',
          className: 'project-data'
        },
        {
          href: '#alignement-and-attractiveness',
          title: 'Alinhamento e Atratividade',
          className: 'alignement-and-attractiveness'
        },
        {
          href: '#output',
          title: 'Outputs',
          className: 'output'
        },
        {
          href: '#outcomes',
          title: 'Efeitos/Outcomes',
          className: 'outcomes'
        },
        {
          href: '#impact',
          title: 'Impacto Antecipado',
          className: 'impact'
        },
        {
          href: '#agregate-measure-impact',
          title: project.revaluate_ami ? (
            <span className="project-modal-menu__alert">
              Medida Agregada de Impacto (AMI)
            </span>
          ) : "Medida Agregada de Impacto (AMI)",
          className: 'agregate-measure-impact'
        },
        {
          href: '#risk',
          title: project.revaluate_risk ? (
            <span className="project-modal-menu__alert">
              Estimativa de Risco
            </span>
          ) : "Estimativa de Risco",
          className: 'risk'
        },
      ]);
    }
  }, [project]);

  useEffect(() => {
    if (sessions.length > 0 && project) {
      let href = "";

      if (project.revaluate_risk) {
        href = "#risk";
      }

      if (project.revaluate_ami) {
        href = "#agregate-measure-impact";
      }

      if (href) {
        setTimeout(() => {
          const link = document.createElement("a");
          link.href = href;

          link.click();
        }, 500);
      }
    }
  }, [sessions]);

  return (
    <ProjectModal
      menu={menu}
      uid={uid}
      open={open}
      title={project && <ProjectEditModalTitle project={{ ...project, ami }} />}
      footer={project &&
        <ProjectEditModalFooter
          loading={loading}
          form={form}
          onCancel={onCancel}
        />
      }
      onCancel={onCancel}
      onLoadProject={onLoadProject}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
      >
        {sessions.map((session) => (
          <ProjectEditModalSession
            key={session.key}
            session={session}
            project={project}
          />
        ))}
      </Form>
    </ProjectModal>
  );
}

export default ProjectEditModal;
