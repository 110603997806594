import { useState } from "react";

import { Button, Col, notification, Row, Space, Tooltip, Typography } from "antd";
import { WarningOutlined } from "@ant-design/icons";

import _service from "@netuno/service-client";
import _auth from "@netuno/auth-client";

import { inactiveUserMesage, inactiveUserCode } from "../../../../common/consts";

import "./index.less";

function ProjectRevaluateModalFooter({
    project,
    toRevaluate,
    toRevaluateCount,
    onCancel,
    onClose,
}) {
    const [open, setOpen] = useState(false);
    const [loadingAccept, setLoadingAccept] = useState(false);
    const [loadingNotify, setLoadingNotify] = useState(false);

    const onToggleTooltip = () => setOpen(!open);

    const onAccept = () => {
        setLoadingAccept(true);

        _service({
            url: "project",
            method: "PUT",
            data: {
                ...project,
                area_uid: project.area.uid,
                institution_uid: project.institution.uid,
                alert: false,
                revaluate_risk: false,
                revaluate_ami: false,
                notify: false
            },
            success: (res) => {
                notification.success({
                    message: "Alerta removido com sucesso."
                });
                setLoadingAccept(false);
                console.log("accpet")
                onClose({
                    reloadAlerts: true
                });
            },
            fail: (err) => {
                setLoadingAccept(false);

                const { json } = err;

                if (json) {
                    if (json.code === inactiveUserCode) {
                        notification.error({
                            key: inactiveUserCode,
                            message: inactiveUserMesage
                        });
                        _auth.logout();

                        return;
                    }
                }

                notification.error({
                    message: "Falha ao remover alerta."
                });
            }
        });
    };

    const onNotify = () => {
        setLoadingNotify(true);

        _service({
            url: "project",
            method: "PUT",
            data: {
                ...project,
                area_uid: project.area.uid,
                institution_uid: project.institution.uid,
                alert: false,
                revaluate_risk: toRevaluate.find(({ name }) => name === "risk") && !toRevaluate.find(({ name, accept }) => name === "risk" && accept),
                revaluate_ami: toRevaluate.find(({ name }) => name === "ami") && !toRevaluate.find(({ name, accept }) => name === "ami" && accept),
                notify: false
            },
            success: () => {
                notification.success({
                    message: "O gestor responsável será notificado."
                });
                setLoadingNotify(false);
                onClose({
                    reloadAlerts: true
                });
            },
            fail: (err) => {
                setLoadingNotify(false);

                console.error(err);

                const { json } = err;

                if (json) {
                    if (json.code === inactiveUserCode) {
                        notification.error({
                            key: inactiveUserCode,
                            message: inactiveUserMesage
                        });
                        _auth.logout();

                        return;
                    }
                }

                notification.error({
                    message: "Falha ao notificar gestor"
                });
            }
        });
    };

    return (
        <Row justify="end" align="middle">
            <Col>
                <Tooltip
                    overlayClassName="cancel-tooltip"
                    open={open}
                    trigger="click"
                    title={(
                        <Row className="cancel-tooltip__inner" justify="end">
                            <Col span="24">
                                <Typography.Paragraph className="cancel-tooltip__inner__text">
                                    <span className="cancel-tooltip__inner__text__icon"><WarningOutlined /></span>
                                    Tem certeza que deseja cancelar a reavaliação do projeto?
                                </Typography.Paragraph>
                                <Row justify="end" gutter={8}>
                                    <Col>
                                        <Button
                                            className="cancel-tooltip__inner__not"
                                            onClick={onToggleTooltip}
                                            type="default"
                                        >
                                            Não
                                        </Button>
                                    </Col>
                                    <Col>
                                        <Button
                                            className="cancel-tooltip__inner__yes"
                                            type="primary"
                                            danger
                                            onClick={() => {
                                                onToggleTooltip();
                                                onCancel();
                                            }}
                                        >
                                            Sim
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    )}
                >
                    <Button
                        onClick={onToggleTooltip}
                        type="link"
                        danger
                    >
                        Cancelar
                    </Button>
                </Tooltip>
            </Col>
            <Col>
                <Button
                    type="link"
                    disabled={!toRevaluate.find(({ accept }) => !accept)}
                    loading={loadingNotify}
                    onClick={onNotify}
                >
                    Guardar e Notificar Gestor
                </Button>
            </Col>
            <Col>
                <Button
                    type="primary"
                    disabled={toRevaluate.find(({ accept }) => !accept) || toRevaluate.length !== toRevaluateCount}
                    loading={loadingAccept}
                    onClick={onAccept}
                >
                    Aceitar e Fechar
                </Button>
            </Col>
        </Row>
    )
}

export default ProjectRevaluateModalFooter;
