import { useState, useEffect } from "react";

import { connect } from 'react-redux';
import { dashboardReloadAction, dashboardOrdenationAction } from "../../../redux/actions"
import { bindActionCreators } from 'redux';

import { Button, Col, Popover, Row, Table, Popconfirm, notification, Spin, Typography } from "antd";
import { EditOutlined, FileSearchOutlined, WarningOutlined, DeleteOutlined } from "@ant-design/icons";

import _service from "@netuno/service-client";
import _auth from "@netuno/auth-client";

import dayjs from 'dayjs';

import EditProjectModal from "../EditProjectModal";
import InfoModal from "../InfoModal";

import { inactiveUserMesage, inactiveUserCode } from "../../../common/consts";
import InfoModalPrint from "../InfoModal/InfoModalPrint";
import { pdf } from "@react-pdf/renderer";
import { PrintButton } from "../InfoModal/InfoModalPrint/PrintButton";

import "./index.less";
import Modal from "antd/es/modal/Modal";

function ListProjects({
    type,
    dashboardFilters,
    dashboardReloadAction,
    dashboardOrdenation,
    dashboardOrdenationAction,
    reloads
}) {
    const [data, setData] = useState([]);
    const [loadingProjects, setLoadingProjects] = useState(false);
    const [projects, setProjects] = useState([]);
    const [total, setTotal] = useState(0);
    const [editingRecord, setEditingRecord] = useState(null);
    const [loadingData, setLoadingData] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [openProjectModal, setOpenProjectModal] = useState(false);
    const [ordenation, setOrdenation] = useState({});
    const [endPagination, setEndPagination] = useState(false);
    const [uid, setUid] = useState("");
    const [defaultLink, setDefaultLink] = useState("#");
    const [loadingPrint, setLoadingPrint] = useState(false);
    const [pagination, setPagination] = useState({
        size: 10,
        page: 1
    });

    const ordenations = {
        institution: "institution",
        area: "area",
        rank_risk: "risk",
        project: "project",
        ami: "ami"
    };
    const directions = {
        ascend: "asc",
        descend: "desc"
    };

    const configs = {
        dashboard: {
            hideColumns: ["rank_risk", "ami"]
        },
        management: {
            hideColumns: []
        }
    };

    const onCell = (link) => {
        return (_, rowIndex) => ({
            onClick: () => {
                setOpenProjectModal(true);
                setUid(projects[rowIndex].uid);
                setDefaultLink(link);
            }
        });
    }

    const onCancel = () => {
        setOpenProjectModal(false)
        setUid("")
    };

    const handleUpdateAndReload = () => {
        getProject(false)
    };

    const onRevaluate = (project) => {
        setOpenProjectModal(false)
        getInfoProject(project)
    };

    const handlePrint = (project) => {
        setLoadingPrint(true)
        _service({
            url: '/project',
            method: 'GET',
            data: {
                uid: project.uid
            },
            success: async (response) => {
                const { project: { institution, area, ...others } } = response.json;
                others.dates = [dayjs(others.consultancy_start, 'YYYY-MM-DD'), dayjs(others.consultancy_end, 'YYYY-MM-DD')];
                const editingRecord = {
                    ...others,
                    area_uid: area.uid,
                    institution_uid: institution.uid,
                };
                const docBlob = await pdf((
                    <InfoModalPrint
                        project={editingRecord}
                    />
                )).toBlob();
                setLoadingPrint(false)


                const url = window.URL.createObjectURL(docBlob);
                const link = document.createElement("a");
                link.href = url;
                link.target = "_blank";

                link.click();
            },
            fail: (err) => {
                const { json } = err;

                if (json) {
                    const { code } = json;

                    if (code === inactiveUserCode) {
                        notification.error({
                            key: inactiveUserCode,
                            message: inactiveUserMesage
                        });
                        _auth.logout();
                    }
                }

                console.error(err);
            }
        });
    };


    const columns = [
        {
            title: 'Organização/Direção',
            key: 'institution',
            dataIndex: 'institution',
            render: (institution) => institution.name,
            sorter: true,
            onCell: onCell("#project-data"),
        },
        {
            title: 'Área Operacional',
            key: 'area',
            dataIndex: 'area',
            render: (area) => area.name,
            sorter: true,
            onCell: onCell("#project-data"),
        },
        {
            title: 'AMI',
            key: 'ami',
            dataIndex: 'ami',
            render: (ami) => Math.trunc(ami * 10) / 10,
            sorter: {
                compare: (a, b) => a.ami - b.ami,
                tooltip: 'Clique para ordenar'
            },
            onCell: onCell("#agregate-measure-impact"),
            width: "9%"
        },
        {
            title: 'Risco',
            key: 'rank_risk',
            dataIndex: 'rank_risk',
            render: (rank_risk) => rank_risk,
            sorter: true,
            onCell: onCell("#risk"),
            width: "9%"
        },
        {
            title: 'Projeto',
            key: 'project',
            dataIndex: 'project',
            render: (project) => project,
            sorter: true,
            onCell: onCell("#project-data"),
            width: "27%"
        },
        {
            title: 'Ações',
            key: 'actions',
            dataIndex: 'actions',
            className: 'list-projects__column__actions',
            width: "10%",
            render: (_, project) => {
                return (
                    <div style={{ display: "flex" }}>
                        <Popover
                            placement="leftTop"
                            content={(
                                <div>
                                    <p>Organização/Direção: <strong>{project.institution.name}</strong></p>
                                    <p>Área Operacional: <strong>{project.area.name}</strong></p>
                                    <p>Projetos: <strong>{project.project}</strong></p>
                                    <p>Risco: <strong>{project.rank_risk}</strong></p>
                                    <p>Impacto Agregado: <strong>{Math.trunc(project.ami * 10) / 10}</strong></p>
                                    <p>Prontidão tecnológica vs Tempo: <strong>{project.trl_ttm}</strong></p>
                                </div>
                            )}
                        >
                            <Button
                                className="edit-institution-btn"
                                type={'link'}
                            >
                                <FileSearchOutlined style={{ fontSize: 20, color: "#3CBA92" }} />
                            </Button>

                        </Popover>
                        <PrintButton onClick={() => handlePrint(project)} icon={true} title={false} />
                        <Button
                            className="edit-institution-btn"
                            type="link"
                            onClick={() => getInfoProject(project)}
                        >
                            <EditOutlined style={{ fontSize: 20, color: "#3CBA92" }} />
                        </Button>
                        <Popconfirm
                            placement="top"
                            title="Tem certeza que deseja eliminar este projeto?"
                            okText="Sim"
                            cancelText="Não"
                            onConfirm={() => deletProject(project)}
                            okType="link"
                            okButtonProps={{
                                className: "pop-confirm-confirm-btn",
                            }}
                            cancelButtonProps={{
                                className: "pop-confirm-cancel-btn",
                            }}
                            icon={<WarningOutlined style={{ color: "#E70000" }} />}
                            rootClassName="pop-confirm"
                        >
                            <Button className="delete-institution-btn" type="link">
                                <DeleteOutlined
                                    style={{ fontSize: 20, color: "rgba(255, 0, 0, 0.6)" }}
                                />
                            </Button>
                        </Popconfirm>
                    </div>
                )
            },
        },
    ];

    const onPageChange = (currentPage) => {
        setPagination({
            ...pagination,
            page: currentPage
        });
    };

    const getInfoProject = (record) => {
        setLoadingData(true)
        _service({
            url: '/project',
            method: 'GET',
            data: {
                uid: record.uid
            },
            success: (response) => {
                const { project: { institution, area, ...others } } = response.json;
                others.dates = [dayjs(others.consultancy_start, 'YYYY-MM-DD'), dayjs(others.consultancy_end, 'YYYY-MM-DD')]
                setEditingRecord({
                    ...others,
                    area_uid: area.uid,
                    institution_uid: institution.uid,
                });
                setLoadingData(false)
                setIsModalVisible(true);
            },
            fail: (err) => {
                const { json } = err;

                if (json) {
                    const { code } = json;

                    if (code === inactiveUserCode) {
                        notification.error({
                            key: inactiveUserCode,
                            message: inactiveUserMesage
                        });
                        _auth.logout();
                    }
                }

                console.error(err);
            }
        })
    };

    const onChange = (pagination, filters, sorter, extra) => {
        const { field, order } = sorter;
        const newOrdenation = {
            by: ordenations[field],
            direction: directions[order]
        };

        if (JSON.stringify(newOrdenation) !== JSON.stringify(ordenation)) {
            setOrdenation(newOrdenation);
            console.log(newOrdenation)
            dashboardOrdenationAction({
                ...dashboardOrdenation,
                projects: newOrdenation
            });
        }
    };

    const getProject = (reload = true) => {
        setLoadingProjects(true);

        const filter = {
            institution_uids: [],
            area_uids: [],
            project_uids: [],
        };

        if (dashboardFilters && dashboardFilters.institution_uids) {
            filter.institution_uids = dashboardFilters.institution_uids.map(({ uid }) => uid);
        }

        if (dashboardFilters && dashboardFilters.area_uids) {
            filter.area_uids = dashboardFilters.area_uids.map(({ uid }) => uid);
        }

        if (dashboardFilters && dashboardFilters.project_uids) {
            filter.project_uids = dashboardFilters.project_uids.map(({ uid }) => uid);
        }

        _service({
            url: "project/list",
            method: "POST",
            data: {
                filter,
                ordenation,
                pagination
            },
            success: (res) => {
                if (res.json) {
                    setProjects(res.json.projects);
                    setTotal(res.json.total);
                }
                setLoadingProjects(false);
            },
            fail: (err) => {
                const { json } = err;

                if (json) {
                    const { code } = json;

                    if (code === inactiveUserCode) {
                        notification.error({
                            key: inactiveUserCode,
                            message: inactiveUserMesage
                        });
                        _auth.logout();
                    }
                }

                console.error(err);
            }
        })
    };
    useEffect(() => {
        getProject()
    }, [dashboardFilters, ordenation, pagination]);

    useEffect(() => {
        setPagination({
            ...pagination,
            page: 1
        });
    }, [dashboardFilters, ordenation, reloads]);

    const deletProject = (record) => {
        _service({
            url: "/project",
            method: "DELETE",
            data: {
                uid: record.uid,
            },
            success: (response) => {
                if (type === "dashboard") {
                    dashboardReloadAction()
                } else {
                    if (endPagination) {
                        setData(data.filter((item) => item.uid !== record.uid));
                    } else {
                        getProject();
                    }
                }
            },
            fail: (err) => {
                const { json } = err;

                if (json) {
                    const { code } = json;

                    if (code === inactiveUserCode) {
                        notification.error({
                            key: inactiveUserCode,
                            message: inactiveUserMesage
                        });
                        _auth.logout();
                    }
                }

                console.error(err);
            }
        });
    };

    return (
        <Row align="middle" justify={'space-between'}>
            <Col span="24">
                <Table
                    columns={columns.filter(({ key }) => !configs[type].hideColumns.includes(key))}
                    dataSource={projects}
                    onChange={onChange}
                    loading={loadingProjects}
                    locale={{
                        triggerDesc: 'Ordem alfabética (Z-A)',
                        triggerAsc: 'Ordem alfabética (A-Z)',
                    }}
                    pagination={{
                        position: ["bottomCenter"],
                        pageSize: pagination.size,
                        onChange: onPageChange,
                        current: pagination.page,
                        total: total
                    }}
                    style={{
                        cursor: "pointer",
                        marginTop: "10px"
                    }}
                />
                <InfoModal
                    uid={uid}
                    open={openProjectModal}
                    onCancel={onCancel}
                    onRevaluate={onRevaluate}
                    defaultLink={defaultLink}
                />
                {editingRecord && (
                    <EditProjectModal
                        show={isModalVisible}
                        editingRecord={editingRecord}
                        onClose={() => setIsModalVisible(false)}
                        onCancel={() => setEditingRecord(null)}
                        handleUpdateAndReload={handleUpdateAndReload}
                    />
                )}
                <Modal
                    open={loadingPrint}
                    footer={false}
                    closable={false}
                    bodyStyle={{
                        padding: "24px 0px"
                    }}
                    maskStyle={{
                        backdropFilter: "blur(2px)"
                    }}
                    centered
                >
                    <Row
                        justify="center"
                        gutter={[0, 15]}
                        style={{
                            width: "min-content !important"
                        }}
                    >
                        <Col style={{ marginBottom: "14px" }}>
                            <Spin />
                        </Col>

                        <Col span="24">
                            <Typography.Paragraph style={{ textAlign: "center", fontSize: "20px" }}>
                                Aguarde enquanto preparamos o seu PDF
                            </Typography.Paragraph>
                        </Col>
                    </Row>
                </Modal>
            </Col>
        </Row>
    );
}

const mapStateToProps = store => {
    const { dashboardFilters } = store.dashboardFiltersState;
    const { dashboardOrdenation } = store.dashboardOrdenationState;

    return {
        dashboardFilters,
        dashboardOrdenation,
        reloads: store.dashboardReloadState
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    dashboardReloadAction,
    dashboardOrdenationAction
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ListProjects);
