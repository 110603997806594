import { WarningOutlined } from "@ant-design/icons";
import { Col, Row, Tooltip, Typography } from "antd";

import "./index.less";

function ProjectRevaluateModalCard({
    data,
    title,
    alert,
}) {
    return (
        <Row
            className={`project-edit-modal-card ${alert ? "project-modal-card__alert" : "project-modal-card__default"}`}
            align="center"
            justify="center"
        >
            <Col span="24">
                <Typography.Title
                    className="project-edit-modal-card__text"
                    level={2}
                >
                    {Math.trunc(data * 10) / 10}
                </Typography.Title>
            </Col>
            <Col span="24">
                <Typography.Paragraph
                    className="project-edit-modal-card__text"
                    style={{
                        fontSize: "14px"
                    }}
                >
                    {
                        alert && (
                            <Tooltip
                                title="Foi requerido uma reavaliação para este indicador."
                            >
                                <span><WarningOutlined size={34} /></span>
                            </Tooltip>
                        )
                    }
                    {title}
                </Typography.Paragraph>
            </Col>
        </Row>
    )
}

export default ProjectRevaluateModalCard;