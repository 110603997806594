import React from "react";
import { Button, Modal, Tabs } from "antd";
import InstitutionsTab from "./InstitutionsTab";
import AreasTab from "./AreasTab";

import "./index.less";

const InstitutionsAreasModal = ({ show, onClose }) => {
  const items = [
    {
      key: "institutions",
      label: `Organização/Direção`,
      children: <InstitutionsTab onCloseModal={onClose} />,
    },
    {
      key: "areas",
      label: `Área Operacional`,
      children: <AreasTab onCloseModal={onClose} />,
    },
  ];

  return (
    <Modal
      className="modal-institutions-areas"
      title="Definições de Organizações e Áreas"
      open={show}
      maskClosable={false}
      onCancel={onClose}
      destroyOnClose={true}
      footer={
        <Button onClick={onClose} type="link">
          Fechar
        </Button>
      }
    >
      <Tabs defaultActiveKey="1" items={items} />
    </Modal>
  );
};

export default InstitutionsAreasModal;
