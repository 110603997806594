import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';

import { connect } from "react-redux"
import { bindActionCreators } from 'redux';

import { Form, notification } from 'antd';

import _service from '@netuno/service-client';
import _auth from '@netuno/auth-client';

import ProjectData from '../../Container/ProjectData'
import Alignement from '../../Container/Alignement';
import Output from '../../Container/Output';
import Outcomes from '../../Container/Outcomes';
import ImpactYouAntecipate from '../../Container/ImpactYouAntecipate';
import CompositeMeasure from '../../Container/CompositeMeasure';
import RiskAssessment from '../../Container/RiskAssessment';

import { dashboardReloadAction } from "../../../../redux/actions"
import { inactiveUserMesage, inactiveUserCode } from "../../../../common/consts";

import './index.less';

const AddForm = React.forwardRef(({ handleGetValuesCalculation, dashboardReloadAction }, ref) => {
    const [loading, setLoading] = useState(true);
    const [calculationAmi, setCalculationAmi] = useState(0);
    const [refForm] = Form.useForm();

    const SAA = Form.useWatch("rank_alignement_attractiveness", refForm);
    const OP = Form.useWatch("rank_output", refForm);
    const OC = Form.useWatch("rank_outcomes", refForm);
    const AIA = Form.useWatch("rank_impact_long_term_measure", refForm);

    const onSubmit = () => {
        refForm.submit();
    };

    const onFinish = (values) => {
        setLoading(true);
        const startDate = values?.dates[0].format("YYYY-MM-DD");
        const endDate = values?.dates[1].format("YYYY-MM-DD");
        _service({
            url: "/project/calculation/ami",
            method: "POST",
            data: values,
            success: (response => {
                setCalculationAmi(response.json.ami)
                handleGetValuesCalculation(response);
                _service({
                    url: "/project",
                    method: "POST",
                    data: {
                        institution_uid: refForm.getFieldValue("institution_uid"),
                        area_uid: refForm.getFieldValue("area_uid"),
                        ami: response.json.ami,
                        number_proof_concepts: refForm.getFieldValue("number_proof_concepts"),
                        consultancy_start: startDate,
                        consultancy_end: endDate,
                        project: refForm.getFieldValue("project"),
                        justify_competence_network_partners: refForm.getFieldValue("justify_competence_network_partners"),
                        deliverables: refForm.getFieldValue("deliverables"),
                        description_impact_long_term_measure: refForm.getFieldValue("description_impact_long_term_measure"),
                        milestones: refForm.getFieldValue("milestones"),
                        description_output: refForm.getFieldValue("description_output"),
                        overview: refForm.getFieldValue("overview"),
                        justify_uniquiness_program_plan: refForm.getFieldValue("justify_uniquiness_program_plan"),
                        justify_risk: refForm.getFieldValue("justify_risk"),
                        justify_strategic_alignement: refForm.getFieldValue("justify_strategic_alignement"),
                        justify_number_new_potential_business_proposals: refForm.getFieldValue("justify_number_new_potential_business_proposals"),
                        justify_number_proof_concepts: refForm.getFieldValue("justify_number_proof_concepts"),
                        justify_number_norms_reports_techinical_assessments: refForm.getFieldValue("justify_number_norms_reports_techinical_assessments"),
                        justify_number_patents: refForm.getFieldValue("justify_number_patents"),
                        justify_number_outreach: refForm.getFieldValue("justify_number_outreach"),
                        justify_number_publications: refForm.getFieldValue("justify_number_publications"),
                        justify_economical_environmental_relevance_business: refForm.getFieldValue("justify_economical_environmental_relevance_business"),
                        number_norms_reports_techinical_assessments: refForm.getFieldValue("number_norms_reports_techinical_assessments"),
                        outcome_increase_recognition: refForm.getFieldValue("outcome_increase_recognition"),
                        outcome_knowledge_enlargement: refForm.getFieldValue("outcome_knowledge_enlargement"),
                        outcome_public_awareness: refForm.getFieldValue("outcome_public_awareness"),
                        outcomes_justify: refForm.getFieldValue("outcomes_justify"),
                        number_outreaches: refForm.getFieldValue("number_outreaches"),
                        number_new_strategic_partnerships: refForm.getFieldValue("number_new_strategic_partnerships"),
                        justify_number_new_strategic_partnerships: refForm.getFieldValue("justify_number_new_strategic_partnerships"),
                        number_new_technologies: refForm.getFieldValue("number_new_technologies"),
                        justify_number_new_technologies: refForm.getFieldValue("justify_number_new_technologies"),
                        number_patents: refForm.getFieldValue("number_patents"),
                        number_new_potential_business_proposals: refForm.getFieldValue("number_new_potential_business_proposals"),
                        number_publications: refForm.getFieldValue("number_publications"),
                        rank_alignement_attractiveness: refForm.getFieldValue("rank_alignement_attractiveness"),
                        rank_impact_long_term_measure: refForm.getFieldValue("rank_impact_long_term_measure"),
                        rank_outcomes: refForm.getFieldValue("rank_outcomes"),
                        rank_output: refForm.getFieldValue("rank_output"),
                        rank_risk: refForm.getFieldValue("rank_risk"),
                        risk_justify: refForm.getFieldValue('risk_justify'),
                        trl_ttm: refForm.getFieldValue("trl_ttm")
                    },
                    success: (response) => {
                        notification["success"]({
                            message: 'Criação do Projeto',
                            description: 'Os dados do projeto foram registrados com sucesso.',
                        });
                        dashboardReloadAction()
                    },
                    fail: (err) => {
                        const { json } = err;

                        if (json) {
                            if (json.code === inactiveUserCode) {
                                notification.error({
                                    key: inactiveUserCode,
                                    message: inactiveUserMesage
                                });
                                _auth.logout();

                                return;
                            }
                        }

                        notification["error"]({
                            message: 'Não foi possível criar o projeto, contacte-nos através do chat de suporte.'
                        });
                    }
                });
            }),
            fail: (err) => {
                const { json } = err;

                if (json) {
                    if (json.code === inactiveUserCode) {
                        notification.error({
                            key: inactiveUserCode,
                            message: inactiveUserMesage
                        });
                        _auth.logout();

                        return;
                    }
                }

                notification["error"]({
                    message: "Não foi possível calcular o valor do AMI, contacte-nos através do chat de suporte.",
                });
            },
        });

    }

    React.useImperativeHandle(ref, () => ({
        refForm,
        onSubmit: () => {
            return refForm.validateFields().then((values) => {
                return onSubmit();
            })
        },
    }));


      useEffect(() => {
        _service({
            url: "/project/calculation/ami",
            method: "POST",
            data: {
                rank_alignement_attractiveness: SAA ? SAA : 0,
                rank_output: OP ? OP : 0,
                rank_outcomes: OC ? OC : 0,
                rank_impact_long_term_measure: AIA ? AIA : 0,
            },
            success: (response) => {
                handleGetValuesCalculation(response)
                setCalculationAmi(response.json.ami)
            },
            fail: (error) => {}
        })
      },[SAA, OP, OC, AIA])

    return (
        <Form id="add-project-form" form={refForm} onFinish={onFinish}>
            <div className='area-project-modal'>
                <div
                    id="project-data"
                >
                    <ProjectData />
                </div>
                <div
                    id="alignement-and-attractiveness"
                >
                    <Alignement />
                </div>
                <div
                    id="output"
                >
                    <Output />
                </div>
                <div
                    id="outcomes"
                >
                    <Outcomes />
                </div>
                <div
                    id="impact"
                >
                    <ImpactYouAntecipate />
                </div>
                <div
                    id="agregate-measure-impact"
                >
                    <CompositeMeasure calculationAmi={calculationAmi} />
                </div>
                <div
                    id="risk"
                >
                    <RiskAssessment />
                </div>
            </div>
        </Form>
    );

})

const mapStateToProps = store => {
    return {
        reloads: store.dashboardReloadState
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    dashboardReloadAction
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(AddForm);
