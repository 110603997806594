import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
import { Select, Row, Col, Form, Input, Popover, DatePicker, Space, Divider, Button, notification } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import './index.less';
import _service from '@netuno/service-client';
import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons'

const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Option } = Select;


const ProjectData = () => {
    const [managementInstitution, setManagementInstitution] = useState([]);
    const [areaInstitution, setAreaInstitution] = useState([]);
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false);
    const [newItem, setNewItem] = useState(null);
    const [checkUser, setCheckUser] = useState(false);
    const [name, setName] = useState('');

    const MAX_NAME_LENGTH = 75;

    const errorMessageInstituion = {
        "institution-exists": (name) => `A Organização/Direção "${name}" já existe.`,
    };

    const errorMessageArea = {
        "area-exists": (name) => `A área operacional "${name}" já existe.`,
    };

    const onNameChange = (event) => {
        const value = event.target.value;
        if (value.length <= MAX_NAME_LENGTH) {
            setName(value);
        }
    };
    const addInstitution = (values) => {
        setLoading(true);

        const newItemObject = {
            name: name,
            uid: uuidv4(),
        };

        setManagementInstitution([...managementInstitution, newItemObject]);
        setName('');
        setNewItem(newItemObject);
        setTimeout(() => {
            const inputElement = document.querySelector('.add-direction input');
            if (inputElement) {
                inputElement.focus();
            }
        }, 0);

        _service({
            url: "/institution",
            method: "POST",
            data: newItemObject,
            success: (response) => {
                setLoading(false);
                setData([...data, response.json.institution]);
            },
            fail: (err) => {
                setLoading(false);
                if (err.json) {
                    const { code } = err.json;
                    const error = errorMessageInstituion[code];

                    if (error) {
                        notification.error({
                            message: error(newItemObject.name)
                        });
                        return;
                    }

                }
            }
        });
    };

    const addArea = (values) => {
        setLoading(true);

        const newItemObject = {
            name: name,
            uid: uuidv4(),
        };

        setAreaInstitution([...areaInstitution, newItemObject]);
        setName('');
        setNewItem(newItemObject);
        setTimeout(() => {
            const inputElement = document.querySelector('.add-area input');
            if (inputElement) {
                inputElement.focus();
            }
        }, 0);

        _service({
            url: "/area",
            method: "POST",
            data: newItemObject,
            success: (response) => {
                setData([response.json.area, ...data]);
                setLoading(false);
            },
            fail: (err) => {
                console.error(err);
                setLoading(false);

                if (err.json) {
                    const { code } = err.json;
                    const errorMessage = errorMessageArea[code];

                    if (errorMessage) {
                        notification.error({
                            message: errorMessage(newItemObject.name)
                        });
                        return;
                    }
                }
            }
        });
    };

    useEffect(() => {
        setLoading(true)
        _service({
            url: "/institution/list",
            method: "POST",
            success: (response) => {
                setManagementInstitution([...response.json.institutions]);
                setLoading(false)
            },
        });
        _service({
            url: "/area/list",
            method: "POST",
            success: (response) => {
                setAreaInstitution([...response.json.areas]);
                setLoading(false)
            },
        });
    }, [])

    useEffect(() => {
        _service({
            method: 'GET',
            url: 'people',
            success: (response) => {
                if (response.json.result) {
                    if (response.json.data.admin) {
                        setCheckUser(true);
                    }
                } else {
                    notification["warning"]({
                        message: 'Dados do Utilizador',
                        description: response.json.error,
                    });
                }
            },
            fail: (e) => {
                console.error('Dados do Utilizador', e);
            }
        });
    }, []);

    const options = [];

    for (let i = 1; i <= 9; i++) {
        options.push(
            <Option key={i} value={i}>
                {i}
            </Option>
        );
    }

    const content = (
        <div style={{
            display: "flex",
            flexDirection: "column",
            width: "550px",
            zIndex: 9999,
            position: "relative"
        }}>
            <div style={{
                width: "100%"
            }}>
                <img style={{
                    width: "100%",
                    objectFit: "cover"
                }} src="/images/trl_image.jpg" />
            </div>
        </div>

    );

    return (
        <Row className='project-data-modal' justify="flex-start">
            <Col className='project-data-modal-title'>
                <h1>Dados Gerais do Projeto</h1>
            </Col>
            <Row className="project-data-modal-body" gutter={[10, 24]}>
                <Col span={24}>
                    <span>Organização/Direção</span>
                    <Form.Item name="institution_uid"
                        rules={[
                            { required: true, message: 'Campo Requerido.' },
                        ]}
                    >
                        <Select
                            style={{
                                marginTop: 8
                            }}
                            placeholder="Selecione uma opção"
                            dropdownRender={(menu) => (
                                <>
                                    {menu}
                                    <Divider
                                        style={{
                                            margin: '8px 0',
                                        }}
                                    />
                                    <Row wrap gutter={[10, 18]}>
                                        <Col span={16}>
                                            <Input
                                                style={{
                                                    width: '100%',
                                                }}
                                                className='add-direction'
                                                placeholder="Nova Organização/Direção"
                                                value={name}
                                                onChange={onNameChange}
                                            />
                                        </Col>
                                        <Col span={8}>
                                            <Button type="primary" icon={<PlusOutlined />} onClick={addInstitution} htmlType="button">
                                                Adicionar
                                            </Button>
                                        </Col>
                                    </Row>
                                </>
                            )}
                            options={managementInstitution
                                .filter((item, index, array) => {
                                    return array.findIndex((existingItem) => existingItem.name === item.name) === index;
                                })
                                .map((item) => ({
                                    label: item.name,
                                    value: item.uid
                                }))
                            }
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <span>Área Operacional</span>
                    <Form.Item name="area_uid"
                        rules={[
                            { required: true, message: 'Campo Requerido.' },
                        ]}>
                        <Select
                            style={{
                                marginTop: 8
                            }}
                            placeholder="Selecione uma opção"
                            dropdownRender={(menu) => (
                                <>
                                    {menu}
                                    <Divider
                                        style={{
                                            margin: '8px 0',
                                        }}
                                    />
                                    <Row wrap gutter={[10, 18]}>
                                        <Col span={16}>
                                            <Input
                                                style={{
                                                    width: '100%',
                                                }}
                                                className='add-area'
                                                placeholder="Nova Área Operacional"
                                                value={name}
                                                onChange={onNameChange}
                                            />
                                        </Col>
                                        <Col span={8}>
                                            <Button type="primary" icon={<PlusOutlined />} onClick={addArea} htmlType="button">
                                                Adicionar
                                            </Button>
                                        </Col>
                                    </Row>
                                </>
                            )}
                            options={areaInstitution
                                .filter((item, index, array) => {
                                    return array.findIndex((existingItem) => existingItem.name === item.name) === index;
                                })
                                .map((item) => ({
                                    label: item.name,
                                    value: item.uid
                                }))
                            }
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <span>Período de Execução</span>
                    <Form.Item name="dates" rules={[
                        { required: true, message: "Campo Requerido" }
                    ]}
                    >
                        <RangePicker />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <span>Projeto / Programa Acrónimo</span>
                    <Form.Item name="project" rules={[
                        { required: true, message: 'Campo Requerido.' },
                    ]}>
                        <TextArea rows={4} />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <span>Visão geral</span>
                    <Form.Item name="overview" >
                        <TextArea rows={4} />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <span>Metas</span>
                    <Form.Item name="milestones" >
                        <TextArea rows={4} />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <span>Entregáveis</span>
                    <Form.Item name="deliverables" >
                        <TextArea rows={4} />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <div className='project-data-modal-body-popover'>
                        <span>Prontidão tecnológica vs Tempo para o mercado</span>
                        <Popover style={{
                            position: "relative",
                            zIndex: 99
                        }} content={content}>
                            <InfoCircleOutlined />
                        </Popover>
                    </div>
                    <Form.Item name="trl_ttm" rules={[
                        { required: true, message: 'Campo Requerido.' },
                    ]}>
                        <Select
                            style={{
                                marginTop: 8,
                            }}
                            allowClear
                            className="project-data-modal-body-select-unique"
                            showSearch
                            placeholder="Selecione uma opção"
                            optionFilterProp="children"
                        >
                            {options}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        </Row >
    );
}

export default ProjectData;