import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';

import {
    Chart as ChartJS,
    LinearScale,
    PointElement,
    Tooltip,
    Legend,
} from 'chart.js';

import { Bubble } from "react-chartjs-2";
import _service from '@netuno/service-client';

import { Col, Row, Typography } from 'antd';

import SessionTitle from "../SessionTitle";
import ProjectImpactGraphicPopover from "./Popover";

import "./index.less";

ChartJS.register(LinearScale, PointElement, Tooltip, Legend);

const INITIAL_POPOVER_POSITION = {
    top: -10000,
    left: -10000,
    opacity: 0
};

let currentPopoverConfig = {
    data: [],
    position: INITIAL_POPOVER_POSITION,
    displayed: false
};

function ProjectImpactGraphic({
    dashboardFilters,
    reloads
}) {
    const [datasets, setDatasets] = useState([]);
    const [popoverConfig, setPopoverConfig] = useState(currentPopoverConfig);

    const colors = ["#E70000", "#F4BC4E", "#FDE60E", "#78A164", "#529985"];
    const chartPlugins = [{
        id: "customCanvasBackgroundImage",
        beforeDraw: (chart) => {
            const canvas = chart.canvas;
            var chartArea = chart.chartArea;
            const ctx = canvas.getContext("2d");

            ctx.fillStyle = "#DFE7E8";

            ctx.fillRect(
                chartArea.left,
                chartArea.top,
                chartArea.right - chartArea.left,
                (chartArea.bottom - chartArea.top) / 2
            );

            ctx.fillStyle = "#F5EDDC";

            ctx.fillRect(
                chartArea.left,
                ((chartArea.bottom - chartArea.top) / 2) + chartArea.top,
                chartArea.right - chartArea.left,
                (chartArea.bottom - chartArea.top) / 2
            );

            return null;
        }
    }];
    const chartOptions = {
        maintainAspectRatio: false,
        devicePixelRatio: 2,
        clip: {
            top: 100,
            right: 100,
            bottom: 0,
            left: 0
        },
        layout: {
            autoPadding: false
        },
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                enabled: false,
            },
        },
        scales: {
            xb: {
                axis: "x",
                ticks: {
                    stepSize: 1,
                },
                border: {
                    display: false,
                },
                max: 9,
                min: 1,
                grid: {
                    display: true,
                    color: "#FFFFFF"
                }
            },
            x: {
                axis: "x",
                afterTickToLabelConversion: (data) => {
                    data.drawLabels = () => { };
                },
                ticks: {
                    count: 4,
                },
                title: {
                    display: true,
                    text: "Prontidão tecnológica vs Tempo para o mercado",
                    color: "#000000"
                },
                max: 9,
                min: 1,
                border: {
                    display: false,
                    dash: [5],
                },
                grid: {
                    drawTicks: false,
                    lineWidth: 2,
                    color: "#999999"
                },
            },
            yLabels: {
                axis: "y",
                ticks: {
                    stepSize: 1,
                },
                border: {
                    display: false,
                },
                max: 5,
                min: 1,
                grid: {
                    display: true,
                    color: "#FFFFFF"
                }
            },
            y: {
                axis: "y",
                afterTickToLabelConversion: (data) => {
                    data.drawLabels = () => { };
                },
                ticks: {
                    count: 3,
                },
                title: {
                    display: true,
                    text: "Medida Agregada de Impacto",
                    color: "#000000"
                },
                max: 5,
                min: 1,
                grid: {
                    drawTicks: false,
                    lineWidth: 2,
                    color: "#999999"
                },
                border: {
                    display: false,
                    dash: [5],
                },
            },
        },
        onHover: (event, elements) => {
            if (event.type === "mousemove") {
                const elementsData = elements.map(({ element, datasetIndex }) => ({
                    ...element.$context.raw.data,
                    x: element.x,
                    y: element.y,
                    r: element.$context.raw.r,
                    color: colors[element.$context.raw.data.rank_risk - 1]
                }));
                const stringfyElemetsData = JSON.stringify(elementsData.map(({ ami, trl_ttm }) => ({ ami, trl_ttm })))
                const stringfyCurrent = JSON.stringify(currentPopoverConfig.data.map(({ ami, trl_ttm }) => ({ ami, trl_ttm })))
                const isEquals = stringfyCurrent === stringfyElemetsData;

                if (!isEquals) {
                    const newConfig = {
                        data: elementsData,
                        position: INITIAL_POPOVER_POSITION,
                        displayed: false,
                        event
                    };

                    currentPopoverConfig = newConfig;
                    setPopoverConfig(newConfig);
                }
            }
        },
    };

    useEffect(() => {
        if (
            currentPopoverConfig.data.length > 0
            && !currentPopoverConfig.displayed
            && currentPopoverConfig.event
        ) {
            const [{ x, r }] = currentPopoverConfig.data;
            const containerOffset = 40;
            const itemDistance = 20;
            let left = x + containerOffset + r + itemDistance;

            const newPopoverPosition = {
                top: `${currentPopoverConfig.event.native.pageY}px`,
                left,
                opacity: 1
            };

            setPopoverConfig((prev) => {
                const newConfig = {
                    ...prev,
                    position: newPopoverPosition,
                    displayed: true
                };

                return newConfig;
            });
        }
    }, [popoverConfig]);

    useEffect(() => {
        const filter = {
            institution_uids: [],
            area_uids: [],
            project_uids: [],
        };

        if (dashboardFilters && dashboardFilters.institution_uids) {
            filter.institution_uids = dashboardFilters.institution_uids.map(({ uid }) => uid);
        }

        if (dashboardFilters && dashboardFilters.area_uids) {
            filter.area_uids = dashboardFilters.area_uids.map(({ uid }) => uid);
        }

        if (dashboardFilters && dashboardFilters.project_uids) {
            filter.project_uids = dashboardFilters.project_uids.map(({ uid }) => uid);
        }

        _service({
            url: "project/graphic/impact",
            method: "POST",
            data: {
                filter
            },
            success: (res) => {
                if (res.json) {
                    const min = 16
                    const growthRate = 4;
                    const maxMultiple = {};
                    res.json.graphic.forEach((point) => {
                        const key = `${point.ami}-${point.trl_ttm}`;

                        if (!maxMultiple[key]) {
                            maxMultiple[key] = point;

                            return;
                        }

                        maxMultiple[key].total += point.total;
                    });

                    const sortedData = res.json.graphic.sort((a, b) => {
                        const aKey = `${a.ami}-${a.trl_ttm}`;
                        const bKey = `${b.ami}-${b.trl_ttm}`;
                        let aTotal = a.total;
                        let bTotal = b.total;

                        if (maxMultiple[aKey]) {
                            aTotal = maxMultiple[aKey].total;
                        }

                        if (maxMultiple[bKey]) {
                            bTotal = maxMultiple[bKey].total;
                        }

                        return bTotal - aTotal;
                    });

                    let graphicData = [{
                        label: sortedData.map(() => ""),
                        data: sortedData.map((data) => {
                            const key = `${data.ami}-${data.trl_ttm}`;
                            const max = 80;

                            let total = data.total;

                            if (maxMultiple[key]) {
                                total = maxMultiple[key].total;
                            }

                            let r = (min + ((total - 1) * growthRate)) / 2;

                            if (r > max) {
                                r = max;
                            }

                            return ({
                                x: data.trl_ttm,
                                y: Math.trunc(data.ami * 10) / 10,
                                r,
                                data
                            });
                        }),
                        pointBackgroundColor: sortedData.map(data => data?.total_points > 1 ? "#0E4F9F" : colors[data.rank_risk - 1]),
                        borderColor: sortedData.map(() => "#FFF")
                    }];

                    setDatasets(graphicData);
                }
            }
        })
    }, [dashboardFilters, reloads]);

    return (
        <Row className="graphic">
            <Col span="24">
                <SessionTitle title="Avaliação dos Projetos" />
            </Col>
            <Col span="24">
                <Row>
                    <Col span="24">
                        <ProjectImpactGraphicPopover
                            open={true}
                            data={popoverConfig.data}
                            position={popoverConfig.position}
                        />
                        <div
                            style={{ padding: 4 }}
                            onMouseLeave={(event) => {
                                event.stopPropagation();

                                const element = event.relatedTarget;

                                if (element.className !== "ant-popover-content") {
                                    const newConfig = {
                                        data: [],
                                        position: INITIAL_POPOVER_POSITION,
                                        displayed: false
                                    }
                                    currentPopoverConfig = newConfig;
                                    setPopoverConfig(newConfig);
                                }
                            }}
                        >
                            <Bubble
                                className="graphic__chart"
                                data={{
                                    datasets
                                }}
                                plugins={chartPlugins}
                                options={chartOptions}
                                style={{
                                    marginBottom: '8px'
                                }}
                            />
                        </div>
                    </Col>
                    <Col span="24">
                        <Row className="legend">
                            <Col span="24" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Typography.Paragraph className="legend__content legend__text">
                                    <canvas
                                        style={{
                                            margin: '5px 8px 0 0'
                                        }}
                                        className="legend__text__circle"></canvas>
                                    Projetos com o mesmo valor de Prontidão tecnológica vs Tempo para o mercado, com a mesma AMI e com os valores estimados do Risco distintos entre si
                                </Typography.Paragraph>
                            </Col>
                            <Col span="24">
                                <Typography.Paragraph className="legend__content legend__content--bold">
                                    Estimativa de Risco
                                </Typography.Paragraph>
                            </Col>
                            <Col span="24">
                                <Row wrap={false} justify="center" align="middle">
                                    <Col span="1"
                                        style={{
                                            marginRight: '0px'
                                        }}
                                        className="legend__index__one">1</Col>
                                    {
                                        colors.map((color, index) => (
                                            <Col
                                                key={`${color}-${index}`}
                                                className="legend__color"
                                                span="4"
                                                style={{
                                                    backgroundColor: color,
                                                }}
                                            ></Col>
                                        ))
                                    }
                                    <Col span="1"
                                        style={{
                                            marginLeft: '0px'
                                        }}
                                        className="legend__index__five">5</Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

const mapStateToProps = store => {
    const { dashboardFilters } = store.dashboardFiltersState;
    return {
        dashboardFilters,
        reloads: store.dashboardReloadState
    };
};

export default connect(mapStateToProps, {})(ProjectImpactGraphic);
