function RiskBox({
    color,
    size
}) {
    return (
        <div
            style={{
                backgroundColor: color,
                height: size,
                width: size,
                borderRadius: 4,
                display: "inline-block",
                marginRight: 4
            }}
        >
        </div>
    );
}

export default RiskBox;