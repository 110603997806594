import React, { useState, useEffect, useRef } from 'react';
import { Button, Modal, Popconfirm, Popover } from "antd";
import "./index.less";
import AddUser from './AddUser';

const User = ({ show, onClose, getUsers }) => {

    const addUserRef = useRef();

    return (
        <Modal
            className="user-area"
            open={show}
            onCancel={onClose}
            maskClosable={false}
            destroyOnClose={true}
            footer={
                <div style={{
                    display: 'flex',
                    justifyContent: "flex-end",
                    alignItems: "center"
                }}>
                    <Button
                        onClick={onClose}
                        style={{
                            width: "150px"
                        }}
                    >
                        Fechar
                    </Button>
                    <Button
                        type='primary'
                        onClick={() => addUserRef.current.onSubmit()}
                        style={{
                            width: "150px"
                        }}
                    >
                        Novo Utilizador
                    </Button>
                </div >
            }
        >
            <div className='user-area-components'>
                <AddUser ref={addUserRef} getUsers={getUsers} />
            </div>
        </Modal>
    );
};

export default User;
