import { Checkbox, DatePicker, Form, Input, InputNumber } from "antd";

import "./index.less";

function ProjectRevaluateModalSessionField({
    field,
    project
}) {
    const requireds = [
        "period",
        "area",
        "institution",
        "project",
        "rank_alignement_attractiveness",
        "rank_impact_long_term_measure",
        "rank_outcomes",
        "rank_output",
        "trl_ttm",
        "rank_risk"
    ];
    const rules = [];

    let value = field.value;
    let label = field.label;
    let key = field.key;
    let valuePropName = "value";

    if (!value) {
        if (typeof project[field.key] === "number") {
            value = (
                <InputNumber />
            );
        }

        if (typeof project[field.key] === "string") {
            value = <Input.TextArea rows={4} />
        }
    }

    if (typeof project[field.key] === "boolean") {
        valuePropName = "checked";
        value = (
            <Checkbox checked={project[field.key]}>{field.value}</Checkbox>
        );
    }

    if (field.key === "period") {
        value = (
            <DatePicker.RangePicker />
        )
    }

    if (field.key === "area" || field.key === "institution") {
        key = `${field.key}_uid`
    }

    if (requireds.includes(field.key)) {
        rules.push({
            required: true,
            message: "Campo Requerido."
        });
    }


    return (
        <Form.Item
            id={key}
            className="project-modal-form-item"
            label={label}
            name={key}
            valuePropName={valuePropName}
            rules={rules}
        >
            {value}
        </Form.Item>
    )
}

export default ProjectRevaluateModalSessionField;