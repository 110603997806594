import { useEffect, useState } from "react";

import { Col, Modal, notification, Row, Spin } from "antd";

import _service from "@netuno/service-client";
import _auth from "@netuno/auth-client";

import ProjectModalMenu from "./Menu";

import { inactiveUserMesage, inactiveUserCode } from "../../../common/consts";

import "./index.less";

function ProjectModal({
    open,
    footer,
    title,
    uid,
    onLoadProject,
    onCancel,
    children,
    menu,
}) {
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (uid) {
            setLoading(true);

            _service({
                url: "project",
                method: "GET",
                data: {
                    uid
                },
                success: (res) => {
                    if (res.json) {
                        const { project } = res.json;
                        onLoadProject(project);
                    }

                    setLoading(false);
                },
                fail: (err) => {
                    setLoading(false);
                    console.error(err);

                    const { json } = err;

                    if (json) {
                        if (json.code === inactiveUserCode) {
                            notification.error({
                                key: inactiveUserCode,
                                message: inactiveUserMesage
                            });
                            _auth.logout();

                            return;
                        }
                    }

                    notification.error({
                        message: "Falha ao carregar informações do projeto."
                    });
                }
            });
        }
    }, [uid]);

    return (
        <Modal
            className="project-modal"
            maskClosable={false}
            title={!loading && title}
            footer={!loading && footer}
            open={open}
            onCancel={onCancel}
            centered
            destroyOnClose
        >
            <Row
                className="project-modal__inner"
                wrap={false}
                align={loading ? "middle" : "top"}
                justify={loading && "center"}
            >
                {
                    loading ? (
                        <Col>
                            <Spin />
                        </Col>
                    ) : (
                        <>
                            <Col>
                                <ProjectModalMenu
                                    getContainer={() => document.getElementById("project-modal__inner__content")}
                                    menu={menu}
                                />
                            </Col>
                            <Col id="project-modal__inner__content" className="project-modal__inner__content">
                                {children}
                            </Col>
                        </>
                    )
                }
            </Row>
        </Modal>
    )
}

export default ProjectModal;
