import { useEffect, useState } from "react";

import { connect } from 'react-redux';

import { Col, Row } from "antd";

import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, } from 'chart.js';

import _service from "@netuno/service-client";

import SessionTitle from "../../SessionTitle";

import "./index.less";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

let currentChartLabelWidth = 0;

function OperationalAreaSplit({
    dashboardFilters,
    reloads
}) {
    const [chartData, setChartData] = useState({
        datasets: [],
        labels: []
    });
    const [max, setMax] = useState(0);
    const [chartLabelWidth, setChartLabelWidth] = useState(0);

    const chartOptions = {
        maintainAspectRatio: false,
        devicePixelRatio: 2,
        indexAxis: "y",
        layout: {
            padding: {
                right: max.toString().length * 20
            }
        },
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                enabled: false
            }
        },
        scales: {
            x: {
                max,
                axis: "x",
                ticks: {
                    callback: () => "",
                },
                border: {
                    display: false,
                    dash: [5]
                },
                grid: {
                    drawTicks: false
                }
            },
            y: {
                axis: "y",
                border: {
                    display: false,
                    dash: [5]
                },
                grid: {
                    drawTicks: false
                },
                afterFit: (axis) => {
                    if (axis.width !== currentChartLabelWidth) {
                        currentChartLabelWidth = axis.width;
                        setChartLabelWidth(currentChartLabelWidth);
                    }
                }
            }
        },
    };
    const chartAxisGraphicOptions = JSON.parse(JSON.stringify(chartOptions));
    chartAxisGraphicOptions.layout.padding.left = currentChartLabelWidth;
    chartAxisGraphicOptions.scales.x.ticks.callback = (value) => {
        if (Math.trunc(value) === value) {
            return value;
        }
    };

    if (chartData.labels.length >= 12) {
        chartAxisGraphicOptions.layout.padding.right += 4;
    }

    const chartPlugins = [
        {
            afterDraw: (chart) => {
                const context = chart.ctx;

                chart.data.datasets.forEach((dataset, i) => {
                    const meta = chart.getDatasetMeta(i);

                    if (!meta.hidden) {
                        meta.data.forEach((bar, index) => {
                            const data = dataset.data[index];
                            const tick = chart.scales.y.ticks[index];

                            if (tick && tick.label) {
                                context.fillStyle = '#333333';
                                context.fillText(data, bar.x + 10, bar.y);
                            }
                        });
                    }
                });
            },
        },
    ];

    useEffect(() => {
        const filter = {
            institution_uids: [],
            area_uids: [],
            project_uids: [],
        };

        if (dashboardFilters && dashboardFilters.institution_uids) {
            filter.institution_uids = dashboardFilters.institution_uids.map(({ uid }) => uid);
        }

        if (dashboardFilters && dashboardFilters.area_uids) {
            filter.area_uids = dashboardFilters.area_uids.map(({ uid }) => uid);
        }

        if (dashboardFilters && dashboardFilters.project_uids) {
            filter.project_uids = dashboardFilters.project_uids.map(({ uid }) => uid);
        }

        _service({
            url: "project/graphic/split_area",
            method: "POST",
            data: {
                filter
            },
            success: (res) => {
                if (res.json) {
                    const colors = [];
                    const rawData = res.json.graphic.filter(({ total_projects }) => total_projects > 0)
                    const data = rawData.map((item) => item.total_projects)
                    const chartLabels = rawData.map((item) => item.area.name);
                    const newMax = data.reduce((acc, current) => {
                        if (current > acc) {
                            return current;
                        }

                        return acc;
                    }, 0);

                    setMax(newMax);

                    for (let i = 0; i < rawData.length; i++) {
                        const randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;

                        const shouldNotAdd = colors.find((color) => color === randomColor)
                            || randomColor.length !== 7;

                        if (shouldNotAdd) {
                            i--;
                        } else {
                            colors.push(randomColor);
                        }
                    }

                    setChartData({
                        datasets: [{
                            borderRadius: 4,
                            data,
                            backgroundColor: colors,
                            maxBarThickness: 30
                        }],
                        labels: chartLabels
                    });
                }
            }
        })
    }, [dashboardFilters, reloads]);

    return (
        <Row className="operational-area-split">
            <Col className="operational-area-split__title__container" span="24">
                <SessionTitle title="Projetos Por Área Operacional" />
            </Col>
            <Col className="operational-area-split__graphic" span="24">
                <div style={{
                    height: chartData.labels.length > 6 ? chartData.labels.length * 55 : 630,
                    minHeight: "100%"
                }}>
                    <Bar
                        className="operational-area-split__graphic__chart"
                        plugins={chartPlugins}
                        data={chartData}
                        options={chartOptions}
                    />
                </div>
            </Col>
            <Col
                span="24"
                style={{
                    height: "27px"
                }}
            >
                <Bar
                    plugins={[]}
                    data={{
                        labels: [],
                        datasets: []
                    }}
                    options={chartAxisGraphicOptions}
                />
            </Col>
        </Row>
    )
}

const mapStateToProps = store => {
    const { dashboardFilters } = store.dashboardFiltersState;
    return {
        dashboardFilters,
        reloads: store.dashboardReloadState
    };
};

export default connect(mapStateToProps, {})(OperationalAreaSplit);
