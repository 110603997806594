import { useEffect, useState } from "react";

import { connect } from 'react-redux';

import { RightOutlined, WarningOutlined } from "@ant-design/icons";
import { Carousel, Col, notification, Row, Typography } from "antd";

import _service from "@netuno/service-client";

import ProjectRevaluateModal from "../Project/RevaluateModal";
import ProjectEditModal from "../Project/EditModal";

import { inactiveUserCode } from "../../common/consts";

import "./index.less";

function Alerts({
    dashboardFilters,
    loggedUserInfo,
    reloads
}) {
    const [uid, setUid] = useState("");
    const [alerts, setAlerts] = useState([]);
    const [alertsPagination, setAlertsPagination] = useState([]);
    const [open, setOpen] = useState(false);

    const pageSize = 3;

    const loadAlerts = () => {
        const filter = {
            institution_uids: [],
            area_uids: [],
            project_uids: [],
        };

        if (dashboardFilters && dashboardFilters.institution_uids) {
            filter.institution_uids = dashboardFilters.institution_uids.map(({ uid }) => uid);
        }

        if (dashboardFilters && dashboardFilters.area_uids) {
            filter.area_uids = dashboardFilters.area_uids.map(({ uid }) => uid);
        }

        if (dashboardFilters && dashboardFilters.project_uids) {
            filter.project_uids = dashboardFilters.project_uids.map(({ uid }) => uid);
        }

        _service({
            url: "project/alert/list",
            method: "POST",
            data: {
                filter
            },
            success: (res) => {
                if (res.json) {
                    const { alerts } = res.json;
                    const alertsPages = [];

                    for (let i = 0; i < alerts.length; i += pageSize) {
                        alertsPages.push(alerts.slice(i, i + pageSize));
                    }

                    setAlertsPagination(alertsPages);
                    setAlerts(alerts);
                }
            },
            fail: (err) => {
                console.error(err);

                const { json } = err;

                if (json) {
                    const { code } = json;

                    if (code === inactiveUserCode) {
                        return;
                    }
                }

                notification.error({
                    message: "Falha ao carregar alertas."
                });
            }
        })
    };

    const onCancel = () => {
        setOpen(false);
        setUid("");
    };

    useEffect(() => {
        loadAlerts();
    }, [dashboardFilters, reloads]);

    return (
        <Row className="alerts">
            <Col span="24" className="alerts__header">
                <Row justify="space-between" align="middle">
                    <Col>
                        <Typography.Title level={3}>
                            Alertas
                        </Typography.Title>
                    </Col>
                    <Col>
                        <Typography.Paragraph className="alerts__header__count">
                            {
                                alerts.length > 1 ?
                                    `${alerts.length} Projetos Requerem Atenção` :
                                    alerts.length === 0 ?
                                        `${alerts.length} Projetos Requerem Atenção` :
                                        `${alerts.length} Projeto Requer Atenção`
                            }
                        </Typography.Paragraph>
                    </Col>
                </Row>
            </Col>
            <Col className="alerts__listing" span="24">
                <Carousel>
                    {
                        alertsPagination.map((page, index) => (
                            <div key={index}>
                                {page.map((alert) => (
                                    <Row
                                        key={alert.uid}
                                        className="alerts__listing__item"
                                        align="middle"
                                        wrap={false}
                                    >
                                        <Col span="2" className="alerts__listing__item__icon">
                                            <WarningOutlined />
                                        </Col>
                                        <Col span="18" className="alerts__listing__item__data__container">
                                            <Row>
                                                <Col span="20">
                                                    <Typography.Title level={5}>
                                                        {alert.project}
                                                    </Typography.Title>
                                                </Col>
                                            </Row>
                                            <Row gutter={12}>
                                                <Col>Risco: {alert.rank_risk}</Col>
                                                <Col>AMI: {Math.trunc(alert.ami * 10) / 10}</Col>
                                            </Row>
                                        </Col>
                                        <Col
                                            span="4"
                                            className="alerts__listing__item__arrow__container"
                                            onClick={() => {
                                                setOpen(true);
                                                setUid(alert.uid);
                                            }}
                                        >
                                            <RightOutlined size={24} />
                                        </Col>
                                    </Row>
                                ))}
                            </div>
                        ))
                    }
                </Carousel>
            </Col>
            {
                loggedUserInfo.admin ? (
                    <ProjectRevaluateModal
                        uid={uid}
                        open={open}
                        onCancel={onCancel}
                        loadAlerts={loadAlerts}
                    />
                ) : (
                    <ProjectEditModal
                        uid={uid}
                        open={open}
                        onCancel={onCancel}
                        loadAlerts={loadAlerts}
                    />
                )
            }
        </Row>
    );
}

const mapStateToProps = store => {
    const { dashboardFilters } = store.dashboardFiltersState;
    const { loggedUserInfo } = store.loggedUserInfoState;

    return {
        dashboardFilters,
        loggedUserInfo,
        reloads: store.dashboardReloadState
    };
};

export default connect(mapStateToProps, {})(Alerts);
