import React, { useRef, useState } from 'react';
import { Button, Modal, Popconfirm, Popover } from "antd";
import { InfoCircleOutlined, WarningOutlined } from '@ant-design/icons'
import SideMenuProject from '../SideProject';
import AddForm from './AddForm';
import "./index.less";
import _service from '@netuno/service-client';

const AddProjectModal = ({ show, onClose }) => {
    const [parentState, setParentState] = useState({
        ami: '',
    });
    const handleGetValuesCalculation = (response) => {
        setParentState({
            ami: response.json.ami,
        });
    }
    const addProjectForm = useRef(null);

    const onFinishFailed = ({ errorFields }) => {
        const fieldError = errorFields[0];
        addProjectForm.current.refForm.scrollToField(fieldError.name, {
            behavior: "smooth",
            block: "center",
            inline: "center",
        });
    };

    const handleSaveAndClose = () => {
        addProjectForm.current.onSubmit()
            .then(() => {
                window.history.replaceState({}, document.title, window.location.pathname);
                onClose();
            })
            .catch((error) => {
                onFinishFailed({ errorFields: error.errorFields });
            });
    };

    const handleCloseModal = () => {
        window.history.replaceState({}, document.title, window.location.pathname);
        onClose();
    };

    const items = [
        {
            key: "side-menu",
            children: <SideMenuProject />,
        },
        {
            key: 'add-project',
            children: <AddForm />
        }
    ];

    return (
        <Modal
            className="modal-project-areas"
            title={
                <React.Fragment>
                    <h1 className="modal-project-areas-title">Criar Projeto</h1>
                    <div className="modal-project-areas-button">
                        <span className="button-tcmgi">
                            AMI {parentState.ami ? parentState.ami.toFixed(1) : 0}
                            <Popover
                                overlayStyle={{
                                    width: "300px"
                                }}  
                                content="AMI - Medida Agregada de Impacto = 0.30 * Alinhamento e Atratividade + 0,20 * Outputs + 0,20 * Efeitos / Outcomes + 0,30 * Impacto Antecipado"
                            >
                                <InfoCircleOutlined />
                            </Popover>
                        </span>
                    </div>
                </React.Fragment>
            }
            maskClosable={false}
            visible={show}
            onCancel={handleCloseModal}
            destroyOnClose={true}
            footer={
                <div style={{
                    display: 'flex',
                    justifyContent: "flex-end",
                    alignItems: "center"
                }}>
                    <Popconfirm
                        title="Tem certeza que deseja cancelar a criação de projeto?"
                        okText="Sim"
                        cancelText="Não"
                        okType="link"
                        onConfirm={handleCloseModal}
                        icon={<WarningOutlined style={{ color: "#E70000" }} />}
                        okButtonProps={{
                            className: "pop-confirm-confirm-btn",
                        }}
                        cancelButtonProps={{
                            className: "pop-confirm-cancel-btn",
                        }}
                    >
                        <Button type='link' style={{
                            color: "#E70000"
                        }}
                        >Cancelar</Button>
                    </Popconfirm>
                    <Button style={{
                        width: 132
                    }} onClick={handleSaveAndClose} type="primary" className="btn-sm add-project-button">
                        Guardar
                    </Button>
                </div >
            }
        >
            <React.Fragment>
                <SideMenuProject getContainer={() => document.querySelector(".area-project-modal")} items={items} />
            </React.Fragment>
            <React.Fragment>
                <AddForm ref={addProjectForm} handleGetValuesCalculation={handleGetValuesCalculation} />
            </React.Fragment>
        </Modal >
    );
};

export default AddProjectModal;
