import { InfoCircleOutlined } from "@ant-design/icons";
import { Row, Col, Typography, Tooltip, Popover } from "antd";

import "./index.less";

function ProjectRevaluateModalTitle({
    project
}) {
    return (
        <Row className="project-modal-title" justify="space-between" align="middle">
            <Col>
                <Typography.Title className="project-modal-title__title" level={2}>
                    {project.project}
                </Typography.Title>
            </Col>
            <Col>
                <Row gutter={8}>
                    {
                        project.manager && (
                            <Col>
                                <Typography.Paragraph className="project-modal-title__info">
                                    Gestor: {project.manager.name}
                                </Typography.Paragraph>
                            </Col>
                        )
                    }
                    <Col>
                        <Typography.Paragraph className="project-modal-title__info">
                            AMI: {Math.trunc(project.ami * 10) / 10}{" "}
                            <Popover
                                overlayStyle={{
                                    width: "300px"
                                }} 
                                content="AMI - Medida Agregada de Impacto = 0.30 * Alinhamento e Atratividade + 0,20 * Outputs + 0,20 * Efeitos / Outcomes + 0,30 * Impacto Antecipado"
                            >
                                <InfoCircleOutlined />
                            </Popover>
                        </Typography.Paragraph>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default ProjectRevaluateModalTitle;