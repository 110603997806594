import { Anchor } from "antd";

import "./index.less";

function ProjectModalMenu({
    getContainer,
    menu
}) {
    const menuLinks = [{
        href: '#project-data',
        title: 'Dados Gerais do Projeto',
        className: 'project-data'
    },
    {
        href: '#alignement-and-attractiveness',
        title: 'Alinhamento e Atratividade',
        className: 'alignement-and-attractiveness'
    },
    {
        href: '#output',
        title: 'Outputs',
        className: 'output'
    },
    {
        href: '#outcomes',
        title: 'Efeitos/Outcomes',
        className: 'outcomes'
    },
    {
        href: '#impact',
        title: 'Impacto Antecipado',
        className: 'impact'
    },
    {
        href: '#agregate-measure-impact',
        title: "Medida Agregada de Impacto (AMI)",
        className: 'agregate-measure-impact'
    },
    {
        href: '#risk',
        title: "Estimativa de Risco",
        className: 'risk'
    }];

    return (
        <Anchor
            rootClassName="menu-sidebar-project"
            items={menu || menuLinks}
            getContainer={getContainer}
            style={{ paddingRight: "40px"}}
        />
    )
}

export default ProjectModalMenu;