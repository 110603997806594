import { Col, Row, Typography } from "antd";

import ProjectRevaluateModalSessionField from "./Field";

import "./index.less";

function ProjectRevaluateModalSession({
    session,
    project
}) {
    return (
        <Row className="project-modal-session" id={session.key}>
            {
                session.title && (
                    <Col span="24">
                        <Typography.Title className="project-modal-session__title">
                            {session.title}
                        </Typography.Title>
                    </Col>
                )
            }
            {
                session.title && (
                    <Col span="24" className="project-modal-session__subtitle">
                        {session.subtitle}
                    </Col>
                )
            }
            {
                session.fields && (
                    session.fields.map((row) => {
                        return row.map((field) => {
                            const value = project[field.key];

                            const shoulRender = !!value
                                || typeof value === "number";

                            if (shoulRender) {
                                return (
                                    <Col key={field.key} span={24 / row.length}>
                                        <ProjectRevaluateModalSessionField project={project} field={field} />
                                    </Col>
                                )
                            }
                        })
                    })
                )
            }
        </Row>
    )
}

export default ProjectRevaluateModalSession;