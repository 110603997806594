import {
  LOGGED_USER_INFO,
  LOGGED_USER_INFO_RELOAD,
  DASHBOARD_FILTERS,
  DASHBOARD_FILTERS_RELOAD,
  DASHBOARD_RELOAD,
  DASHBOARD_ORDENATION
} from './actionTypes';

export const loggedUserInfoAction = (data) => ({
  type: LOGGED_USER_INFO,
  payload: { ...data }
});

export const loggedUserInfoReloadAction = () => ({
  type: LOGGED_USER_INFO_RELOAD,
  payload: { }
});

export const dashboardFiltersAction = (data) => ({
  type: DASHBOARD_FILTERS,
  payload: { ...data }
});

export const dashboardFiltersReloadAction = () => ({
  type: DASHBOARD_FILTERS_RELOAD,
  payload: { }
});

export const dashboardReloadAction = () => ({
  type: DASHBOARD_RELOAD,
  payload: { }
});

export const dashboardOrdenationAction = (data) => ({
  type: DASHBOARD_ORDENATION,
  payload: { ...data }
});