import { Popover, Divider, Row, Col } from "antd";

import "./index.less";
import RiskBox from "./RiskBox";

function ProjectImpactGraphicPopover({
    open,
    data,
    position,
    onAfterOpenChange
}) {
    return (
        <Popover
            open={open}
            arrow={false}
            overlayClassName="graphic-popover"
            overlayStyle={{
                ...position
            }}
            afterOpenChange={onAfterOpenChange}
            title={(
                <Row className="graphic-popover" gutter={4}>
                    {data.map((point, index) => {
                        return (
                            <>
                                <Col>
                                    <Row>
                                        <Col>

                                        </Col>
                                    </Row>
                                    <Row className="graphic-popover__content">
                                        <Col span="24" className="graphic-popover__content__session">
                                            <RiskBox color={point.color} size={14} />
                                            Risco: <strong>{point.rank_risk}</strong>
                                        </Col>
                                        <Col span="24" className="graphic-popover__content__session">
                                            Impacto Agregado: <strong>{Math.trunc(point.ami * 10) / 10}</strong>
                                        </Col>
                                        <Col span="24" className="graphic-popover__content__session">
                                            Prontidão tecnológica vs Tempo para o mercado: <strong>{point.trl_ttm}</strong>
                                        </Col>
                                        <Col span="24" className="graphic-popover__content__session">
                                            <Row>
                                                <Col span="24">
                                                    Projetos: <strong>{point.projects.length}</strong>
                                                </Col>
                                                {point.projects.map((project, index) => (
                                                    <Col span="24" key={`${point.rank_risk},${point.ami},${point.trl_ttm},${index}`}>
                                                        <strong>{project.project}</strong>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                                {index !== data.length - 1 && <Col><Divider className="graphic-popover__content__session__divider" type="vertical" /></Col>}
                            </>
                        );
                    })}
                </Row>
            )}
        />
    );
}

export default ProjectImpactGraphicPopover;