import { useEffect, useState } from "react";

import { notification, Select } from "antd";

import _service from "@netuno/service-client";

function SelectAreaInstitution({
    field,
    form,
    initial
}) {
    const [options, setOptions] = useState([]);
    const [loadingOptions, setLoadingOptions] = useState(false);

    useEffect(() => {
        setLoadingOptions(true);

        _service({
            url: `${field}/list`,
            method: "POST",
            success: (res) => {
                setOptions(
                    res.json[`${field}s`].map(({ uid, name }) => ({
                        label: name,
                        value: uid
                    }))
                );
                setLoadingOptions(false);
            },
            fail: (err) => {
                notification.error("Falha ao carregar opições.");
                setLoadingOptions(false);
            }
        })
    }, []);

    return (
        <Select
            placeholder="Selecione uma opção"
            onChange={(value) => form.setFieldValue(`${field}_uid`, value)}
            options={options}
            loading={loadingOptions}
            defaultValue={initial}
            showSearch
            optionFilterProp="label"
            style={{
            }}
        />
    );
}

export default SelectAreaInstitution;