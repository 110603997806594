import { useState } from "react";

import { Button, Col, Modal, Row, Spin, Tooltip, Typography } from "antd";
import { PrinterOutlined } from "@ant-design/icons";

import { pdf } from "@react-pdf/renderer";

import { connect } from "react-redux";

import { Chart } from "chart.js";

import _service from "@netuno/service-client";

import PrintDasboardDocument from "./Document";

import "./index.less";

function PrintDasboard({
  dashboardFilters,
  dashboardOrdenation
}) {
  const [loadingPrint, setLoadingPrint] = useState(false);

  const getOverview = () => {
    return new Promise((resolve, reject) => {
      const filter = {
        institution_uids: [],
        area_uids: [],
        project_uids: [],
      };

      if (dashboardFilters && dashboardFilters.institution_uids) {
        filter.institution_uids = dashboardFilters.institution_uids.map(({ uid }) => uid);
      }

      if (dashboardFilters && dashboardFilters.area_uids) {
        filter.area_uids = dashboardFilters.area_uids.map(({ uid }) => uid);
      }

      if (dashboardFilters && dashboardFilters.project_uids) {
        filter.project_uids = dashboardFilters.project_uids.map(({ uid }) => uid);
      }

      _service({
        url: "overview",
        method: "POST",
        data: {
          filter
        },
        success: (res) => {
          resolve(res.json);
        },
        fail: reject
      });
    })
  };

  const getProjects = async () => {
    return new Promise((resolve, reject) => {
      const filter = {
        institution_uids: [],
        area_uids: [],
        project_uids: [],
      };
      const ordenation = {};

      if (dashboardFilters && dashboardFilters.institution_uids) {
        filter.institution_uids = dashboardFilters.institution_uids.map(({ uid }) => uid);
      }

      if (dashboardFilters && dashboardFilters.area_uids) {
        filter.area_uids = dashboardFilters.area_uids.map(({ uid }) => uid);
      }

      if (dashboardFilters && dashboardFilters.project_uids) {
        filter.project_uids = dashboardFilters.project_uids.map(({ uid }) => uid);
      }

      if (dashboardOrdenation && dashboardOrdenation.projects) {
        ordenation.by = dashboardOrdenation.projects.by;
        ordenation.direction = dashboardOrdenation.projects.direction;
      }

      _service({
        url: "project/list",
        method: "POST",
        data: {
          filter,
          ordenation
        },
        success: (res) => {
          resolve(res.json.projects);
        },
        fail: reject
      });
    })
  };

  const getAlerts = async () => {
    return new Promise((resolve, reject) => {
      const filter = {
        institution_uids: [],
        area_uids: [],
        project_uids: [],
      };

      if (dashboardFilters && dashboardFilters.institution_uids) {
        filter.institution_uids = dashboardFilters.institution_uids.map(({ uid }) => uid);
      }

      if (dashboardFilters && dashboardFilters.area_uids) {
        filter.area_uids = dashboardFilters.area_uids.map(({ uid }) => uid);
      }

      if (dashboardFilters && dashboardFilters.project_uids) {
        filter.project_uids = dashboardFilters.project_uids.map(({ uid }) => uid);
      }

      _service({
        url: "project/alert/list",
        method: "POST",
        data: {
          filter
        },
        success: (res) => {
          resolve(res.json.alerts);
        },
        fail: reject
      });
    })
  };

  const onPrint = async () => {
    try {
      setLoadingPrint(true);

      const splitAreaGraphicInstance = Chart.instances[Object.keys(Chart.instances)[0]];
      const projectImpactGraphicInstance = Chart.instances[Object.keys(Chart.instances)[2]];
      const splitAreaGraphicData = [...splitAreaGraphicInstance.data.datasets[0].data];
      const splitAreaGraphicLabels = [...splitAreaGraphicInstance.data.labels];
      const splitAreaGraphicLabelsColors = [...splitAreaGraphicInstance.data.datasets[0].backgroundColor];

      const overview = await getOverview();
      const alerts = await getAlerts();
      const projects = await getProjects();

      const docBlob = await pdf((
        <PrintDasboardDocument
          filters={dashboardFilters || ({
            area_uids: [],
            institution_uids: [],
            project_uids: []
          })}
          overviewData={{
            projects: overview.projects,
            areas: overview.areas,
            institutions: overview.institutions,
            alerts: alerts.length
          }}
          projects={projects.map((project) => ({
            ami: project.ami,
            area: project.area.name,
            institution: project.institution.name,
            project: project.project,
            rank_risk: project.rank_risk
          }))}
          maxLabelWidth={splitAreaGraphicInstance.scales.y.width}
        />
      )).toBlob();

      splitAreaGraphicInstance.data.datasets[0].data = splitAreaGraphicData;
      splitAreaGraphicInstance.data.labels = splitAreaGraphicLabels;
      splitAreaGraphicInstance.data.datasets[0].backgroundColor = splitAreaGraphicLabelsColors;
      splitAreaGraphicInstance.options.scales.x.ticks.callback = () => "";

      splitAreaGraphicInstance.update();
      splitAreaGraphicInstance.resize();
      projectImpactGraphicInstance.update();
      projectImpactGraphicInstance.resize();

      const url = window.URL.createObjectURL(docBlob);
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";

      link.click();
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingPrint(false);
    }
  };

  return (
    <>
      <Tooltip
        title="Versão de Impressão"
      >
        <Button
          className="print-button"
          icon={<PrinterOutlined />}
          shape="circle"
          type="primary"
          size="large"
          loading={loadingPrint}
          onClick={() => onPrint()}
        />
      </Tooltip>
      <Modal
        open={loadingPrint}
        footer={false}
        closable={false}
        bodyStyle={{
          padding: "24px 0px"
        }}
        maskStyle={{
          backdropFilter: "blur(2px)"
        }}
        centered
      >
        <Row
          justify="center"
          gutter={[0, 15]}
          style={{
            width: "min-content !important"
          }}
        >
          <Col style={{ marginBottom: "14px" }}>
            <Spin />
          </Col>

          <Col span="24">
            <Typography.Paragraph style={{ textAlign: "center", fontSize: "20px" }}>
              Aguarde enquanto preparamos o seu PDF
            </Typography.Paragraph>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

const mapStateToProps = store => {
  const { dashboardFilters } = store.dashboardFiltersState;
  const { dashboardOrdenation } = store.dashboardOrdenationState;

  return {
    dashboardFilters,
    dashboardOrdenation
  };
};

export default connect(mapStateToProps, {})(PrintDasboard);
