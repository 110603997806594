import { Button, Col, notification, Row, Space, Tooltip, Typography } from "antd";
import { pdf } from '@react-pdf/renderer'

import _service from "@netuno/service-client";

import "./index.less";
import InfoModalPrint from "../InfoModalPrint";
import { PrinterOutlined } from "@ant-design/icons";
import { PrintButton } from "../InfoModalPrint/PrintButton";


function ProjectRevaluateModalFooter({
    onCancel,
    onRevaluate,
    project
}) {

    const handlePrint = async () => {
        const docBlob = await pdf((
            <InfoModalPrint
                project={project}
            />

        )).toBlob();

        const url = window.URL.createObjectURL(docBlob);
        const link = document.createElement("a");
        link.href = url;
        link.target = "_blank";

        link.click();
    }


    return (
        <Row justify="end" align="middle">
            <Col>
                <PrintButton onClick={handlePrint} title={true} icon={false} />
            </Col>
            <Col>
                <Button
                    type="link"
                    onClick={onCancel}
                >
                    Fechar
                </Button>
            </Col>
            <Col>
                <Button
                    type="link"
                    onClick={() => onRevaluate(project)}
                    danger
                >
                    Reavaliar
                </Button>
            </Col>
        </Row>
    )
}

export default ProjectRevaluateModalFooter;