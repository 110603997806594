const inactiveUserMesage = "Não foi possível efetuar o login. Entre em contacto com o administrador do sistema.";
const inactiveUserCode = "inactive-not-found";
const invalidLoginMessage = inactiveUserMesage;
const overview = "Visão Geral";
const dashboardTitle = "Avaliação do Impacto de Projetos de Inovação";
const evaluatedProjects = "Projetos Avaliados";
const splitAreaTitle = "Projetos Por Área Operacional";
const projectImpactTitle = "Avaliação dos Projetos";

export {
  inactiveUserMesage,
  inactiveUserCode,
  invalidLoginMessage,
  overview,
  dashboardTitle,
  evaluatedProjects,
  splitAreaTitle,
  projectImpactTitle
};
