import React, { useState, useEffect } from "react";
import { Select, Row, Col, Form, Input, Popover, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons"

import "./index.less";

import _service from "@netuno/service-client";

const { TextArea } = Input;
const { Option } = Select;

const RiskAssessment = () => {

    const options = [];

    const contentRanking = ["Risco - Muito elevado", "Risco - Alto", "Risco - Moderado", "Risco - Normal", "Risco - Baixo"];

    for (let i = 0; i < contentRanking.length; i++) {
        options.push(
            <Option key={i + 1} value={i + 1}>
                {i + 1} - {contentRanking[i]}
            </Option>
        );
    }

    const text = <span>Antecipar os riscos que podem ser científicos, financeiros ou administrativos ou outros que podem colocar em causa a avaliação de impacto anterior e propor planos de ação para mitigar esses riscos</span>;

    return (
        <>
            <Row className="risk-assessment-modal" justify="flex-start">
                <Col className="risk-assessment-modal-title">
                    <h1>Estimativa de Risco <Tooltip placement="topLeft" title={text}><InfoCircleOutlined style={{ width: 20 }}/></Tooltip></h1>
                    <p></p>
                </Col>
                <Row className="risk-assessment-modal-body" gutter={[0, 24]}>
                    <Col span={24}>
                        <span>Justificação e planos de ação para mitigar os riscos</span>
                        <Form.Item name="risk_justify" >
                            <TextArea rows={4} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <div className="area-project-modal-select-title">
                            <span>Avaliação</span>
                        </div>
                        <Form.Item name="rank_risk" rules={[
                            { required: true, message: "Campo Requerido." },
                        ]}>
                            <Select
                                style={{
                                    marginTop: 8
                                }}
                                allowClear
                                className="impact-you-antecipate-modal-body-content-select-unique"
                                showSearch
                                placeholder="Selecione uma opção"
                                optionFilterProp="children"
                            >
                                {options}
                            </Select>

                        </Form.Item>
                    </Col>
                </Row>
            </Row>
        </>
    )
}

export default RiskAssessment;