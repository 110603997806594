import {
  Document,
  Image as Img,
  Page,
  Text,
  View,
  StyleSheet,
  Font
} from "@react-pdf/renderer";

import { Chart } from "chart.js";

import {
  dashboardTitle,
  evaluatedProjects,
  overview,
  projectImpactTitle,
  splitAreaTitle
} from "../../../../common/consts";

Font.registerHyphenationCallback((word) => [word]);

const styles = StyleSheet.create({
  page: {
    padding: "12px",
    fontFamily: "Helvetica",
  },
  logo: {
    width: "150px"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  col: {
    display: "flex",
    rowGap: "16px"
  },
  headerTitle: {
    fontSize: "16px"
  },
  session: {
    marginBottom: "12mm"
  },
  sessionTitle: {
    borderLeft: "1mm solid #3CBA92",
    padding: "4mm",
    marginBottom: "4mm",
    color: "#2D2D2D",
    fontFamily: "Helvetica-Bold",
  },
  text: {
    fontSize: "14pt"
  },
  overview: {
    backgroundColor: "#3CBA92",
    padding: "8mm",
    borderRadius: "4px"
  },
  overviewSectionText: {
    display: "flex",
    alignSelf: "center"
  },
  textWhite: {
    color: "#FFF"
  },
  textBold: {
    fontFamily: "Helvetica-Bold",
  }
});

const PrintDasboardDocument = ({
  filters,
  overviewData,
  projects,
  maxLabelWidth
}) => {
  const projectsTableColumns = [
    {
      name: "Organização/Direção",
      field: "institution",
    },
    {
      name: "Área Operacional",
      field: "area"
    },
    {
      name: "AMI",
      field: "ami"
    },
    {
      name: "Risco",
      field: "rank_risk"
    },
    {
      name: "Projeto",
      field: "project"
    }
  ]
  const overviewLabels = {
    projects: evaluatedProjects,
    areas: "ÁREA OPERACIONAL",
    institutions: "Organização/Direção",
    alerts: "ALERTAS"
  };
  const filtersLabels = {
    area_uids: "Áreas Operacionais",
    institution_uids: "Instituições",
    project_uids: "Projetos"
  };
  const riskColors = [
    "rgb(231, 0, 0)",
    "rgb(244, 188, 78)",
    "rgb(253, 230, 14)",
    "rgb(120, 161, 100)",
    "rgb(82, 153, 133)"
  ];
  const projectImpactGraphicInstance = Chart.instances[Object.keys(Chart.instances)[2]];
  const splitAreaGraphicInstance = Chart.instances[Object.keys(Chart.instances)[0]];
  const splitAreaGraphicPages = [];
  const splitAreaGraphicLabels = splitAreaGraphicInstance.data.labels;
  const splitAreaGraphicMaxValue = splitAreaGraphicInstance.scales.y.max;
  let splitAreaGraphicPageSize = 20;

  for (let i = 0; i < splitAreaGraphicLabels.length; i += splitAreaGraphicPageSize) {
    if (i > 0) {
      splitAreaGraphicPageSize = 40;
    }

    splitAreaGraphicPages.push({
      labels: splitAreaGraphicLabels.slice(i, i + splitAreaGraphicPageSize),
      data: splitAreaGraphicInstance.data.datasets[0].data.slice(i, i + splitAreaGraphicPageSize),
      colors: splitAreaGraphicInstance.data.datasets[0].backgroundColor.slice(i, i + splitAreaGraphicPageSize)
    });
  }

  const countFilters = Object.keys(filters).reduce((acc, _current, index) => {
    const key = Object.keys(filters)[index];
    const field = filters[key];

    if (field && field.length > 0) {
      return acc + 1;
    }

    return acc;

  }, 0);

  const getProjectTableColumnWidth = (column) => {
    let width;

    if (column.field === "project") {
      width = 30;
    }

    if (column.field === "ami") {
      width = 10;
    }

    if (column.field === "rank_risk") {
      width = 10;
    }

    if (column.field === "area") {
      width = 25;
    }

    if (column.field === "institution") {
      width = 25;
    }

    return width;
  };

  const getSplitAreaGraphicImage = (
    instance,
    meta,
  ) => {
    const barHeight = 24;
    const axisXHeight = 25;
    const { canvas } = instance;

    instance.options.scales.y.afterSetDimensions = function(axes) {
      axes.maxWidth = maxLabelWidth;
    }
    instance.data.labels = meta.labels.map((label) => `${" ".repeat(200)}${label}`);
    instance.data.datasets[0].data = meta.data;
    instance.data.datasets[0].backgroundColor = meta.colors;
    instance.options.animation = false;

    if (meta.showXAxi) {
      instance.options.scales.x.ticks.callback = (value) => {
        if (Math.trunc(value) === value) {
          return value;
        }
      }
    } else {
      instance.options.scales.x.ticks.callback = () => ""
    }

    instance.update();
    instance.resize(600, (meta.labels.length * barHeight) + axisXHeight);

    return canvas.toDataURL();
  };

  const getProjectImpactGraphicImage = async (instance) => {
    const { canvas } = instance;

    instance.resize(600, instance.height);
    return canvas.toDataURL();
  }

  return (
    <Document>
      <Page
        size="A4"
        style={styles.page}
      >
        <Text
          style={{
            ...styles.session,
            width: "100%",
            textAlign: "right",
            fontSize: "12pt",
            color: "#2D2D2D"
          }}
          render={({ pageNumber, totalPages, ...rest }) => {
            return (
              `${pageNumber} / ${totalPages} `
            );
          }}
          fixed
        />

        <View style={{
          ...styles.row,
          ...styles.session,
          alignItems: "center",
        }}>
          <Img
            style={{
              ...styles.logo,
              left: "-4mm"
            }}
            src={window.location.origin + "/images/impacto.png"}
          />
          <Text
            style={{
              ...styles.headerTitle,
              color: "#2D2D2D"
            }}
          >
            {dashboardTitle}
          </Text>
        </View>

        <View
          style={{
            ...styles.session
          }}
        >
          <Text
            style={{
              ...styles.text,
              marginBottom: "4mm",
              fontFamily: "Helvetica-Bold",
              color: "#2D2D2D"
            }}
          >
            Filtros Aplicados ({countFilters})
          </Text>

          {
            Object.keys(filters).map((key) => {
              if (filters[key] && filters[key].length > 0) {
                return (
                  <View>
                    <Text
                      style={{
                        ...styles.text,
                        fontFamily: "Helvetica-Bold",
                        color: "#2D2D2D",
                        marginBottom: "2mm",
                      }}
                    >
                      {filtersLabels[key]}:
                    </Text>
                    <Text
                      style={{
                        fontSize: "12pt",
                        marginBottom: "4mm",
                        color: "rgba(45, 45, 45, 0.8)"
                      }}
                    >
                      {filters[key].map(({ name }) => name).join(", ")}
                    </Text>
                  </View>
                );
              }
            })
          }
        </View>

        <View
          style={{
            backgroundColor: "#3CBA92",
            padding: "4mm",
            borderRadius: "4px",
            ...styles.session
          }}
        >
          <Text
            style={{
              ...styles.textWhite,
              ...styles.textBold,
              marginBottom: "4mm",
              fontSize: "14pt"
            }}
          >
            {overview}
          </Text>
          <View style={styles.row}>
            {
              Object.keys(overviewData).map((key) => (
                <View
                  style={{
                    ...styles.col,
                    width: `${100 / Object.keys(overviewData).length}%`,
                  }}
                >
                  <Text
                    style={{
                      ...styles.textWhite,
                      ...styles.textBold,
                      ...styles.overviewSectionText,
                      fontSize: "22pt"
                    }}
                  >
                    {overviewData[key]}
                  </Text>

                  <Text
                    style={{
                      ...styles.textWhite,
                      ...styles.overviewSectionText,
                      fontSize: "11pt"
                    }}
                  >
                    {overviewLabels[key].toUpperCase()}
                  </Text>
                </View>
              ))
            }
          </View>
        </View>

        <View
          style={{
            ...styles.session
          }}
        >
          {
            splitAreaGraphicPages.map((page, pageIndex) => {
              return (
                <View
                  wrap={false}
                >
                  {
                    page.labels.map((label, index) => {
                      return (
                        <View
                          style={{
                            margin: "0 auto"
                          }}
                          wrap={false}
                        >
                          {
                            pageIndex === 0 && index === 0 && (
                              <Text
                                style={{
                                  ...styles.sessionTitle
                                }}
                              >
                                {splitAreaTitle}
                              </Text>
                            )
                          }
                          <Img
                            style={{
                              marginBottom: !(index === page.labels.length - 1) ? "-10mm" : "0mm"
                            }}
                            src={async () => {
                              const instance = splitAreaGraphicInstance;

                              return getSplitAreaGraphicImage(
                                instance,
                                {
                                  labels: [label],
                                  data: [page.data[index]],
                                  colors: [page.colors[index]],
                                  showXAxi: index === page.labels.length - 1
                                }
                              );
                            }}
                          />
                        </View>
                      )
                    })
                  }
                </View>
              );
            })
          }
        </View>

        <View
          style={{
            ...styles.session
          }}
          wrap={false}
        >
          <Text
            style={{
              ...styles.sessionTitle
            }}
          >
            {projectImpactTitle}
          </Text>
          <Img src={async () => {
            return getProjectImpactGraphicImage(projectImpactGraphicInstance);
          }} />
          <View
            style={{
              padding: "0mm 4mm 0mm 18mm"
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "4mm"
              }}
            >
              <View
                style={{
                  backgroundColor: "#0e4f9f",
                  width: "4mm",
                  height: "4mm",
                  borderRadius: "100mm",
                  marginRight: "4mm"
                }}
              />

              <Text
                style={{
                  textAlign: "center",
                  fontSize: "11pt",
                  paddingRight: "2mm",
                  flex: 1,
                }}
              >
                Projetos com o mesmo valor de Prontidão tecnológica vs Tempo para o mercado, com a mesma AMI e com os valores estimados do Risco distintos entre si
              </Text>
            </View>

            <Text
              style={{
                textAlign: "center",
                color: "#212121",
                fontSize: "11pt",
                fontFamily: "Helvetica-Bold",
                marginBottom: "4mm"
              }}
            >
              Estimativa de Risco
            </Text>

            <View
              style={{
                display: "flex",
                flexDirection: "row"
              }}
            >
              <Text
                style={{
                  fontSize: "11pt",
                  width: `${100 / 6}%`,
                  textAlign: "center"
                }}
              >
                1
              </Text>

              {
                riskColors.map((riskColor) => {
                  return (
                    <View
                      style={{
                        backgroundColor: riskColor,
                        height: "4mm",
                        width: `${100 / 5}%`
                      }}
                    />
                  );
                })
              }

              <Text
                style={{
                  fontSize: "11pt",
                  width: `${100 / 6}%`,
                  textAlign: "center"
                }}
              >
                5
              </Text>
            </View>
          </View>
        </View>

        <View
          style={{
            ...styles.session,
          }}
          break
        >
          <Text
            style={{
              ...styles.sessionTitle
            }}
          >
            Projetos
          </Text>

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "4mm 0",
              backgroundColor: "#fafafa",
              borderBottom: "0.5mm solid #f0f0f0"
            }}
            wrap={false}
            fixed
          >
            {
              projectsTableColumns.map((projectsTableColumn) => {
                return (
                  <View
                    style={{
                      width: `${getProjectTableColumnWidth(projectsTableColumn)}%`,
                      flexGrow: 1,
                      padding: "2mm",
                    }}
                  >
                    {
                      projectsTableColumn.name.toString().split(" ").map((text) => {
                        return (
                          <Text
                            style={{
                              ...styles.textBold,
                              fontSize: "11pt",
                              margin: "auto 0",
                              color: "#2D2D2D",
                            }}
                          >
                            {text}
                          </Text>
                        );
                      })
                    }

                  </View>
                );
              })
            }
          </View>

          {
            projects.map((project) => {
              return (
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "4mm 0",
                    borderBottom: "0.5mm solid #f0f0f0"
                  }}
                  wrap={false}
                >
                  {
                    projectsTableColumns.map((projectsTableColumn) => {
                      const columnText = [project[projectsTableColumn.field].toString()];

                      return (
                        <View
                          style={{
                            width: `${getProjectTableColumnWidth(projectsTableColumn)}%`,
                          }}
                        >
                          {
                            columnText.map((text) => {
                              return (

                                <Text
                                  style={{
                                    fontSize: "11pt",
                                    margin: "auto 0",
                                    color: "#2D2D2D",
                                    padding: "0mm 2mm",
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  {text}
                                </Text>
                              )
                            })
                          }
                        </View>
                      );
                    })
                  }
                </View>
              );
            })
          }
        </View>
      </Page>
    </Document>
  );
}

export default PrintDasboardDocument;
