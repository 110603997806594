import { combineReducers } from 'redux';

import { loggedUserInfoReducer } from './loggedUserInfo';
import { dashboardFiltersReducer } from './dashboardFilters';
import { dashboardReloadReducer } from "./dashboardReload";
import { dashboardOrdenationReducer } from './dashboardOrdenation';

export const Reducers = combineReducers({
  loggedUserInfoState: loggedUserInfoReducer,
  dashboardFiltersState: dashboardFiltersReducer,
  dashboardReloadState: dashboardReloadReducer,
  dashboardOrdenationState: dashboardOrdenationReducer
});
