import React, { useState, useEffect } from 'react';
import { Navigate, Link } from "react-router-dom";
import { Layout, Typography, Form, Input, Button, Checkbox, notification } from 'antd';
import _auth from '@netuno/auth-client';
import RecoverModal from './RecoverModal';
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { invalidLoginMessage } from '../../common/consts';

import './index.less';
import TermsAndConditions from '../../components/TermsAndConditions';

export default function Login(props) {

  const [submitting, setSubmitting] = useState(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (_auth.isLogged()) {
      window.scrollTo(0, 0);
    }
    window.scrollTo(0, 0);
  });

  function onFinish(values) {
    setSubmitting(true);
    const { username, password } = values;
    _auth.login({
      username,
      password,
      success: () => {
        setSubmitting(false);
      },
      fail: () => {
        setSubmitting(false);
        notification["error"]({
          message: invalidLoginMessage,
        });
      }
    });
  }

  function onFinishFailed(errorInfo) {
    console.log('Failed:', errorInfo);
  }

  let initialValues = { remember: true };
  if (localStorage.getItem("login") != null) {
    initialValues = JSON.parse(localStorage.getItem("login"));
  }

  if (_auth.isLogged()) {
    return <Navigate to="/dashboard" />;
  }
  else {
    return (
      <div className="auth-login">
        <div className='auth-login-content'>
          <img src="/images/logo-impact-white.png" />
          {
            visible ? (
              <RecoverModal
                show={visible}
                onClose={() => setVisible(false)}
              />
            ) : (
              <div className='auth-login-content-box'>
                <Form
                  layout="vertical"
                  name="basic"
                  initialValues={initialValues}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                >

                  <Form.Item
                    name="username"
                    rules={[
                      { required: true, message: 'Insira o seu usuário.' }
                    ]}
                  >
                    <Input
                      bordered={false}
                      prefix={<UserOutlined style={{ fontSize: '16px', color: 'rgba(45, 45, 45, 0.8)' }} />}
                      placeholder='Utilizador'
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[{ required: true, message: 'Insira a palavra-passe.' }]}
                  >
                    <Input.Password
                      bordered={false}
                      prefix={<LockOutlined style={{ fontSize: '16px', color: 'rgba(45, 45, 45, 0.8)' }} />}
                      placeholder='Palavra-passe'
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button loading={submitting} type="primary" className={"btn-lg"} htmlType="submit">
                      Iniciar Sessão
                    </Button>
                  </Form.Item>
                  <span>Caso seja o seu primeiro acesso ou esqueceu a palavra-passe, <Link className="recovery-password" onClick={() => setVisible(true)}>clique aqui</Link>.</span>
                  <br />
                  <TermsAndConditions />
                </Form>
              </div>
            )
          }
        </div>
      </div >
    );
  }
}
