import { DASHBOARD_ORDENATION }from "../actions/actionTypes";

const initialState = {
    dashboardOrdenation: null
};

export const dashboardOrdenationReducer = (state = initialState, action) => {
    switch (action.type) {
        case DASHBOARD_ORDENATION:
            return {
                ...state,
                dashboardOrdenation: action.payload
            };
        default:
            return state;
    }
};
