import { Col, Row, Typography } from "antd";

import ProjectEditModalSessionField from "./Field";

import "./index.less";

function ProjectEditModalSession({
    session,
    project
}) {
    const calcSpan = (cols) => {
        if (cols.length > 1) {
            return (24 / cols.length) - 1;
        }

        return 24;
    };

    return (
        <Row className="project-modal-session" id={session.key} justify="space-between">
            {
                session.title && (
                    <Col span="24">
                        <Typography.Title className="project-modal-session__title">
                            {session.title}
                        </Typography.Title>
                    </Col>
                )
            }
            {
                session.title && (
                    <Col span="24" className="project-modal-session__subtitle">
                        {session.subtitle}
                    </Col>
                )
            }
            <Col span="24">
                {
                    session.fields && (
                        session.fields.map((row) => (
                            <Row justify="space-between" align="bottom">
                                {
                                    row.map((col) => (
                                        <Col key={col.key} span={calcSpan(row)}>
                                            <ProjectEditModalSessionField project={project} field={col} />
                                        </Col>
                                    ))
                                }
                            </Row>
                        ))
                    )
                }
            </Col>
        </Row>
    )
}

export default ProjectEditModalSession;