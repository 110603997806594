import React from "react";
import { Page, Text, View, Document, StyleSheet, Image } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    fontSize: 16,
    padding: 24,
    fontFamily: "Helvetica",
  },
  section: {
    marginBottom: 8,
    flexDirection: "column",
    gap: '8',
  },
  heading: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 8,
    textAlign: "center",
    fontFamily: "Helvetica-Bold",
  },
  text: {
    fontSize: 10,
    marginBottom: 3,
    fontWeight: 400,
  },
  wrap: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    textAlign: "left",
    marginBottom: "16",
    gap: 8,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20,
    borderBottom: "1 solid #D3D3D3",
    paddingBottom: 10,
    width: "100%",
    gap: 24
  },
  logo: {
    width: 120,
    height: 120,
    objectFit: "contain"
  },
  infoContainer: {
    display: "flex",
    flexDirection: "row",
    border: "1 solid #D3D3D3",
    padding: 6,
    flex: "1",
    gap: "8"
  },
  infoText: {
    fontSize: 10,
    marginBottom: 5,
    fontFamily: "Helvetica-Bold",
  },
  infoTextBold: {
    fontSize: 12,
    marginBottom: 8,
    fontWeight: "900",
    fontFamily: "Helvetica-Bold",
  },
  infoBoldHeader: {
    fontSize: 10,
  },
  footer: {
    textAlign: "right",
    padding: 8,
    fontSize: 10,
  },
  grid: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    marginBottom: "8",
    alignItems: "flex-start",
    gap: 8
  }
});

const TextArea = ({
  title,
  text,
  lineLength,
  minLineCountToBreak
}) => {
  const upperCaseCharacteresWeight = {
    default: 0.4,
    "W": 0.6
  };
  const lines = [];

  let textPosition = 0;

  for (let i = 0; i < minLineCountToBreak; i++) {
    let newLine = text.slice(textPosition, textPosition + lineLength);
    const countUpperCase = (newLine.match(/[A-Z]/g) || []).reduce((acc, curr) => {
      if (upperCaseCharacteresWeight[curr]) {
        return acc + upperCaseCharacteresWeight[curr];
      }

      return upperCaseCharacteresWeight.default;
    }, 0);

    newLine = text.slice(textPosition, textPosition + Math.trunc((lineLength - (countUpperCase * 0.6))));

    const nextBreakLine = newLine.indexOf("\n");

    if (nextBreakLine >= 0) {
      newLine = newLine.slice(0, nextBreakLine + 1);
    }

    if (nextBreakLine < 0) {
      const finishWith = newLine.slice(-1);
      const nextCharacter = text[textPosition + newLine.length] || "";

      if (finishWith.match(/\w/)) {
        if (nextCharacter.match(/\w/)) {
          const lastSpacePosition = newLine.lastIndexOf(" ");

          newLine = newLine.slice(0, lastSpacePosition + 1);
        }

        if (nextCharacter === " ") {
          newLine += nextCharacter;
        }
      }
    }

    lines.push([textPosition, textPosition + newLine.length]);

    textPosition += newLine.length;
  }

  const lastUnbreakableLineEndPosition = lines[lines.length - 1][1];

  return (
    <View
      style={{
        marginBottom: "6mm"
      }}
    >
      <View wrap={false}>
        <Text
          style={{
            fontSize: 12,
            marginBottom: "6mm",
            fontWeight: "900",
            fontFamily: "Helvetica-Bold",
          }}
        >
          {title}
        </Text>
        {
          lines.map(([start, end]) => {
            return (
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: 400,
                }}
              >
                {text.slice(start, end)}
              </Text>
            );
          })
        }
      </View>
      <View>
        <Text
          style={{
            fontSize: 10,
            fontWeight: 400,
          }}
        >
          {text.slice(lastUnbreakableLineEndPosition).trimStart()}
        </Text>
      </View>
    </View>
  );
};


const InfoModalPrint = ({ project }) => {
  const maxLineLength = 118;
  const minLineCountToBreak = 10;
  const textFallback = "Informação não fornecida";
  const sessions = [
    {
      key: "general",
      title: "Dados Gerais Do Projeto",
      fields: [
        {
          children: (
            <TextArea
              title="Periodo da Execução"
              lineLength={maxLineLength}
              text={`${project.consultancy_start} - ${project.consultancy_end}`}
              minLineCountToBreak={minLineCountToBreak}
            />
          )
        },
        {
          children: (
            <TextArea
              title="Visão geral"
              lineLength={maxLineLength}
              text={project.overview || textFallback}
              minLineCountToBreak={minLineCountToBreak}
            />
          )
        },
        {
          children: (
            <TextArea
              title="Metas"
              lineLength={maxLineLength}
              text={project.milestones || textFallback}
              minLineCountToBreak={minLineCountToBreak}
            />
          )
        },
        {
          children: (
            <TextArea
              title="Entregáveis"
              lineLength={maxLineLength}
              text={project.deliverables || textFallback}
              minLineCountToBreak={minLineCountToBreak}
            />
          )
        }
      ]
    },
    {
      key: "alignement",
      title: "Alinhamento e Atratividade",
      fields: [
        {
          children: (
            <TextArea
              title="Alinhamento com os objetivos estratégicos da Organização"
              lineLength={maxLineLength}
              text={project.justify_strategic_alignement || textFallback}
              minLineCountToBreak={minLineCountToBreak}
            />
          )
        },
        {
          children: (
            <TextArea
              title="Grau de inovação, originalidade e efetividade do Plano Proposto e Realizado no Projeto"
              lineLength={maxLineLength}
              text={project.justify_uniquiness_program_plan || textFallback}
              minLineCountToBreak={minLineCountToBreak}
            />
          )
        },
        {
          children: (
            <TextArea
              title="Relevância económica, societal e ambiental para a organização/ setor / região / país"
              lineLength={maxLineLength}
              text={project.justify_economical_environmental_relevance_business || textFallback}
              minLineCountToBreak={minLineCountToBreak}
            />
          )
        },
        {
          children: (
            <TextArea
              title="Relevância estratégica dos parceiros da rede na cocriação e valorização do conhecimento"
              lineLength={maxLineLength}
              text={project.justify_competence_network_partners || textFallback}
              minLineCountToBreak={minLineCountToBreak}
            />
          )
        },
        {
          children: (
            <TextArea
              title="Avaliação"
              lineLength={maxLineLength}
              text={project.rank_alignement_attractiveness.toString()}
              minLineCountToBreak={minLineCountToBreak}
            />
          )
        },
      ]
    },
    {
      key: "output",
      title: "Outputs",
      fields: [
        {
          children: (
            <TextArea
              title="Nº de publicações científicas e não científicas"
              lineLength={maxLineLength}
              text={project.number_publications.toString()}
              minLineCountToBreak={minLineCountToBreak}
            />
          )
        },
        {
          children: (
            <TextArea
              title="Descrição"
              lineLength={maxLineLength}
              text={project.justify_number_publications || textFallback}
              minLineCountToBreak={minLineCountToBreak}
            />
          )
        },
        {
          children: (
            <TextArea
              title="Nº de iniciativas de divulgação, Promoção e Prémios"
              lineLength={maxLineLength}
              text={project.number_outreaches.toString()}
              minLineCountToBreak={minLineCountToBreak}
            />
          )
        },
        {
          children: (
            <TextArea
              title="Descrição"
              lineLength={maxLineLength}
              text={project.justify_number_outreach || textFallback}
              minLineCountToBreak={minLineCountToBreak}
            />
          )
        },
        {
          children: (
            <TextArea
              title="Nº de patentes"
              lineLength={maxLineLength}
              text={project.number_patents.toString()}
              minLineCountToBreak={minLineCountToBreak}
            />
          )
        },
        {
          children: (
            <TextArea
              title="Descrição"
              lineLength={maxLineLength}
              text={project.justify_number_patents || textFallback}
              minLineCountToBreak={minLineCountToBreak}
            />
          )
        },
        {
          children: (
            <TextArea
              title="Nº de normas, relatórios e pareces técnicos"
              lineLength={maxLineLength}
              text={project.number_norms_reports_techinical_assessments.toString()}
              minLineCountToBreak={minLineCountToBreak}
            />
          )
        },
        {
          children: (
            <TextArea
              title="Descrição"
              lineLength={maxLineLength}
              text={project.justify_number_norms_reports_techinical_assessments || textFallback}
              minLineCountToBreak={minLineCountToBreak}
            />
          )
        },
        {
          children: (
            <TextArea
              title="Nº de provas de conceito e projetos de ignição, modelos de viabilidade técnico-económica e protótipos"
              lineLength={maxLineLength}
              text={project.number_proof_concepts.toString()}
              minLineCountToBreak={minLineCountToBreak}
            />
          )
        },
        {
          children: (
            <TextArea
              title="Descrição"
              lineLength={maxLineLength}
              text={project.justify_number_proof_concepts || textFallback}
              minLineCountToBreak={minLineCountToBreak}
            />
          )
        },
        {
          children: (
            <TextArea
              title="Nº de introdução de novas tecnologias e inovações em produtos e processos mais sustentáveis"
              lineLength={maxLineLength}
              text={project.number_new_potential_business_proposals.toString()}
              minLineCountToBreak={minLineCountToBreak}
            />
          )
        },
        {
          children: (
            <TextArea
              title="Descrição"
              lineLength={maxLineLength}
              text={project.justify_number_new_potential_business_proposals || textFallback}
              minLineCountToBreak={minLineCountToBreak}
            />
          )
        },
        {
          children: (
            <TextArea
              title="Nº de novos potenciais negócios, licenças a terceiros, joint ventures"
              lineLength={maxLineLength}
              text={project.number_new_strategic_partnerships.toString()}
              minLineCountToBreak={minLineCountToBreak}
            />
          )
        },
        {
          children: (
            <TextArea
              title="Descrição"
              lineLength={maxLineLength}
              text={project.justify_number_new_strategic_partnerships || textFallback}
              minLineCountToBreak={minLineCountToBreak}
            />
          )
        },
        {
          children: (
            <TextArea
              title="Nº de novas parcerias estratégicas"
              lineLength={maxLineLength}
              text={project.number_new_technologies.toString()}
              minLineCountToBreak={minLineCountToBreak}
            />
          )
        },
        {
          children: (
            <TextArea
              title="Descrição"
              lineLength={maxLineLength}
              text={project.justify_number_new_technologies || textFallback}
              minLineCountToBreak={minLineCountToBreak}
            />
          )
        },
        {
          children: (
            <TextArea
              title="Avaliação"
              lineLength={maxLineLength}
              text={project.rank_output.toString()}
              minLineCountToBreak={minLineCountToBreak}
            />
          )
        },
      ],
    },
    {
      key: "outcomes",
      title: "Efeitos/Outcomes",
      fields: [
        {
          children: (
            <TextArea
              title="Aumentar o reconhecimento público"
              lineLength={maxLineLength}
              text={project.outcome_increase_recognition ? "Sim" : "Não"}
              minLineCountToBreak={minLineCountToBreak}
            />
          )
        },
        {
          children: (
            <TextArea
              title="Aumentar o reconhecimento público"
              lineLength={maxLineLength}
              text={project.outcome_increase_recognition ? "Sim" : "Não"}
              minLineCountToBreak={minLineCountToBreak}
            />
          )
        },
        {
          children: (
            <TextArea
              title="Consciencialização pública através de uma maior literacia do conhecimento e participação da sociedade civil"
              lineLength={maxLineLength}
              text={project.outcome_knowledge_enlargement ? "Sim" : "Não"}
              minLineCountToBreak={minLineCountToBreak}
            />
          )
        },
        {
          children: (
            <TextArea
              title="Aumentar o conhecimento em cocriação e contributo das diferentes hélices de inovação"
              lineLength={maxLineLength}
              text={project.outcome_public_awareness ? "Sim" : "Não"}
              minLineCountToBreak={minLineCountToBreak}
            />
          )
        },
        {
          children: (
            <TextArea
              title="Justificação"
              lineLength={maxLineLength}
              text={project.outcomes_justify || "Informação não fornecida"}
              minLineCountToBreak={minLineCountToBreak}
            />
          )
        },
        {
          children: (
            <TextArea
              title="Avaliação"
              lineLength={maxLineLength}
              text={project.rank_outcomes.toString()}
              minLineCountToBreak={minLineCountToBreak}
            />
          )
        }
      ]
    },
    {
      key: "impact",
      title: "Impacto Antecipado",
      fields: [
        {
          children: (
            <TextArea
              title="Impacto Antecipado"
              lineLength={maxLineLength}
              text={project.description_impact_long_term_measure || "Informação não fornecida"}
              minLineCountToBreak={minLineCountToBreak}
            />
          )
        },
        {
          children: (
            <TextArea
              title="Impacto no EBITDA anual a médio/longo prazo"
              lineLength={maxLineLength}
              text={project.rank_impact_long_term_measure.toString()}
              minLineCountToBreak={minLineCountToBreak}
            />
          )
        }
      ]
    },
    {
      key: "risk",
      title: "Estimativa de Risco",
      fields: [
        {
          children: (
            <TextArea
              title="Justificação"
              lineLength={maxLineLength}
              text={project.risk_justify || textFallback}
              minLineCountToBreak={minLineCountToBreak}
            />
          )
        }
      ]
    }
  ];

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Image style={styles.logo} src="images/logo.png" />
          <View style={styles.infoContainer} wrap={true}>
            <View style={styles.grid}>
              <View style={{
                display: "flex",
                flexDirection: "row",
                gap: 4
              }}>
                <Text style={styles.infoText}>Projeto / Programa Acrónimo:</Text>
                <Text style={styles.infoBoldHeader}>{project.project}</Text>
              </View>
              <View style={{
                display: "flex",
                flexDirection: "row",
                gap: 4
              }}>
                <Text style={styles.infoText}>Gestor:</Text>
                <Text style={styles.infoBoldHeader}>{project?.manager?.name}</Text>
                <Text style={styles.infoText}>AMI:</Text>
                <Text style={styles.infoBoldHeader}>{project.ami}</Text>
              </View>
              <View style={{
                display: "flex",
                flexDirection: "row",
                gap: 4
              }}>
                <Text style={styles.infoText}>Estimativa de Risco:</Text>
                <Text style={styles.infoBoldHeader}>{project.rank_risk}</Text>
                <Text style={styles.infoText}>Prontidão tecnológica vs Tempo para o mercado:</Text>
                <Text style={styles.infoBoldHeader}>{project.trl_ttm}</Text>
              </View>

            </View>
          </View>
        </View>
        <Text
          style={{
            ...styles.session,
            width: "100%",
            textAlign: "right",
            fontSize: "12pt",
            color: "#2D2D2D",
            marginBottom: "12mm"
          }}
          render={({ pageNumber, totalPages, ...rest }) => {
            return (
              `${pageNumber} / ${totalPages} `
            );
          }}
          fixed
        />
        {
          sessions.map((session) => {
            const notBreak = ["general"];

            return (
              <View
                style={[styles.section]}
                key={session.key}
                break={!notBreak.includes(session.key)}
              >
                <Text style={styles.heading}>{session.title}</Text>
                {
                  session.fields.map((field) => {
                    return field.children
                  })
                }
              </View>
            );
          })
        }
      </Page>
    </Document>
  )
}

export default InfoModalPrint;
