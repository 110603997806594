import { Typography } from "antd";
import "./index.less";

function SessionTitle({
    title
}) {
    return (
        <Typography.Title className="session-title" level={3}>
            {title}
        </Typography.Title>
    );
}

export default SessionTitle;