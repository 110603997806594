import { useEffect, useState } from "react";

import { connect } from 'react-redux';

import { Col, notification, Row, Spin, Typography } from "antd";

import _service from "@netuno/service-client";

import { inactiveUserCode } from "../../common/consts";

import "./index.less";

function Overview({
  dashboardFilters,
  reloads
}) {
  const [datas, setDatas] = useState({
    projects: 0,
    institutions: 0,
    areas: 0
  });
  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {
    setLoadingData(true);

    const filter = {
      institution_uids: [],
      area_uids: [],
      project_uids: [],
    };

    if (dashboardFilters && dashboardFilters.institution_uids) {
      filter.institution_uids = dashboardFilters.institution_uids.map(({ uid }) => uid);
    }

    if (dashboardFilters && dashboardFilters.area_uids) {
      filter.area_uids = dashboardFilters.area_uids.map(({ uid }) => uid);
    }

    if (dashboardFilters && dashboardFilters.project_uids) {
      filter.project_uids = dashboardFilters.project_uids.map(({ uid }) => uid);
    }

    _service({
      url: "overview",
      method: "POST",
      data: {
        filter
      },
      success: (res) => {
        if (res.json) {
          setDatas(res.json);
        }
        setLoadingData(false);
      },
      fail: (err) => {
        setLoadingData(false);
        console.error("Overview", err);

        const { json } = err;

        if (json) {
          const { code } = json;

          if (code === inactiveUserCode) {
            return;
          }
        }

        notification.error({
          message: "Falha ao carregar overview"
        });
      }
    })
  }, [dashboardFilters, reloads]);

  return (
    <Row className="overview">
      <Col span="24">
        <Typography.Title className="overview__title" level={3}>
          Visão Geral
        </Typography.Title>
      </Col>
      {
        false ? (
          <Col span="24" className="overview__spin">
            <Spin />
          </Col>
        ) : (
          <>
            <Col span="8" className="overview__section">
              <Typography.Title level={1}>
                {loadingData ? <Spin /> : datas.projects}
              </Typography.Title>
              <Typography.Paragraph>
                PROJETOS AVALIADOS
              </Typography.Paragraph>
            </Col>
            <Col span="8" className="overview__section">
              <Typography.Title level={1}>
                {loadingData ? <Spin /> : datas.institutions}
              </Typography.Title>
              <Typography.Paragraph>
                ORGANIZAÇÃO/DIREÇÃO
              </Typography.Paragraph>
            </Col>
            <Col span="8" className="overview__section">
              <Typography.Title level={1}>
                {loadingData ? <Spin /> : datas.areas}
              </Typography.Title>
              <Typography.Paragraph>
                ÁREA OPERACIONAL
              </Typography.Paragraph>
            </Col>
          </>
        )
      }
    </Row>
  );
}

const mapStateToProps = store => {
  const { dashboardFilters } = store.dashboardFiltersState;
  return {
    dashboardFilters,
    reloads: store.dashboardReloadState
  };
};

export default connect(mapStateToProps, {})(Overview);
