import { Select } from "antd";

function SelectRank({
  name,
  initial,
  option,
  form
}) {
  const options = {
    risk: [
      {
        label: "1 - Risco - Muito elevado",
        value: 1
      },
      {
        label: "2 - Risco - Alto",
        value: 2
      },
      {
        label: "3 - Risco - Moderado",
        value: 3
      },
      {
        label: "4 - Risco - Normal",
        value: 4
      },
      {
        label: "5 - Risco - Baixo",
        value: 5
      }
    ],
    output: [
      {
        label: "1 - Baixo",
        value: 1
      },
      {
        label: "2 - Suficiente",
        value: 2
      },
      {
        label: "3 - Bom",
        value: 3
      },
      {
        label: "4 - Muito Bom",
        value: 4
      },
      {
        label: "5 - Excede",
        value: 5
      }
    ],
    outcomes: [
      {
        label: "Nível de efeitos - Baixos",
        value: 1
      },
      {
        label: "Nível de efeitos - Suficientes",
        value: 2
      },
      {
        label: "Nível de efeitos - Bom",
        value: 3
      },
      {
        label: "Nível de efeitos - Muito Bom",
        value: 4
      },
      {
        label: "Nível de efeitos - Forte",
        value: 5
      }
    ],
    alignement: [
      {
        label: "1 - Alinhamento e Atratividade - Baixo",
        value: 1
      },
      {
        label: "2 - Alinhamento e Atratividade - Suficiente",
        value: 2
      },
      {
        label: "3 - Alinhamento e Atratividade - Bom",
        value: 3
      },
      {
        label: "4 - Alinhamento e Atratividade - Muito Bom",
        value: 4
      },
      {
        label: "5 - Alinhamento e Atratividade - Forte",
        value: 5
      }
    ],
    ebitda: [
      {
        label: "1 - [ < 0.5 ]",
        value: 1
      },
      {
        label: "2 - [0.5 - 1.0]",
        value: 2
      },
      {
        label: "3 - [1.0 - 2.0]",
        value: 3
      },
      {
        label: "4 - [2.0 - 3.0]",
        value: 4
      },
      {
        label: "5 - [ > 3.0 ]",
        value: 5
      }
    ],
    trl_ttm: [
      {
        label: "1",
        value: 1
      },
      {
        label: "2",
        value: 2
      },
      {
        label: "3",
        value: 3
      },
      {
        label: "4",
        value: 4
      },
      {
        label: "5",
        value: 5
      },
      {
        label: "6",
        value: 6
      },
      {
        label: "7",
        value: 7
      },
      {
        label: "8",
        value: 8
      },
      {
        label: "9",
        value: 9
      }
    ]
  }

  return (
    <Select
      placeholder="Selecione uma opção"
      onChange={(value) => form.setFieldValue(name, value)}
      defaultValue={{
        label: options[option][initial - 1].label,
        value: initial
      }}
      showSearch
      optionFilterProp="label"
      options={options[option]}
    />
  )
};

export default SelectRank;
