import { Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import _service from "@netuno/service-client";

function FilterGroupCode({
    onChange,
}) {
    const onChangeSelect = (value) => {
        if (value.length > 1) {
            onChange({
                group_code: null
            });

            return;
        }

        onChange({
            group_code: value[0]
        });
    };

    const options = [
        {
            label: "Administrador",
            value: "raiz_admin"
        },
        {
            label: "Gestor",
            value: "raiz_manager"
        }
    ];

    return (
        <>
            <Select
                allowClear
                mode="multiple"
                showArrow={true}
                suffixIcon={<SearchOutlined />}
                options={options}
                optionFilterProp="label"
                onChange={onChangeSelect}
                placeholder="Grupo do Utilizador"
                style={{
                    width: "100%",
                    marginRight: "10px",
                    background: "#fff",
                    borderRadius: "5px"
                }}
                dropdownStyle={{
                    maxHeight: 400,
                    overflow: 'auto',
                    minWidth: 300,
                }}
            />
        </>
    );
}

export default FilterGroupCode;
