import React, { useState } from 'react';
import TermsConditionsModal from './Modal';

function TermsAndConditions() {

    const [showTerms, setShowTerms] = useState(false);

    function onClickTermsConditions() {
        setShowTerms(true)
    }

    return (
        <>
            <span onClick={onClickTermsConditions}>Ao iniciar a sessão, está automaticamente a aceitar os nossos
                <a>&#32; termos e condições 
                </a>.</span>
            <TermsConditionsModal
                show={showTerms}
                onClose={() => setShowTerms(false)}
            />
        </>

    )
}

export default TermsAndConditions