import React, { useEffect, useState } from 'react';
import _service from '@netuno/service-client';
import _auth from "@netuno/auth-client";
import {
    useNavigate,
} from "react-router-dom";
import './index.less';
import {
    Row,
    Col,
    Button,
    Table,
    Input,
    Form,
    Popconfirm,
    InputNumber,
    Pagination,
    notification,
    Select,
    Switch,
    Typography

} from "antd";

import {
    DeleteOutlined,
    EditOutlined,
    WarningOutlined,
    CloseOutlined,
    SaveOutlined,
    CheckOutlined
} from "@ant-design/icons";
import User from '../../components/User';
import FilterName from './FilterName';
import FilterGroupCode from './FilterGroupCode';

import { inactiveUserMesage, inactiveUserCode } from "../../common/consts";

const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
}) => {
    const [groupCode, setGroupCode] = useState("");

    const inputNode =
        dataIndex === "group_code" ? (
            <Select
                style={{ width: "100%", margin: 0, textAlign: "left" }}
                value={groupCode}
                onChange={(value) => setGroupCode(value)}
            >
                <Select.Option value="raiz_admin">Administrador</Select.Option>
                <Select.Option value="raiz_manager">Gestor</Select.Option>
            </Select>
        ) : inputType === "number" ? (
            <InputNumber />
        ) : (
            <Input style={{ textAlign: "center" }} />
        );
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                        textAlign: "center",
                    }}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

const UserManagement = () => {
    const [data, setData] = useState([]);
    const [loadingData, setLoadingData] = useState(false);
    const [page, setPage] = useState(1);
    const [endPagination, setEndPagination] = useState(false);
    const [editingKey, setEditingKey] = useState("");
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [showAddUser, setShowAddUser] = useState(false)
    const [formEdit] = Form.useForm();

    const isEditing = (record) => record.uid === editingKey;

    const editUser = (record) => {
        formEdit.setFieldsValue({
            name: record.name,
            group_code: record.group_code === "raiz_admin" ? "Administrador" : "Gestor",
            username: record.username,
            email: record.email,
            password: record.password,
            active: record.active,
        });
        setEditingKey(record.uid);
    };

    const saveUser = async (record) => {
        try {
            const groupCodes = {
                Administrador: "raiz_admin",
                Gestor: "raiz_manager"
            };
            const row = await formEdit.validateFields();

            if (groupCodes[row.group_code]) {
                row.group_code = groupCodes[row.group_code];
            }

            const newData = [...data];
            const index = newData.findIndex((item) => record.uid === item.uid);
            if (index > -1) {
                _service({
                    url: "/user",
                    method: "PUT",
                    data: {
                        name: row.name,
                        username: row.username,
                        email: row.email,
                        group_code: row.group_code,
                        password: row.password,
                        uid: record.uid,
                        active: record.active,
                    },
                    success: () => {
                        notification["success"]({
                            message: "Seus dados foram alterados com sucesso!",
                        });
                        const item = newData[index];
                        newData.splice(index, 1, {
                            ...item,
                            ...row,
                        });
                        setData(newData);
                        setEditingKey("");
                    },
                    fail: (err) => {
                        const { json } = err;

                        if (json) {
                            const { code } = json;

                            if (code === inactiveUserCode) {
                                notification.error({
                                    key: inactiveUserCode,
                                    message: inactiveUserMesage
                                });
                                _auth.logout();
                                
                                return;
                            }
                        }

                        notification["error"]({
                            description: "Não foi possivel alterar os  seus dados."
                        });
                    }
                });
            } else {
                newData.push(row);
                setData(newData);
                setEditingKey("");
            }
        } catch (errInfo) {
            console.log("Validate Failed:", errInfo);
        }
    }

    const deleteUser = (record) => {
        _service({
            url: "/user",
            method: "DELETE",
            data: {
                uid: record.uid,
            },
            success: (response) => {
                const { json } = response;
                if (endPagination) {
                    setData(data.filter((item) => item.uid !== record.uid));
                } else {
                    getUsers();
                }

                if (json) {
                    if (json.result) {
                        notification.success({
                            message: "O utilizador foi eliminado com sucesso."
                        });
                    }
                }
            },
            fail: (err) => {
                console.error(err);

                const { json } = err;

                if (json) {
                    const { code } = json;

                    if (code === inactiveUserCode) {
                        notification.error({
                            key: inactiveUserCode,
                            message: inactiveUserMesage
                        });
                        _auth.logout();
                    }
                }
            }
        });
    };

    const handleNextPage = (e) => {
        const loadMore =
            e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

        if (loadMore && !endPagination) {
            setPage((prev) => prev + 1);
        }
    };

    const handlePageChange = (page, pageSize) => {
        setPage(page);
    };

    const [filter, setFilter] = useState({
        user_uids: [],
        group_code: null,
    });

    const onChangeFilters = (value) => {
        setFilter(prevFilter => ({
            ...prevFilter,
            ...value
        }));
    };

    const getUsers = () => {
        setLoadingData(true);
        _service({
            url: "/user/list",
            method: "POST",
            data: {
                pagination: {
                    page: page,
                    size: pageSize,
                },
                filter: filter
            },
            success: (response) => {
                setData(response.json.users);
                setTotalItems(response.json.total);
                setLoadingData(false);
            },
            fail: (error) => {
                console.error(error);
                setLoadingData(false);

                const { json } = error;

                if (json) {
                    const { code } = json;

                    if (code === inactiveUserCode) {
                        notification.error({
                            key: inactiveUserCode,
                            message: inactiveUserMesage
                        });
                        _auth.logout();
                    }
                }
            }
        });
    };

    useEffect(() => {
        getUsers();
    }, [page, filter]);

    const columns = [
        {
            title: 'Nome',
            dataIndex: "name",
            key: "name",
            editable: true,
        },
        {
            title: 'Email',
            dataIndex: "email",
            key: "email",
            editable: true,
        },
        {
            title: 'Utilizador',
            dataIndex: "username",
            key: "username",
            editable: true,
        },
        {
            title: 'Grupo',
            dataIndex: "group_code",
            key: "group_code",
            editable: true,
            render: (_, record) => {
                if (record.group_code == "raiz_admin") {
                    return <span>Administrador</span>
                } else {
                    return <span>Gestor</span>
                }
            },
        },
        {
            title: 'Palavra-passe',
            dataIndex: "password",
            key: "password",
            editable: true,
        },
        {
            title: "Ativo | Inativo",
            dataIndex: "active",
            key: "active",
            render: (text, record) => (
                <Popconfirm
                    placement="top"
                    title={
                        record.active
                            ? "Tem certeza que deseja deixar esse utilizador inativo?"
                            : "Tem certeza que deseja deixar esse utilizador ativo?"
                    }
                    okText="Sim"
                    cancelText="Não"
                    okType="link"
                    okButtonProps={{
                        className: "pop-confirm-confirm-btn",
                    }}
                    cancelButtonProps={{
                        className: "pop-confirm-cancel-btn",
                    }}
                    icon={<WarningOutlined style={{ color: "#E70000" }} />}
                    rootClassName="pop-confirm"
                    onConfirm={() => {
                        record.active = !record.active;
                        _service({
                            url: "/user",
                            method: "PUT",
                            data: {
                                uid: record.uid,
                                active: record.active,
                                ...record
                            },
                            success: () => {
                                notification["success"]({
                                    message: 'Utilizador alterado com sucesso!',
                                    description: `${record.active ? 'O Utilizador foi ativado com sucesso' : 'O utilizador foi desativado com sucesso.'}`,
                                });
                                getUsers();
                            },
                            fail: (err) => {
                                const { json } = err;

                                if (json) {
                                    const { code } = json;

                                    if (code === inactiveUserCode) {
                                        notification.error({
                                            key: inactiveUserCode,
                                            message: inactiveUserMesage
                                        });
                                        _auth.logout();

                                        return;
                                    }
                                }

                                notification["error"]({
                                    message: "Não foi possível atualizar o status.",
                                });
                            },
                        });
                    }}
                    onCancel={() => { }}
                >
                    <Switch
                        checkedChildren="Ativo"
                        unCheckedChildren="Inativo"
                        checked={record.active}
                        onChange={() => { }}
                    />
                </Popconfirm>
            ),
        },
        {
            title: "Ações",
            key: "actions",
            render: (_, record) => {
                const editable = isEditing(record);
                return !editable ? (
                    <div>
                        <Button
                            className="edit-institution-btn"
                            type="link"
                            onClick={() => editUser(record)}
                        >
                            <EditOutlined style={{ fontSize: 20, color: "#3CBA92" }} />
                        </Button>
                        <Popconfirm
                            placement="top"
                            title="Tem certeza que deseja eliminar este utilizador?"
                            okText="Sim"
                            cancelText="Não"
                            onConfirm={() => deleteUser(record)}
                            okType="link"
                            okButtonProps={{
                                className: "pop-confirm-confirm-btn",
                            }}
                            cancelButtonProps={{
                                className: "pop-confirm-cancel-btn",
                            }}
                            icon={<WarningOutlined style={{ color: "#E70000" }} />}
                            rootClassName="pop-confirm"
                        >
                            <Button className="delete-institution-btn" type="link" onClick={() => setEditingKey("")}>
                                <DeleteOutlined
                                    style={{ fontSize: 20, color: "rgba(255, 0, 0, 0.6)" }}
                                />
                            </Button>
                        </Popconfirm>
                    </div>
                ) : (
                    <div>
                        <Button
                            className="save-institution-btn"
                            type="link"
                            onClick={() => saveUser(record)}
                        >
                            <SaveOutlined style={{ fontSize: 20, color: "#3CBA92" }} />
                        </Button>
                        <Button type="link" onClick={() => setEditingKey("")}>
                            <CloseOutlined
                                style={{ fontSize: 20, color: "rgba(255, 0, 0, 0.6)" }}
                            />
                        </Button>
                    </div>
                );
            },
        },
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === "password" ? "password" : "text",
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    useEffect(() => {
        setPage(1);
    }, [filter]);

    return (
        <div className='list-user' onScroll={handleNextPage}>
            <div
                className="dashboard__filter"
            >
                <Typography.Title
                    level={2}
                    style={{ color: '#fff' }}
                >
                    Filtrar
                </Typography.Title>
                <div className='dashboard__filter-itens'>
                    <div className='dashboard__filter-itens-box'>
                        <FilterName
                            filter="name"
                            onChange={onChangeFilters}
                            placeholder="Utilizadores"
                        />
                    </div>
                    <div className='dashboard__filter-itens-box'>
                        <FilterGroupCode
                            filter="group_code"
                            onChange={onChangeFilters}
                            placeholder="Utilizadores"
                        />
                    </div>
                </div>
            </div>
            <div className='ant-modal-title list-user-header'>
                <h1>Utilizadores</h1>
                <Button onClick={() => setShowAddUser(true)} className='list-user-header-btn btn-lg' type="primary">Criar um novo Utilizador</Button>
            </div>
            <Form form={formEdit} component={false}>
                <Table
                    rowClassName="editable-row"
                    columns={mergedColumns}
                    dataSource={data}
                    sticky={true}
                    pagination={false}
                    loa
                    rowKey="uid"
                    components={{
                        body: {
                            cell: EditableCell,
                        },
                    }}
                />
            </Form>
            <Pagination
                current={page}
                pageSize={pageSize}
                total={totalItems}
                onChange={handlePageChange}
            />
            <User
                show={showAddUser}
                getUsers={getUsers}
                onClose={() => setShowAddUser(false)}
            />
        </div>
    )
}

export default UserManagement;
