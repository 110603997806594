import React, { useState, useEffect } from "react";
import { Select, Row, Col, Form, Input, Popover, Tooltip, Button } from "antd";
import "./index.less";
import _service from "@netuno/service-client";
import Checkbox from "antd/es/checkbox/Checkbox";
import { InfoCircleOutlined } from "@ant-design/icons"

const { TextArea } = Input;
const { Option } = Select;

const ImpactYouAntecipate = () => {
    const text = <span> Sugestão de possíveis cálculos para a avaliação: previsão do
    retorno do investimento por ano após terminus do projeto, contemplar os direitos
    resultantes de licenças, poupanças de custos, lucros;  contributo do Programa/Projeto
    para a alteração da estrutura dos rendimentos da organização, entrada em novos mercados
    ou em novas áreas de negócio, processos de geração de novos negócios, difusão tecnológica
    e efeitos de repercussões “spillovers”, alteração do valor da despesa em IDT;
    valor económico, social e ambiental gerado. Tente sempre estimar o impacto esperado global 
    quantificando-o com base nos pressupostos assumidos e em % do EBITDA</span>;

    const onChange = (value) => {
        console.log(`selected ${value}`);
    };
    const onSearch = (value) => {
        console.log("search:", value);
    };

    const options = [];

    const contentRanking = [
        "[ < 0.5 ]",
        "[0.5 - 1.0]",
        "[1.0 - 2.0]",
        "[2.0 - 3.0]",
        "[ > 3.0 ]",
    ]

    for (let i = 0; i < contentRanking.length; i++) {
        options.push(
            <Option key={i + 1} value={i + 1}>
                {i + 1} - {contentRanking[i]}
            </Option>
        )
    }

    return (
        <Row className="impact-you-antecipate-modal" justify="flex-start">
            <Col className="impact-you-antecipate-modal-title">
                <h1>Impacto Antecipado <Tooltip placement="topLeft" title={text}><InfoCircleOutlined style={{ width: 20 }}/></Tooltip></h1>
                <p>         
                </p>
            </Col>
            <Row className="impact-you-antecipate-modal-body" gutter={[0, 24]}>
                <Col span={24}>
                    <span>Impacto Antecipado</span>
                    <Form.Item name="description_impact_long_term_measure">
                        <TextArea rows={4} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <div className="area-project-modal-select-title">
                        <span>Impacto no EBITDA anual a médio/longo prazo</span>
                    </div>
                    <Form.Item name="rank_impact_long_term_measure" rules={[
                        { required: true, message: "Campo Requerido." },
                    ]}>
                        <Select
                            style={{
                                marginTop: 8
                            }}
                            allowClear
                            className="impact-you-antecipate-modal-body-content-select-unique"
                            showSearch
                            placeholder="Selecione uma opção"
                            optionFilterProp="children"
                            onChange={onChange}
                            onSearch={onSearch}
                        >
                            {options}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        </Row>
    )
}

export default ImpactYouAntecipate;



