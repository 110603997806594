import React, { useState, useEffect } from "react";
import {
  Button,
  Table,
  Input,
  Row,
  Col,
  Form,
  Popconfirm,
  InputNumber,
  Spin,
  notification,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  WarningOutlined,
  CloseOutlined,
  SaveOutlined,
} from "@ant-design/icons";

import _service from "@netuno/service-client";
import _auth from "@netuno/auth-client";
import { dashboardReloadAction } from "../../../redux/actions";
import { connect } from "react-redux"
import { bindActionCreators } from 'redux';

import { inactiveUserMesage, inactiveUserCode } from "../../../common/consts";

import "./index.less";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode =
    inputType === "number" ? (
      <InputNumber />
    ) : (
      <Input style={{ textAlign: "center" }} />
    );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
            textAlign: "center",
          }}
          rules={[
            {
              required: true,
              message: `Insira o nome da Organização`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const InstitutionsTab = ({
  onCloseModal,
  dashboardReloadAction
}) => {
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [editingKey, setEditingKey] = useState("");
  const [page, setPage] = useState(1);
  const [endPagination, setEndPagination] = useState(false);
  const [formEdit] = Form.useForm();
  const [formAdd] = Form.useForm();
  const errorMessages = {
    "institution-exists": (name) => `A Organização/Direção "${name}" já existe.`,
    "linked": () => "Esta Organização/Direção está ligada à um projeto."
  };

  const isEditing = (record) => record.uid === editingKey;

  const refreshInstitutions = () => {
    setLoadingData(true);
    _service({
      url: "/institution/list",
      method: "POST",
      data: {
        pagination: {
          page: 1,
          size: 10 * page,
        },
      },
      success: (response) => {
        setData([...response.json.institutions]);

        if (response.json.total === data.length) {
          setEndPagination(true);
        }
        setLoadingData(false);
      },
      fail: (err) => {

      }
    });
  };

  const getInstitutions = () => {
    setLoadingData(true);
    _service({
      url: "/institution/list",
      method: "POST",
      data: {
        pagination: {
          page: page,
          size: 10,
        },
      },
      success: (response) => {
        const newData = [...data, ...response.json.institutions];
        setData([...new Map(newData.map((item) => [item.uid, item])).values()]);
        if (response.json.total === data.length) {
          setEndPagination(true);
        }
        setLoadingData(false);
      },
      fail: (err) => {
        const { json } = err;

        if (json) {
          const { code } = json;

          if (code === inactiveUserCode) {
            onCloseModal();
            notification.error({
              key: inactiveUserCode,
              message: inactiveUserMesage
            });
            _auth.logout();
          }
        }

        console.error(err);
      }
    });
  };

  const addInstitution = (values) => {
    setLoadingAdd(true);
    _service({
      url: "/institution",
      method: "POST",
      data: values,
      success: (response) => {
        setLoadingAdd(false);
        setData([response.json.institution, ...data]);
        formAdd.resetFields();
        notification["success"]({
          message: 'Criação da Organização/Direção',
          description: 'A organização foi registrada com sucesso.',
        });
        dashboardReloadAction();
      },
      fail: (err) => {
        setLoadingAdd(false);
        if (err.json) {
          const { code } = err.json;
          const error = errorMessages[code];

          if (error) {
            notification.error({
              message: error(values.name)
            });

            return;
          }

          if (code === inactiveUserCode) {
            onCloseModal();
            notification.error({
              key: inactiveUserCode,
              message: inactiveUserMesage
            });
            _auth.logout();
          }
        }
      }
    });
  };

  const editInstitution = (record) => {
    formEdit.setFieldsValue({
      name: record.name,
    });
    setEditingKey(record.uid);
  };

  const saveInstitution = async (record) => {
    try {
      const row = await formEdit.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => record.uid === item.uid);
      if (index > -1) {
        _service({
          url: "/institution",
          method: "PUT",
          data: {
            name: row.name,
            uid: record.uid,
          },
          success: () => {
            const item = newData[index];
            newData.splice(index, 1, {
              ...item,
              ...row,
            });
            setData(newData);
            setEditingKey("");
            notification["success"]({
              message: 'Edição da Organização/Direção',
              description: 'Sua Organização/Direção foi alterada com sucesso'
            });
            dashboardReloadAction();
          },
          fail: (err) => {
            setLoadingAdd(false);
            const errorMessages = {
              "institution-exists": (name) => `A Organização/Direção "${name}" já existe.`
            };

            if (err.json) {
              const { code } = err.json;
              const error = errorMessages[code];

              if (error) {
                notification.error({
                  message: error(row.name)
                });

                return;
              }

              if (code === inactiveUserCode) {
                onCloseModal();
                notification.error({
                  key: inactiveUserCode,
                  message: inactiveUserMesage
                });
                _auth.logout();
              }
            }
          }
        });
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const deleteInstitution = (record) => {
    _service({
      url: "/institution",
      method: "DELETE",
      data: {
        uid: record.uid,
      },
      success: (response) => {
        if (endPagination) {
          setData(data.filter((item) => item.uid !== record.uid));
        } else {
          refreshInstitutions();
        }
        notification["success"]({
          message: 'Excluir a Operação Direção',
          description: 'Operação Direção foi excluida com sucesso',
        });
        dashboardReloadAction();
      },
      fail: (err) => {
        if (err.json) {
          const { code } = err.json;
          const errorMessage = errorMessages[code];

          if (errorMessage) {
            notification.error({
              message: errorMessage()
            });

            return;
          }

          if (code === inactiveUserCode) {
            onCloseModal();
            notification.error({
              key: inactiveUserCode,
              message: inactiveUserMesage
            });
            _auth.logout();
          }
        }
      }
    });
  };

  useEffect(() => {
    getInstitutions();
  }, [page]);

  const columns = [
    {
      title: "Organização/Direção",
      dataIndex: "name",
      key: "name",
      editable: true,
    },
    {
      title: "Ações",
      key: "actions",
      render: (_, record) => {
        const editable = isEditing(record);
        return !editable ? (
          <div>
            <Button
              className="edit-institution-btn"
              type="link"
              onClick={() => editInstitution(record)}
            >
              <EditOutlined style={{ fontSize: 20, color: "#3CBA92" }} />
            </Button>
            <Popconfirm
              placement="top"
              title="Tem certeza que deseja eliminar esta Organização/Direção?"
              onConfirm={() => deleteInstitution(record)}
              okText="Sim"
              cancelText="Não"
              okType="link"
              okButtonProps={{
                className: "pop-confirm-confirm-btn",
              }}
              cancelButtonProps={{
                className: "pop-confirm-cancel-btn",
              }}
              icon={<WarningOutlined style={{ color: "#E70000" }} />}
              rootClassName="pop-confirm"
            >
              <Button className="delete-institution-btn" type="link">
                <DeleteOutlined
                  style={{ fontSize: 20, color: "rgba(255, 0, 0, 0.6)" }}
                />
              </Button>
            </Popconfirm>
          </div>
        ) : (
          <div>
            <Button
              className="save-institution-btn"
              type="link"
              onClick={() => saveInstitution(record)}
            >
              <SaveOutlined style={{ fontSize: 20, color: "#3CBA92" }} />
            </Button>
            <Button type="link" onClick={() => setEditingKey("")}>
              <CloseOutlined
                style={{ fontSize: 20, color: "rgba(255, 0, 0, 0.6)" }}
              />
            </Button>
          </div>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "age" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const handleNextPage = (e) => {
    const loadMore =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

    if (loadMore && !endPagination) {
      setPage((prev) => prev + 1);
    }
  };

  return (
    <div className="institutions-tab" onScroll={handleNextPage}>
      <Form form={formAdd} onFinish={addInstitution}>
        <Row gutter={16} className="form-col">
          <Col span={12}>
            <Form.Item
              name="name"
              rules={[
                { required: true, message: "Insira o nome da Organização." },
              ]}
            >
              <Input
                placeholder="Nova Organização/Direção"
                className="input-default"
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item>
              <Button
                id="add-institution"
                type="primary"
                htmlType="submit"
                loading={loadingAdd}
              >
                Adicionar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Form form={formEdit} component={false}>
        <Table
          className="institutions-tab-table"
          rowClassName="editable-row"
          columns={mergedColumns}
          dataSource={data}
          sticky={true}
          pagination={false}
          loa
          rowKey="uid"
          components={{
            body: {
              cell: EditableCell,
            },
          }}
        />
      </Form>
      {loadingData && (
        <div className="institutions-tab-table-loading">
          <Spin />
        </div>
      )}
    </div>
  );
};


const mapStateToProps = store => {
  return {
    reloads: store.dashboardReloadState
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  dashboardReloadAction
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(InstitutionsTab);
