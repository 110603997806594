import { useEffect, useState } from "react";

import { Divider, Typography } from "antd";
import { CheckOutlined } from "@ant-design/icons";

import ProjectModal from "../Modal";
import ProjectRevaluateModalFooter from "./Footer";
import ProjectRevaluateModalTitle from "./Title";
import ProjectRevaluateModalSession from "./Session";
import ProjectRevaluateModalCard from "./Card";

function InfoModal({
  uid,
  open,
  onCancel,
  onRevaluate,
  defaultLink
}) {
  const [project, setProject] = useState(null);
  const [sessions, setSessions] = useState([]);

  const onLoadProject = (values) => {
    setProject(values);
  };

  useEffect(() => {
    if (project) {

      setSessions([
        {
          key: "project-data",
          title: "Dados Gerais do Projeto",
          fields: [
            [{
              key: "institution",
              label: "Organização/Direção",
            },
            {
              key: "area",
              label: "Área Operacional"
            }],
            [{
              key: "consultancy_start",
              label: "Período de Execução",
            }],
            [{
              key: "project",
              label: "Projeto / Programa Acrónimo"
            }],
            [{
              key: "overview",
              label: "Visão Geral"
            }],
            [{
              key: "milestones",
              label: "Metas"
            }],
            [{
              key: "deliverables",
              label: "Entregáveis"
            }],
            [{
              key: "trl_ttm",
              label: "Prontidão tecnológica vs Tempo para o mercado"
            }],
          ]
        },
        {
          key: "alignement-and-attractiveness",
          title: "Alinhamento e Atratividade",
          fields: [
            [{
              key: "justify_strategic_alignement",
              label: "Alinhamento com os objetivos estratégicos da Organização"
            }],
            [{
              key: "justify_uniquiness_program_plan",
              label: "Grau de inovação, originalidade e efetividade do Plano Proposto e Realizado no Projeto"
            }],
            [{
              key: "justify_economical_environmental_relevance_business",
              label: "Relevância Económica, Societal e Ambiental Para a Organização/Setor/Região/País"
            }],
            [{
              key: "justify_competence_network_partners",
              label: "Relevância Estratégica dos Parceiros da Rede na Cocriação e Valorização do Conhecimento"
            }],
            [{
              key: "rank_alignement_attractiveness",
              label: "Avaliação"
            }]
          ]
        },
        {
          key: "output",
          title: "Outputs",
          fields: [
            [{
              key: "number_publications",
              label: "Nº de publicações científicas e não científicas"
            }],
            [{
              key: "justify_number_publications",
              label: "Descrição"
            }],
            [{
              key: "number_outreaches",
              label: "Nº de iniciativas de divulgação, promoção e prémios"
            }],
            [{
              key: "justify_number_outreach",
              label: "Descrição"
            }],
            [{
              key: "number_patents",
              label: "Nº de patentes"
            }],
            [{
              key: "justify_number_patents",
              label: "Descrição"
            }],
            [{
              key: "number_norms_reports_techinical_assessments",
              label: "Nº de normas, relatórios e pareces técnicos"
            }],
            [{
              key: "justify_number_norms_reports_techinical_assessments",
              label: "Descrição"
            }],
            [{
              key: "number_proof_concepts",
              label: "Nº de provas de conceito e projetos de ignição, modelos de viabilidade técnico-económica e protótipos"
            }],
            [{
              key: "justify_number_proof_concepts",
              label: "Descrição"
            }],
            [{
              key: "number_new_technologies",
              label: "Nº de introdução de novas tecnologias e inovações em produtos e processos mais sustentáveis"
            }],
            [{
              key: "justify_number_new_technologies",
              label: "Descrição"
            }],
            [{
              key: "number_new_potential_business_proposals",
              label: "Nº de novos potenciais negócios, licenças a terceiros, joint ventures"
            }],
            [{
              key: "justify_number_new_potential_business_proposals",
              label: "Descrição"
            }],
            [{
              key: "number_new_strategic_partnerships",
              label: "Nº de novas parcerias estratégicas"
            }],
            [{
              key: "justify_number_new_strategic_partnerships",
              label: "Descrição"
            }],
            [{
              key: "rank_output",
              label: "Avaliação"
            }],
          ]
        },
        {
          key: "outcomes",
          title: "Efeitos/Outcomes",
          fields: [
            [{
              key: "outcome_increase_recognition",
              value: project.outcome_increase_recognition && (
                <Typography.Paragraph className="project-modal-field__value">
                  <span><CheckOutlined /></span> Aumento do conhecimento público
                </Typography.Paragraph>
              )
            }],
            [{
              key: "outcome_public_awareness",
              value: project.outcome_public_awareness && (
                <Typography.Paragraph className="project-modal-field__value">
                  <span><CheckOutlined /></span> Consciencialização pública através de uma maior literacia do conhecimento e participação da sociedade civil
                </Typography.Paragraph>
              )
            }],
            [{
              key: "outcome_knowledge_enlargement",
              value: project.outcome_knowledge_enlargement && (
                <Typography.Paragraph className="project-modal-field__value">
                  <span><CheckOutlined /></span> Aumento do conhecimento em cocriação e contributo das diferentes hélices de inovação
                </Typography.Paragraph>
              )
            }],
            [{
              key: "outcomes_justify",
              label: "Justificação"
            }],
            [{
              key: "rank_outcomes",
              label: "Avaliação"
            }],
          ]
        },
        {
          key: "impact",
          title: "Impacto Antecipado",
          subtitle: "Sugestão de possíveis cálculos para a avaliação: previsão do retorno do investimento por ano após terminus do projeto, contemplar os direitos resultantes de licenças, poupanças de custos, lucros;  contributo do Programa/Projeto para a alteração da estrutura dos rendimentos da organização, entrada em novos mercados ou em novas áreas de negócio, processos de geração de novos negócios, difusão tecnológica e efeitos de repercussões “spillovers”, alteração do valor da despesa em IDT; valor económico, social e ambiental gerado. Tente sempre estimar o impacto esperado global quantificando-o com base nos pressupostos assumidos e em % do EBITDA",
          fields: [
            [{
              key: "description_impact_long_term_measure",
              label: "Impacto Antecipado"
            }],
            [{
              key: "rank_impact_long_term_measure",
              label: "Impacto no EBITDA anual a médio/longo prazo",
            }]
          ]
        },
        {
          key: "agregate-measure-impact",
          fields: [
            [{
              key: "ami",
              value: (
                <>
                  <Divider />
                  <ProjectRevaluateModalCard
                    data={project.ami}
                    title="MEDIDA AGREGADA DE IMPACTO (AMI)"
                  />
                  <Divider />
                </>
              )
            }]
          ]
        },
        {
          key: "risk",
          title: "Estimativa de Risco",
          subtitle: "Antecipar os riscos que podem ser científicos, financeiros ou administrativos ou outros que podem colocar em causa a avaliação de impacto anterior e propor planos de ação para mitigar esses riscos",
          fields: [
            [{
              key: "risk_justify",
              label: "Justificação e planos de ação para mitigar os riscos"
            }],
            [{
              key: "rank_risk",
              value: (
                <>
                  <Divider />
                  <ProjectRevaluateModalCard
                    data={project.rank_risk}
                    title="Estimativa de Risco"
                  />
                </>
              )
            }]
          ]
        },
      ])
    }
  }, [project]);

  useEffect(() => {
    setTimeout(() => {
      const link = document.createElement("a")
      link.href = defaultLink
      link.click()
    }, 250)
  }, [sessions])


  const handleCloseModal = () => {
    window.history.replaceState({}, document.title, window.location.pathname);
    onCancel();
  };


  return (
    <ProjectModal
      uid={uid}
      open={open}
      title={project && <ProjectRevaluateModalTitle project={project} />}
      footer={project &&
        <ProjectRevaluateModalFooter
          onCancel={handleCloseModal}
          onRevaluate={onRevaluate}
          project={project}
        />
      }
      onCancel={handleCloseModal}
      onLoadProject={onLoadProject}
    >
      {sessions.map((session) => (
        <ProjectRevaluateModalSession
          key={session.key}
          session={session}
          project={project}
        />
      ))}
    </ProjectModal>
  );
}

export default InfoModal;