import { useEffect, useState } from "react";

import { Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import _service from "@netuno/service-client";

function FilterName({
    onChange,
}) {
    const [userList, setUserList] = useState([]);

    const onChangeSelect = (value) => {
        onChange({
            user_uids: value
        });
    }

    useEffect(() => {
        _service({
            url: "/user/list",
            method: "POST",
            success: (response) => {
                setUserList(response.json.users);
            },
            fail: (error) => {
                console.error(error);
            }
        });
    }, []);

    return (
        <>
            <Select
                allowClear
                mode="multiple"
                showArrow={true}
                suffixIcon={<SearchOutlined />}
                options={
                    userList.map(({ uid, username }) => ({
                        label: username,
                        value: uid
                    }))
                }
                onChange={onChangeSelect}
                optionFilterProp="label"
                placeholder="Utilizadores"
                style={{
                    width: "100%",
                    marginRight: "10px",
                    background: "#fff",
                    borderRadius: "5px"
                }}
                dropdownStyle={{
                    maxHeight: 400,
                    overflow: 'auto',
                    minWidth: 300,
                }}
            />
        </>
    );
}

export default FilterName;