import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { dashboardFiltersAction } from '../../redux/actions';
import { bindActionCreators } from 'redux';

import { Row, Col, Typography } from 'antd';

import _service from "@netuno/service-client";

import ListProjects from '../../components/Project/ListProjects';
import FilterArea from '../../components/Project/ListProjects/FilterArea';
import FilterInstitution from '../../components/Project/ListProjects/FilterInstitution';
import FilterProject from '../../components/Project/ListProjects/FilterProject';

import "./index.less";

function ProjectManagement({
    dashboardFiltersAction,
    reloads
}) {
    const [filter, setFilter] = useState({
        institution_uids: [],
        area_uids: [],
        project_uids: []
    });

    const onFilterChange = (filter) => {
        dashboardFiltersAction(filter);
    };

    const onChangeFilters = (values) => {
        setFilter({
            ...filter,
            ...values
        });
    };

    useEffect(() => {
        onFilterChange(filter);
    }, [filter, reloads])

    return (
        <Row>
            <Col span="24">
                <Row
                    align="middle"
                    gutter={4}
                    justify={'space-between'}
                    className="dashboard__filter"
                >
                    <Col span={6}>
                        <Typography.Title
                            level={2}
                            style={{ color: 'white' }}
                        >
                            Filtrar
                        </Typography.Title>
                    </Col>
                    <Col span={18}>
                        <Row justify={'space-between'} gutter={8}>
                            <Col span={8}>
                                <FilterInstitution
                                    filter="institution"
                                    onChange={onChangeFilters}
                                    placeholder="Organização/Direção"
                                    reloads={reloads}

                                />
                            </Col>
                            <Col span={8}>
                                <FilterArea />
                            </Col>
                            <Col span={8}>
                                <FilterProject />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
            <Col span="24">
                <ListProjects type="management" />
            </Col>
        </Row>
    );
}

const mapStateToProps = store => {
    const { loggedUserInfo } = store.loggedUserInfoState;
    return {
        loggedUserInfo,
        reloads: store.dashboardReloadState
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    dashboardFiltersAction
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProjectManagement);