import React from 'react';
import { Button, Modal, Popconfirm } from "antd";
import { InfoCircleOutlined, WarningOutlined } from '@ant-design/icons'
import _service from '@netuno/service-client';
import './index.less';

const TermsConditionsModal = ({ show, onClose }) => {

    return (
        <Modal
            className="modal-fap"
            title={
                <React.Fragment>
                    <h1 className="modal-fap-title">Termos e Condições da Plataforma</h1>
                </React.Fragment>
            }
            maskClosable={false}
            visible={show}
            onCancel={onClose}
            destroyOnClose={true}
            footer={
                <div style={{
                    display: 'flex',
                    justifyContent: "flex-end",
                    alignItems: "center"
                }}>
                    <Button
                        onClick={() => onClose()}
                        type='primary'
                        style={{
                            width: 132
                        }}>
                        Fechar
                    </Button>
                </div >
            }
        >
            <p className='modal-content'>
                <b>1. Disposições Gerais e Descrição do Serviço
                </b>
                <br />
                1.1 Os presentes “Termos e Condições” regulam o acesso e utilização da plataforma iMPACTO, Plataforma de Avaliação de Impacto (doravante, “Plataforma”) cuja propriedade pertence ao Instituto da Floresta e Papel (doravante “RAIZ”).
                1.2 A navegação na Plataforma, bem como o recurso aos serviços disponibilizados nesta, implicam a aceitação dos presentes Termos e Condições pelo Utilizador.
                1.3 A presente Plataforma foi criada, baseada na patente do RAIZ, financiada pelo INOV C+, e será disponibilizada ao consórcio para a avaliação do impacto do INOV C+ nas diversas organizações do consórcio, para comunicação à entidade financiadora.
                1.4 O RAIZ é ainda a responsável pela administração Plataforma, incluindo o controlo de acessos e a gestão de conteúdos.
                1.5 0 RAIZ reserva-se o direito de, a todo o momento, sem aviso e com efeitos imediatos, alterar, acrescentar ou revogar, parcial ou totalmente, os presentes Termos e Condições. Quaisquer alterações serão imediatamente divulgadas, constituindo um dever do utilizador consultá-las periodicamente.
                <br />
                <br />
                <b> 2. Condições de Utilização
                </b>
                <br />
                2.1 Este documento estabelece os termos, condições e regras a que fica sujeito o acesso e utilização da Plataforma, assim como de qualquer conteúdo apresentado ou disponibilizado (“Condições de Utilização”).
                2.2 As presentes Condições de Utilização não prejudicam a aplicação das regras legais relevantes, nem de outras condições que sejam definidas pelo RAIZ para acesso e/ou utilização de funcionalidades, serviços ou conteúdos específicos.
                2.3 Independentemente de qualquer aceitação anterior, as Condições de Utilização, sempre na sua versão mais recente, são automática e integralmente aplicáveis a qualquer utilizador que aceda à Plataforma, sendo irrelevante o objetivo – [avaliação dos projetos e acompanhamento dessa avaliação] - que tenha motivado tal acesso.
                2.4. Dada a aplicação integral e automática, caso o utilizador não concorde, em qualquer momento, com qualquer uma das suas regras, termos ou condições, deverá cessar a utilização da Plataforma.
                2.5. A cessação imediata da utilização da Plataforma não prejudicará a aplicação destas Condições de Utilização e das demais regras legais ou contratuais à utilização que tiver sido feita pelo utilizador antes de tal momento.
                2.6. Em caso de dúvida acerca da interpretação e/ou aplicação destes Condições de Utilização, o utilizador deverá contactar os serviços do RAIZ através do endereço de correio eletrónico <a>sara.monteiro@thenavigatorcompany.com</a>.
                <br />
                <br />
                <b> 3. Acesso</b>
                <br />
                3.1 O Raiz reserva o direito exclusivo de, a qualquer momento e sem necessidade de aviso prévio, impedir, suspender ou condicionar o acesso à Plataforma ou a qualquer uma das funcionalidades, serviços ou conteúdos, a qualquer utilizador que não cumpra as Condições de Utilização ou as normas legais vigentes.
                3.2 Em virtude dos presentes Termos e Condições, os Utilizadores terão, através da Plataforma, acesso ao seu processo de avaliação, que deve ser preenchido por gestor de projeto ou de Ciência e Tecnologia da sua organização.
                3.3 Para aceder à Plataforma pela primeira vez o utilizador deve fornecer o seu nome e e-mail ao administrador Sara Monteiro (<a>sara.monteiro@thenavigatorcompany.com</a>). Após o administrador registar os dados fornecidos e ativar o utilizador na plataforma, será enviado um e-mail de boas-vindas com o link de acesso ao sistema.
                O utilizador deverá clicar no link, será direcionado para ao endereço web da plataforma, no qual deve selecionar a opção:
                “Caso seja o seu primeiro acesso ou esqueceu a palavra-passe, clique aqui.”
                Deverá preencher o campo endereço de e-mail fornecido para registo e clicar em enter
                Após o envio, o utilizador receberá um e-mail, para que seja definida a palavra-passe. O utilizador deverá clicar no link, escolher a palavra-passe e realizar o login com a palavra-passe por si definida.
                3.5 O RAIZ rejeita qualquer responsabilidade por eventual atraso ou impossibilidade de apresentação da avaliação do impacto, decorrente de erro ou insuficiência dos dados fornecidos pelos Utilizadores.
                <br />
                <br />
                <b>4. Informações de Registo</b>
                <br />
                4.1. Os utilizadores da Plataforma serão os únicos responsáveis pela fidedignidade, correção e atualização de toda e qualquer informação por si disponibilizada, quer no momento do registo, quer em momento posterior.
                4.2. O código de entrada na plataforma (password) tem carácter pessoal e intransmissível e é para conhecimento e uso exclusivo do utilizador, sendo este responsável por assegurar e proteger, em quaisquer circunstâncias, a confidencialidade do mesmo, evitando, em especial, a sua revelação a terceiros não autorizados e a sua utilização em condições que permitam a sua descodificação e cópia.
                4.3. Qualquer utilização da Plataforma com os dados de registo do utilizador (código de identificação pessoal e password), com ou sem a sua autorização, formal ou tácita, será imputável ao utilizador em causa, salvo se este puder demonstrar que não violou a obrigação de confidencialidade prevista na cláusula 4.2.
                4.4. O utilizador obriga-se a comunicar imediatamente ao RAIZ, para o endereço de correio eletrónico [<a>sara.monteiro@thenavigatorcompany.com</a>], toda e qualquer violação de segurança e/ou quebra ou risco de quebra de confidencialidade, para que o RAIZ possa tomar as devidas precauções.
                4.5. O RAIZ assegura a segurança e a confidencialidade dos dados registados para efeitos de utilização da Plataforma, mas não aceita qualquer responsabilidade por perdas ou danos causados por utilizações abusivas dos dados referidos nos números anteriores que não lhe sejam diretamente imputáveis a título de dolo ou culpa grave.
                4.6. A cada utilizador só pode corresponder, em cada momento, um único registo, reservando o RAIZ o direito, como administradora da Plataforma, de impedir os registos subsequentes e de os eliminar quando se verifique que foram efetuados.
                4.7. O utilizador poderá, em qualquer momento, editar os seus dados e alterar a sua password, bastando para o efeito utilizar as ferramentas disponíveis na Plataforma

                <br />
                <br />
                <b>5. Política de privacidade e de cookies</b><br />
                5.1 O RAIZ respeita a privacidade do Utilizador e a proteção dos seus dados pessoais de acordo com o disposto no Regulamento Geral de Proteção de Dados (UE) 2016/679 do Parlamento Europeu e do Conselho, de 27 de abril de 2016, relativo à proteção das pessoas singulares, no que diz respeito ao tratamento de dados pessoais e à livre circulação destes dados (adiante “RGPD”) e Lei 58/2019 de 8 de agosto, que assegura a execução, na ordem jurídica nacional, do RGPD.
                Pode em qualquer momento consultar a política de privacidade e política de cookies através dos seguintes links: Política de Privacidade e e Cookies
                5.2 Os dados disponibilizados pelo Utilizador serão tratados única e exclusivamente para reportar o impacto do projeto INOV C+ e reportar o impacto dos projetos do RAIZ após termo do projeto INOV C+.
                <br />
                <br />
                <b>6. Propriedade Intelectual</b><br />
                6.1 Todos os textos, comentários, trabalhos, ilustrações, obras e imagens, software e código-fonte reproduzidos ou representados na Plataforma encontram-se devidamente protegidos pelo direito de autor e são propriedade do RAIZ, nos termos do Código do Direito de Autor e dos Direitos Conexos (CDADC), apenas será autorizada a sua utilização para fins do projeto INOV C+, sem prejuízo de disposições mais restritivas constantes do mencionado Código. Qualquer reprodução ou representação total ou parcial da Plataforma ou de todo ou parte dos elementos incluídos no mesmo é estritamente proibida, sob pena de recurso aos meios legais competentes.
                <br />
                <br />
                <b>7. Comunicações</b><br />
                7.1 Ao utilizar o Plataforma os Utilizadores aceitam expressamente que a comunicação com o RAIZ de forma eletrónica ou outro meio de comunicação à distância, satisfaz a exigência legal para comunicações sujeitas a forma escrita.
                7.2 As comunicações serão realizadas entre as partes através dos contactos constantes nos presentes Termos e Condições no momento da celebração do contrato.
                7.3 Qualquer comunicação será tida por recebida 24 (vinte e quatro) horas após o envio de uma mensagem escrita para contacto de endereço de email do utilizador. Para prova do envio de uma comunicação, basta provar que mesma mensagem foi enviada.
                <br />
                <br />
                <b>8. Confidencialidade</b> <br />
                8.1 Para efeitos dos presentes Termos e Condições, “Informação Confidencial” significa:
                – Quaisquer informações, de qualquer natureza, em qualquer suporte, ou dados com os conceitos, experiências, know-how, procedimentos técnicos e outros aspetos relacionados ou não com os serviços, revelados, no todo ou em parte, por escrito, oralmente ou por qualquer outra forma;
                – Qualquer material publicitário e/ou de comunicação, know-how, procedimentos técnicos.
                8.2 O RAIZ obriga-se a manter em segredo a Informação Confidencial e, bem assim, a adotar todas as medidas adequadas a esse fim, usando de todo o cuidado e zelo necessários a manter tal Informação Confidencial a salvo de revelação, cópia ou uso não autorizados, abstendo-se de reproduzir, imitar ou ceder a terceiro a Informação Confidencial a que tenham acesso.
                8.3 O RAIZ obriga-se ainda a assegurar que essa Informação Confidencial é restrita aos seus empregados, executivos, diretores e subcontratados que devam ter acesso à mesma em virtude da respetiva participação na prestação dos serviços e apenas e só com esse objetivo.
                8.4 As Partes obrigam-se a não divulgar a Informação Confidencial a qualquer terceiro, a não fazer qualquer aproveitamento comercial desta e a utilizá-la apenas no âmbito e nos termos dos presentes Termos e Condições.

                <br />
                <br />
                <b>9. Segurança</b><br />
                9.1. Qualquer alteração de conteúdos, de carregamento de informação, de acesso não autorizado, incluindo a tentativa de, ou qualquer outra ação que possa causar dano ou pôr em risco a integridade, segurança, funcionalidade ou utilização da Plataforma são proibidas e podem ser punidas pela legislação em vigor.
                9.2. Nos termos previstos na cláusula 3.1., o Raiz terá o direito de tomar todas as providências necessárias para assegurar a integridade, segurança, funcionalidade e utilização Plataforma ou para evitar perdas, utilização indevida ou alteração dos conteúdos ali disponibilizados.
                9.3. Por razões de segurança, não serão permitidos acessos à Plataforma com o mesmo endereço de correio eletrónico de utilizador e password.
                <br />
                <br />
                <b>10. Responsabilidade e Exclusões</b><br />
                10.1 O RAIZ não é responsável por eventuais prejuízos decorrentes de interferências, interrupções, vírus informáticos, avarias ou desconexões do sistema operativo que possam impedir temporariamente o acesso, a navegação ou a prestação de serviços ao Utilizador.
                10.2 O RAIZ realiza todos os esforços para assegurar que a informação da sua Plataforma se encontra exata e atualizada e para retificar quaisquer erros ou omissões logo que seja possível quando comunicados.
                10.3 O RAIZ não possui qualquer responsabilidade por perdas ou danos decorrentes da incapacidade de aceder à Plataforma
                10.4 A utilização de qualquer website de terceiros é regulada pelos termos e condições e pela política de privacidade desse mesmo website, isentando o RAIZ de qualquer responsabilidade proveniente dessa utilização.
                <br />
                <br />
                <b>11. Redução</b><br />
                A declaração de invalidade, ilegalidade ou ineficácia, por autoridade competente, de qualquer uma das disposições dos presentes Termos e Condições não afetará nenhuma das restantes disposições, que continuarão em pleno vigor.
                <br />
                <br />
                <b>12. Legislação aplicável e Resolução Alternativa de Litígios</b><br />
                12.1 Os contratos de prestação de serviços celebrados através da Plataforma são regulados pela Lei Portuguesa.
                12.2 Qualquer litígio resultante ou relacionado com esses contratos está sujeito à jurisdição exclusiva dos tribunais portugueses.

                <br />
                <br />
                <b>14. Disposições Finais
                </b><br />
                14.1 Todas as mensagens eletrónicas enviadas durante a utilização da Plataforma serão consideradas, para efeitos da lei aplicável, como declarações contratuais.
                14.2 Considera-se parte integrante das presentes Condições de Utilização a política de privacidade disponível na página inicial da Plataforma.
            </p>
        </Modal>
    );
};

export default TermsConditionsModal;
