import { useState } from "react";
import { Button, Col, Row, Space, Typography } from "antd";

import "./index.less";

function ProjectRevaluateModalCard({
    data,
    title,
    alert,
    onAccept,
    onRevaluate
}) {
    const [disableAccept, setDisableAccept] = useState(false);
    const [disableRevaluate, setDisableRevaluate] = useState(false);

    return (
        <Row
            className={`project-modal-card ${alert ? "project-modal-card__alert" : "project-modal-card__default"}`}
            align="center"
            justify="center"
        >
            <Col span="24">
                <Typography.Title
                    className="project-modal-card__text"
                    level={2}
                >
                    {Math.trunc(data * 10) / 10}
                </Typography.Title>
            </Col>
            <Col span="24">
                <Typography.Paragraph
                    className="project-modal-card__text"
                    style={{
                        fontSize: "14px"
                    }}
                >
                    {title}
                </Typography.Paragraph>
            </Col>
            {
                alert && (
                    <Col>
                        <Space direction="horizontal" align="center">
                            <Button
                                type="primary"
                                danger
                                disabled={disableRevaluate}
                                onClick={() => {
                                    onRevaluate();
                                    setDisableAccept(false);
                                    setDisableRevaluate(true);
                                }}
                            >
                                Reavaliar
                            </Button>
                            <Button
                                type="primary"
                                disabled={disableAccept}
                                onClick={() => {
                                    onAccept();
                                    setDisableAccept(true);
                                    setDisableRevaluate(false);
                                }}
                            >
                                Aceitar
                            </Button>
                        </Space>
                    </Col>
                )
            }
        </Row>
    )
}

export default ProjectRevaluateModalCard;