import { Typography } from "antd";
import { SwapRightOutlined } from "@ant-design/icons";

import "./index.less";

function ProjectRevaluateModalSessionField({
    field,
    project
}) {
    let value;

    if (["area", "institution"].includes(field.key)) {
        value = (
            <Typography.Paragraph className="project-modal-field__value">
                {project[field.key].name}
            </Typography.Paragraph>
        );
    } else if (["consultancy_start"].includes(field.key)) {
        value = (
            <Typography.Paragraph className="project-modal-field__value">
                {project.consultancy_start} <SwapRightOutlined /> {project.consultancy_end}
            </Typography.Paragraph>
        );
    } else if (field.value) {
        value = field.value;
    } else {
        value = (
            <Typography.Paragraph className="project-modal-field__value">
                {project[field.key]}
            </Typography.Paragraph>
        );
    }

    return (
        <>
            {
                field.label && (
                    <Typography.Paragraph className="project-modal-field__label">
                        {field.label}
                    </Typography.Paragraph>
                )
            }
            {value}
        </>
    )
}

export default ProjectRevaluateModalSessionField;