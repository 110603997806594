import React, { useState, useEffect } from 'react';
import { Select, Row, Col, Form, Input, Popover } from 'antd';
import './index.less';
import _service from '@netuno/service-client';
import Checkbox from 'antd/es/checkbox/Checkbox';
import { InfoCircleOutlined } from '@ant-design/icons'

const { TextArea } = Input;
const { Option } = Select;

const Alignement = () => {

    const onChange = (value) => {
        console.log(`selected ${value}`);
    };
    const onSearch = (value) => {
        console.log('search:', value);
    };

    const options = [];

    const contentRanking = ["Alinhamento e Atratividade - Baixo", "Alinhamento e Atratividade - Suficiente", "Alinhamento e Atratividade - Bom", "Alinhamento e Atratividade - Muito Bom", "Alinhamento e Atratividade - Forte"];

    for (let i = 0; i < contentRanking.length; i++) {
        options.push(
            <Option key={i + 1} value={i + 1}>
                {i + 1} - {contentRanking[i]}
            </Option>
        );
    }

    return (
        <>
            <Row className='alignement-modal' justify="flex-start">
                <Col className='alignement-modal-title'>
                    <h1>Alinhamento e Atratividade</h1>
                </Col>
                <Row className="alignement-modal-body" gutter={[10, 24]}>
                    <Col span={24}>
                        <span>Alinhamento com os objetivos estratégicos da Organização</span>
                        <Form.Item name="justify_strategic_alignement">
                            <TextArea rows={4} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <span>Grau de inovação, originalidade e efetividade do Plano Proposto e Realizado no Projeto</span>
                        <Form.Item name="justify_uniquiness_program_plan">
                            <TextArea rows={4} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <span>Relevância económica, societal e ambiental para a organização/ setor / região / país</span>
                        <Form.Item name="justify_economical_environmental_relevance_business">
                            <TextArea rows={4} />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <span>Relevância estratégica dos parceiros da rede na cocriação e valorização do conhecimento</span>
                        <Form.Item name="justify_competence_network_partners">
                            <TextArea rows={4} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <div className='area-project-modal-select-title'>
                            <span>Avaliação</span>
                        </div>
                        <Form.Item name="rank_alignement_attractiveness" rules={[
                            { required: true, message: 'Campo Requerido.' },
                        ]}>
                            <Select
                                style={{
                                    marginTop: 8,
                                    width: 500
                                }}
                                className="area-project-modal-select-unique"
                                showSearch
                                placeholder="Selecione uma opção"
                                optionFilterProp="children"
                                onChange={onChange}
                                onSearch={onSearch}
                            >
                                {options}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Row>
        </>
    );
}

export default Alignement;
